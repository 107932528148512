import React from "react";
import { Detector } from "react-detect-offline";
import { useSnackbar } from "notistack";
const OnlineDetector = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [offlineKey, setOfflineKey] = React.useState();
  const onChange = (online) => {
    if (online) {
      if (offlineKey) {
        closeSnackbar(offlineKey);
        setOfflineKey(null);
      }
      enqueueSnackbar("Back Online", { variant: "success" });
    } else
      setOfflineKey(
        enqueueSnackbar("You don't seem to be connected to the Internet", {
          variant: "error",
          persist: true,
        })
      );
  };
  return (
    <Detector render={(props) => <React.Fragment />} onChange={onChange} />
  );
};

export default OnlineDetector;
