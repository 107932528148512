import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Api } from "./Api";
import App from "./App";
import { StateProvider } from "./StateProvider";
import reducer, { initialState, actionTypes } from "./reducer";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@material-ui/core";
// import { logout } from "./component/auth/Logout";
import GraphAuth from "./components/gql/GraphAuth";
import OnlineDetector from "./components/OnlineDetector";
import { config } from "./Constants";
import { createUploadLink } from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: Api.graphql,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(config.storageItemKeys.token);
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
const theme = createTheme({
  // spacing: (i) => i,
  // palette: {
  //   // primary: purple,
  //   // secondary: ,
  //   // tertiary:"#",
  //   // error: red,
  //   neutral: {
  //     light: "#5ce6e5",
  //     main: "#00b3b3",
  //     dark: "#008383",
  //     contrastText: "#fff",
  //   },
  // },
});

const IndexMain = () => (
  <GraphAuth>
    <OnlineDetector />
    <App />
  </GraphAuth>
);
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <SnackbarProvider
                  maxSnack={3}
                  preventDuplicate
                  // hideIconVariant={false}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <IndexMain />
                </SnackbarProvider>
              </ThemeProvider>
            </BrowserRouter>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StateProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
