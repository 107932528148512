import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import React from "react";
import { config } from "./Constants";
import { gql } from "@apollo/client";
import estateLensQueries from "./api/estateLensQueries";
import leadsQueries from "./api/leadsQueries";
const examQuestionTypes = [
  {
    name: "Short Text",
    slug: "shortText",
    helperText: "Single line answer",
    disabled: false,
    maxLength: true,
  },
  {
    name: "Long Text",
    slug: "longText",
    helperText: "Multiline answer",
    disabled: false,
    maxLength: true,
  },
  {
    name: "Multiple Choice (Single Answer)",
    slug: "checkbox",
    helperText: "",
    disabled: true,
    maxLength: false,
  },
  {
    name: "Multiple Choice (Multiple Answers)",
    slug: "radio",
    helperText: "",
    disabled: true,
    maxLength: false,
  },
];

const typeIconsMain = {
  video: PlayCircleOutlineRoundedIcon,
  pdf: MenuBookRoundedIcon,
  file: InsertDriveFileOutlinedIcon,
  exam: HourglassEmptyRoundedIcon,
};
const TypeIcon = (props) => {
  const Comp = typeIconsMain[props.type];
  return <Comp {...props} />;
};
const typeIcons = {
  video: <typeIconsMain.video />,
  pdf: <typeIconsMain.pdf />,
  file: <typeIconsMain.file />,
  exam: <typeIconsMain.exam />,
};
const classSetRet = ({ key }, props = {}) => {
  const Comp = typeIconsMain[key];
  return <Comp {...props} />;
};
const activityTypes = [
  {
    name: "Video",
    icon: typeIcons.video,
    key: "video",
    setIconProps: classSetRet,
  },
  {
    name: "PDF",
    icon: typeIcons.pdf,
    setIconProps: classSetRet,
    key: "pdf",
  },
  {
    name: "Exam",
    icon: typeIcons.exam,
    key: "exam",
    setIconProps: classSetRet,
  },
  {
    name: "File",
    key: "file",
    icon: typeIcons.file,
    setIconProps: classSetRet,
  },
];

const activityType = {
  video: {
    key: "video",
    name: "Video",
    icon: typeIcons.video,
    setIconProps: classSetRet,
  },
  pdf: {
    key: "pdf",
    name: "PDF",
    icon: typeIcons.pdf,
    setIconProps: classSetRet,
  },
  exam: {
    key: "exam",
    name: "Exam",
    icon: typeIcons.exam,
    setIconProps: classSetRet,
  },
  file: {
    key: "file",
    name: "File",
    icon: typeIcons.file,
    setIconProps: classSetRet,
  },
};

const userData = `id
email
firstName
lastName
number
dateOfBirth
emailAuthenticated
isSuperuser
profileImg
themeColor
coursesEnrolledIn
dashboardAccess
leadsAccess`;
const GET_ME = gql`
  query {
    me {
      ${userData}
    }
  }
`;

const TOKEN_GET = gql`
  mutation getTokenAuth($email: String!, $password: String!) {
    action: tokenAuth(username: $email, password: $password) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
const TOKEN_REFRESH = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
const TOKEN_REVOKE = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;
const DELETE_TOKEN = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`;
const DELETE_REFRESH_TOKEN = gql`
  mutation {
    deleteRefreshTokenCookie {
      deleted
    }
  }
`;
const LOGOUT_QUERIES = gql`
  mutation RevokeToken($refreshToken: String!) {
    deleteRefreshTokenCookie {
      deleted
    }
    deleteTokenCookie {
      deleted
    }
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

const courseDat = `
  id
  title
  slug
  name
  description
  thumbnail
  paid
  draft
  releaseDate
  price
  discountPrice
  order
  activityCount
`;
const GET_COURSES = gql`
  query {
    courses {
      ${courseDat}
    }
  }
`;
const UPDATE_COURSE = gql`
  mutation getUpdateCourse(
    $courseId: ID
    $title: String!
    $slug: String!
    $access: String
    $price: String
    $discountPrice: String
    $useDiscount: Boolean
    $description: String
    $image: Upload
    $delete: Boolean
  ) {
    action: updateCourse(
      courseId: $courseId
      title: $title
      slug: $slug
      access: $access
      price: $price
      discountPrice: $discountPrice
      useDiscount: $useDiscount
      description: $description
      image: $image
      delete: $delete
    ) {
      course {
        id
      }
    }
  }
`;
const GET_COURSE_SECTIONS = gql`
  query ($id: ID!) {
    courseSections(id: $id) {
      id
      title
      slug
      description
      backgroundImage
      order
      createdAt
    }
  }
`;
const GET_COURSE_ACTIVITIES = gql`
  query ($courseId: ID, $sectionId: ID) {
    courseActivities(courseId: $courseId, sectionId: $sectionId) {
      id
      title
      order
      activityType
      description
      slug
      paid
      draft
      comingSoon
      videoTime
      vimeoId
      dataFile
      examData
      fileName
      pageCount
      downloadable
    }
  }
`;

const GET_COURSE_ACTIVITIES_SHORT = gql`
  query ($courseId: ID, $sectionId: ID) {
    courseActivities(courseId: $courseId, sectionId: $sectionId) {
      id
      title
      order
      activityType
      description
      slug
      section {
        order
      }
    }
  }
`;

const GET_COURSE_SECTIONS_ACTIVITIES = gql`
  query ($id: ID!) {
    courseSections(id: $id) {
      id
      title
      slug
      description
      backgroundImage
      order
      createdAt
    }
    courseActivities(courseId: $id) {
      section {
        id
      }
      id
      title
      order
    }
  }
`;
const UPDATE_COURSE_SECTION = gql`
  mutation (
    $courseId: ID!
    $sectionId: ID
    $title: String
    $order: Int
    $delete: Boolean
  ) {
    action: updateCourseSection(
      courseId: $courseId
      sectionId: $sectionId
      title: $title
      order: $order
      delete: $delete
    ) {
      courseSection {
        id
      }
    }
  }
`;
const UPDATE_COURSE_ACTIVITY = gql`
  mutation (
    $sectionId: ID!
    $activityId: ID
    $title: String
    $order: Int
    $paid: Boolean
    $draft: Boolean
    $comingSoon: Boolean
    $delete: Boolean
    $activityType: String
    $dataFile: Upload
    $vimeoId: String
    $examData: String
    $description: String
    $videoTime: Int
    $pageCount: Int
    $downloadable: Boolean
  ) {
    action: updateCourseActivity(
      sectionId: $sectionId
      activityId: $activityId
      title: $title
      order: $order
      paid: $paid
      draft: $draft
      comingSoon: $comingSoon
      delete: $delete
      activityType: $activityType
      dataFile: $dataFile
      vimeoId: $vimeoId
      examData: $examData
      description: $description
      videoTime: $videoTime
      pageCount: $pageCount
      downloadable: $downloadable
    ) {
      courseActivity {
        id
      }
    }
  }
`;

const GET_USERS = gql`query($excludeUser:Boolean){
  allUsers(excludeUser:$excludeUser){
  	${userData}
	}
}`;
const courseUserDat = `id
  course{
    ${courseDat}
  }
  user{
    ${userData}
  }
  enrolled
  unlockedTrack{
    activity{
      id
    }
  }
  lastTrack{
    activity{
      id
    }
    id
  }
  role
  activityComplete
  createdAt`;

const UPDATE_COURSE_USER = gql`mutation($courseUserId:ID!,$role:String,$activityUnlock:ID,$completePrevious:Boolean,$unCompleteNext:Boolean){
  updateCourseUser(courseUserId:$courseUserId,role:$role,activityUnlock:$activityUnlock,completePrevious:$completePrevious,unCompleteNext:$unCompleteNext){
    courseUser{
      ${courseUserDat}
    }
	}
}
`;

const GET_COURSE_USERS = gql`query($courseId:ID!){
  courseUsers(courseId:$courseId){
    ${courseUserDat}
	}
}`;
const GET_COURSE_USER_EXAMS = gql`
  query ($courseId: ID!, $examFilter: Boolean, $marked: Boolean) {
    courseUsersTracks(
      courseId: $courseId
      examFilter: $examFilter
      marked: $marked
    ) {
      id
      examData
      unlocked
      completed
      marked
      submitted
      activity {
        id
        order
        title
      }
      section {
        order
      }
      user {
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

const COURSE_ENROLL_USERS = gql`
  mutation (
    $userIds:[ID]!
    $courseId:ID!
    ){
    enrollUsers(
      userIds:$userIds
      courseId:$courseId
      ){
        courseUsers{
          ${courseUserDat}
        }
    }
  }
`;
const GET_USERS_COURSES = gql`
query{
  usersCourses{
    id
    course{
      ${courseDat}
    }
    activityComplete
    role
    lastTrack{
      id
      activity{
        id
      }
    }
    enrolled
  }
}`;

const GET_COURSE_USER_TRACK = gql`
  query ($courseId: ID!) {
    usersCourseTrack(courseId: $courseId) {
      id
      unlocked
      completed
      examData
      currentPage
      notification
      watchedTime
      section {
        id
        title
        order
      }
      activity {
        id
        title
        order
        paid
        draft
        comingSoon
        activityType
        dataFile
        vimeoId
        examData
        description
        videoTime
        pageCount
        downloadable
      }
    }
  }
`;

const GET_INITIAL_USER_DATA = gql`
query {
  me {
    ${userData}
  }
  usersCourses{
    id
    course{
      ${courseDat}
    }
    activityComplete
    role
    lastTrack{
      id
      activity{
        id
      }
    }
    enrolled
  }
}`;

const CREATE_COURSE_USER_TRACK = gql`
  mutation ($trackId: ID!) {
    createCourseUserTrackL(trackId: $trackId) {
      courseTrack {
        id
      }
    }
  }
`;
const UPDATE_COURSE_USER_TRACK = gql`
  mutation (
    $trackId: ID!
    $examToMark: Boolean
    $watchedTime: Int
    $videoTime: Int
    $currentPage: Int
    $pageCount: Int
    $examData: String
    $activityLog: JSONString
  ) {
    updateCourseUserTrack(
      trackId: $trackId
      watchedTime: $watchedTime
      videoTime: $videoTime
      currentPage: $currentPage
      examData: $examData
      activityLog: $activityLog
      pageCount: $pageCount
      examToMark: $examToMark
    ) {
      courseTrack {
        id
        unlocked
        completed
        currentPage
        examData
        watchedTime
      }
    }
  }
`;

const GET_WEBINARS = gql`
  query {
    webinars {
      id
      name
      views
      watches
      watchedTime
      updating
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;

const HOI_ANALYTICS = gql`
  query ($returnData: String!, $startDate: DateTime, $endDate: DateTime) {
    hoiAnalytics(
      returnData: $returnData
      startDate: $startDate
      endDate: $endDate
    ) {
      data
      dataType
    }
  }
`;

const GET_EMAIL_TRIGGERS = gql`
  query ($hoi: Boolean) {
    emailTriggers(hoi: $hoi) {
      id
      title
      webinar {
        id
      }
      shopProduct {
        id
      }
      webinarTimeField
    }
  }
`;

const GET_EMAILS = gql`
  query ($hoi: Boolean) {
    emails(hoi: $hoi) {
      id
      name
      slug
      subject
      trigger {
        id
        title
      }
      content
      order
      time
      hoi
      draft
      draftDate
      showUnsubscribe
      productPurchased
      productNotPurchased
      triggerComplete
      webinarSentAppend
      sends
      linkClicks
      registers
      sales
      linksData
      userDraft {
        id
        subject
        content
        updatedAt
      }
      published
      createdAt
      updatedAt
    }
  }
`;

const RENDER_EMAIL = gql`
  query ($subject: String!, $content: String!, $emailId: ID!) {
    renderEmail(subject: $subject, content: $content, emailId: $emailId) {
      content
      subject
    }
  }
`;

const UPDATE_EMAIL_DRAFT = gql`
  mutation ($subject: String!, $content: String!, $draftId: ID, $emailId: ID) {
    updateEmailDraft(
      subject: $subject
      content: $content
      emailId: $emailId
      draftId: $draftId
    ) {
      draft {
        id
        subject
        content
        updatedAt
      }
    }
  }
`;

const UPDATE_EMAIL = gql`
  mutation (
    $subject: String
    $content: String
    $name: String
    $time: Int
    $triggerComplete: Boolean
    $triggerId: ID
    $emailId: ID
    $draft: Boolean
    $showUnsubscribe: Boolean
    $hoi: Boolean
  ) {
    updateEmail(
      subject: $subject
      content: $content
      emailId: $emailId
      draft: $draft
      showUnsubscribe: $showUnsubscribe
      name: $name
      time: $time
      triggerComplete: $triggerComplete
      triggerId: $triggerId
      hoi: $hoi
    ) {
      email {
        userDraft {
          id
          subject
          content
          updatedAt
        }
        draft
        draftDate
        subject
        content
        updatedAt
      }
    }
  }
`;

const SEND_TEST_EMAIL = gql`
  mutation (
    $subject: String!
    $content: String!
    $emailId: ID
    $draftId: ID
    $email: String
    $webinarEmail: String
  ) {
    sendTestEmail(
      subject: $subject
      content: $content
      emailId: $emailId
      draftId: $draftId
      webinarEmail: $webinarEmail
      email: $email
    ) {
      sent
    }
  }
`;

const GET_HOI_PRODUCTS = gql`
  query {
    hoiProducts {
      id
      course {
        id
        name
      }
      title
      slug
      pageTitle
      affiliateApply
      mainProduct
      url
      goldMentorship {
        id
      }
      watchedRequired
      emailOnPurchase {
        id
        name
      }
      purchaseRequired {
        id
        title
      }
      featureList
      customPaymentOption
      sales
    }
  }
`;
const GET_HOI_PAYOUTS = gql`
  query {
    hoiPayouts {
      id
      order {
        id
        disputeStarted
      }
      affiliateUser {
        nickname
      }
      payoutMethod {
        name
      }
      transferReference
      payoutAmount
      payoutDate
      payoutStarted
      orderRefunded
      paidOut
      cancelled
      payoutCompleted
      cancelledReason
      createdAt
    }
  }
`;

const GET_PAYOUT_METHODS = gql`
  query {
    hoiPayoutMethods {
      id
      name
      createdAt
    }
  }
`;
const UPDATE_HOI_PAYOUT = gql`
  mutation (
    $payoutId: ID!
    $payoutMethod: ID
    $amount: String
    $transferReference: String
    $markPaidOut: Boolean
    $cancelPayout: Boolean
    $cancelReason: String
  ) {
    updatePayout(
      payoutId: $payoutId
      payoutMethod: $payoutMethod
      amount: $amount
      transferReference: $transferReference
      markPaidOut: $markPaidOut
      cancelPayout: $cancelPayout
      cancelReason: $cancelReason
    ) {
      hoiPayout {
        payoutMethod {
          name
        }
        transferReference
        payoutAmount
        payoutDate
        payoutStarted
        orderRefunded
        paidOut
        cancelled
        payoutCompleted
        cancelledReason
        createdAt
      }
    }
  }
`;

const GET_ORDERS = gql`
  query {
    hoiOrders {
      id
      name
      email
      product {
        id
        title
      }
      paymentLength
      affiliateUserReference {
        id
        nickname
      }
      reference {
        id
        name
      }
      name
      email
      number
      address {
        houseNumber
        street
        postcode
        country
      }
      totalPrice
      sourcePrice
      amountPaid
      taxAmount
      disputeRefunded
      refunded
      expiryDate
      createdAt
    }
  }
`;

// const GET_HOI_PAGES = gql`;
//   query {

//   }
// `;

// baseUrl = "http://localhost:8000";

const formatMediaUrl = (uri) => {
  return `${config.url.API_URL}/media/${uri}`;
};
const errorAlert = (e) => {
  alert(`${e?.message}.\n Please Try Again!`);
};
export const Api = {
  baseUrl: config.url.API_URL,
  graphql: `${config.url.API_URL}/graphql`,
  formatMediaUrl: formatMediaUrl,
  getMe: GET_ME,
  getToken: TOKEN_GET,
  refreshToken: TOKEN_REFRESH,
  revokeToken: TOKEN_REVOKE,
  deleteToken: DELETE_TOKEN,
  deleteRefreshToken: DELETE_REFRESH_TOKEN,
  logoutQueries: LOGOUT_QUERIES,
  getCourses: GET_COURSES,
  updateCourse: UPDATE_COURSE,
  getCourseSections: GET_COURSE_SECTIONS,
  updateCourseSection: UPDATE_COURSE_SECTION,
  updateCourseActivity: UPDATE_COURSE_ACTIVITY,
  getCourseActivities: GET_COURSE_ACTIVITIES,
  getCourseSectionsActivities: GET_COURSE_SECTIONS_ACTIVITIES,
  getCourseUsers: GET_COURSE_USERS,
  getCourseUserExams: GET_COURSE_USER_EXAMS,
  updateCourseUser: UPDATE_COURSE_USER,
  getUsers: GET_USERS,
  courseEnrollUsers: COURSE_ENROLL_USERS,
  getUsersCourses: GET_USERS_COURSES,
  getCourseUserTrack: GET_COURSE_USER_TRACK,
  getInitialUserData: GET_INITIAL_USER_DATA,
  getCourseActivitiesShort: GET_COURSE_ACTIVITIES_SHORT,
  createCourseUserTrack: CREATE_COURSE_USER_TRACK,
  updateCourseUserTrack: UPDATE_COURSE_USER_TRACK,
  getWebinars: GET_WEBINARS,
  hoiAnalytics: HOI_ANALYTICS,
  getEmails: GET_EMAILS,
  renderEmail: RENDER_EMAIL,
  updateEmailDraft: UPDATE_EMAIL_DRAFT,
  updateEmail: UPDATE_EMAIL,
  sendTestEmail: SEND_TEST_EMAIL,
  getEmailTriggers: GET_EMAIL_TRIGGERS,
  hoi: {
    getHoiPayouts: GET_HOI_PAYOUTS,
    getPayoutMethods: GET_PAYOUT_METHODS,
    getHoiProducts: GET_HOI_PRODUCTS,
    updatePayout: UPDATE_HOI_PAYOUT,
    getOrders: GET_ORDERS,
  },
  leads:leadsQueries,
  estatelens: estateLensQueries,
  errorAlert: errorAlert,
};
export { activityTypes, typeIcons, activityType, TypeIcon, examQuestionTypes };
