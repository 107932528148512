import React from "react";
import { Api } from "../../../Api";
import { useQuery } from "@apollo/client";
import ContainerLoader from "../../../components/ContainerLoader";
import { Alert, AlertTitle, Grid, Box } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import CourseSectionMain from "../../../components/admin/CourseSectionMain";
import AddCourseSection from "../../../components/admin/AddCourseSection";
import { spacing } from "../../../components/utilities/utils";
const useStyles = makeStyles((theme) => ({
  //   root: {
  //     "& .MuiTextField-root": {
  //       margin: spacing(theme, 1),
  //       //   marginLeft: 0,
  //       //   width: "100%",
  //     },
  //   },
  inputRoot: {
    // margin: spacing(theme, 1),
    // marginLeft: 0,
    // width: "100%",
  },
  label: {
    textTransform: "uppercase",
    fontWeight: 700,
  },
  input: {
    fontSize: 18,
  },
  margin: {
    marginTop: 20,
  },
  add_title: {
    margin: 0,
  },
  buttonFinal: {
    "& > span": {
      filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))",
    },
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  instructions: {
    marginTop: spacing(theme, 1),
    marginBottom: spacing(theme, 1),
  },
  btnWrapper: {
    position: "relative",
  },
  section_main: {
    marginBottom: 20,
  },
  section_main_title: {
    borderBottom: "1px solid lightGrey",
    paddingBottom: 10,
  },
}));

const CourseSections = ({ course }) => {
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(Api.getCourseSections, {
    variables: {
      id: course.id,
    },
  });
  if (loading) return <ContainerLoader loading={loading} />;
  return (
    <React.Fragment>
      <div>
        {loading === false && !error && data.courseSections ? (
          data.courseSections.length > 0 ? (
            data.courseSections.map((section, i) => {
              const sect = {
                course: course,
                ...section,
              };
              return (
                <CourseSectionMain
                  key={i}
                  refetchLoading={loading}
                  refetch={refetch}
                  section={sect}
                  classes={classes}
                />
              );
            })
          ) : (
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              {error ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
                </Alert>
              ) : (
                <Alert severity="info">
                  <AlertTitle>
                    <strong>No Sections Found</strong>
                  </AlertTitle>
                  Please create a section!
                </Alert>
              )}
            </Grid>
          )
        ) : (
          <React.Fragment />
        )}
      </div>
      <Box
        sx={{
          py: 3
        }}>
        <Grid container alignItems="center" className={classes.section_title}>
          <AddBoxOutlinedIcon />
          <Box
            component="h3"
            sx={{
              ml: 2
            }}>
            Add Section
          </Box>
        </Grid>
        <AddCourseSection refetch={refetch} course={course} />
      </Box>
    </React.Fragment>
  );
};

export default CourseSections;
