import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Typography,
  InputAdornment,
  FormHelperText,
  FormControl,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Grid,
  FormControlLabel,
  CircularProgress,
  Alert,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import TitleRoundedIcon from "@material-ui/icons/TitleRounded";
import AccountBalanceRoundedIcon from "@material-ui/icons/AccountBalanceRounded";
import PermMediaRoundedIcon from "@material-ui/icons/PermMediaRounded";
import "./CreateCourse.css";
import Slugify from "slugify";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import { DropzoneAreaBase } from "material-ui-dropzone";
import CourseTile from "../course/CourseTile";
import { useMutation } from "@apollo/client";
import { Api } from "../../Api";
import { spacing } from "../utilities/utils";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    "& $line": {
      maxWidth: "100%",
    },
  },
  completed: {
    "& $line": {
      maxWidth: "100%",
    },
  },
  line: {
    maxWidth: 0,
    transition: "all .3s",
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();

  const { active, completed } = props;

  const icons = {
    1: <TitleRoundedIcon />,
    2: <VpnKeyRoundedIcon />,
    3: <AccountBalanceRoundedIcon />,
    4: <PermMediaRoundedIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getSteps() {
  return ["Title", "Access", "Price", "Card"];
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: spacing(theme, 1),
    width: 80,
  },
  buttonFinal: {
    "& > span": {
      filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))",
    },
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  instructions: {
    marginTop: spacing(theme, 1),
    marginBottom: spacing(theme, 1),
  },
  btnWrapper: {
    position: "relative",
  },
}));

function CreateCourse({ refetch, modalClose, course, courseData }) {
  let slugData = [];
  if (courseData.length > 0) courseData.map((obj) => slugData.push(obj.slug));
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [updateCourse] = useMutation(Api.updateCourse);
  const steps = getSteps();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(course ? course.title : "");
  const [titleError, setTitleError] = useState("");
  const [slug, setSlug] = useState(course ? course.slug : "");
  const [slugError, setSlugError] = useState("");
  const [type, setType] = useState(course ? course.access : "paid");
  const [price, setPrice] = useState(course ? course.price : "");
  const [priceError, setPriceError] = useState("");

  const [useDiscount, setUseDiscount] = useState(
    course ? course.useDiscount : false
  );
  const [discountPrice, setDiscountPrice] = useState(
    course ? course.discountPrice : ""
  );
  const [discountPriceError, setDiscountPriceError] = useState("");

  const [image, setImage] = useState();
  const [imageSrc, setImageSrc] = useState(course ? course.thumbnail : "");
  const [imageError, setImageError] = useState("");

  const [description, setDescription] = useState(
    course ? course.description : ""
  );
  const [descriptionError, setDescriptionError] = useState("");

  const getStepContent = (step) => {
    const inputStyles = makeStyles((theme) => ({
      root: {
        "& .MuiTextField-root": {
          margin: spacing(theme, 1),
          marginLeft: 0,
          width: "100%",
        },
      },
      inputRoot: {
        margin: spacing(theme, 1),
        marginLeft: 0,
        width: "100%",
      },
      label: {
        textTransform: "uppercase",
        fontWeight: 700,
      },
      input: {
        fontSize: 18,
      },
      margin: {
        marginTop: 20,
      },
    }));

    const input_classes = inputStyles();
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Grid
              className="course-create__left"
              item
              xs={12}
              md={4}
              style={{ backgroundColor: "green" }}
            >
              <span className="course-crete__title">Course title</span>
              <TitleRoundedIcon fontSize="large" />
              <p></p>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={`course-create__right ${input_classes.root}`}
            >
              <span className="title">
                <strong>Write a title for your course</strong>
              </span>
              <p className="description">
                Craft a catchy title and capture your students imagination. Make
                it <strong>short</strong>, <strong>precise</strong>,{" "}
                <strong>memorable</strong> and <strong>ZEO friendly</strong> by
                including strong keywords in it.
              </p>
              <FormControl
                className={input_classes.inputRoot}
                error={titleError !== ""}
              >
                <InputLabel
                  htmlFor="create-course-title"
                  className={input_classes.label}
                >
                  Your title
                </InputLabel>
                <Input
                  className={input_classes.input}
                  id="create-course-title"
                  multiline
                  value={title}
                  onChange={(event) => {
                    const val = event.target.value;
                    const slug = Slugify(val.trim()).toLowerCase();
                    val.trim() === ""
                      ? setTitleError("Please enter a title")
                      : setTitleError("");

                    slugData.includes(slug)
                      ? setSlugError("A course already exists with this slug!")
                      : setSlugError("");
                    setSlug(slug);
                    setTitle(val);
                  }}
                  aria-describedby="create-course-title"
                />
                <FormHelperText>{titleError}</FormHelperText>
              </FormControl>
              <span className="title" style={{ marginTop: 40 }}>
                <strong>Give your course a friendly URL (Slug)</strong>
              </span>
              <p className="description">
                By giving a friendly URL you make your course more accessible
                both to people and search engines.
              </p>
              <FormControl
                className={input_classes.inputRoot}
                error={slugError !== ""}
              >
                <InputLabel
                  htmlFor="create-course-slug"
                  className={input_classes.label}
                >
                  Edit your friendly url
                </InputLabel>
                <Input
                  className={input_classes.input}
                  id="create-course-slug"
                  value={slug}
                  onChange={(event) => {
                    const val = event.target.value.trim();
                    val === ""
                      ? setSlugError("Please enter a friendly url")
                      : slugData.includes(val)
                      ? setSlugError("A course already exists with this slug!")
                      : setSlugError("");
                    setSlug(Slugify(val).toLowerCase());
                  }}
                  aria-describedby="create-course-slug"
                  startAdornment={
                    <InputAdornment position="start" className="input-prefix">
                      Marketceptionacademy.com/courses/
                    </InputAdornment>
                  }
                />
                <FormHelperText>{slugError}</FormHelperText>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Grid
              className="course-create__left"
              item
              xs={12}
              md={4}
              style={{ backgroundColor: "green" }}
            >
              <span className="course-crete__title">Course Access</span>
              <VpnKeyRoundedIcon fontSize="large" />
              <p></p>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={`course-create__right ${input_classes.root}`}
            >
              <span className="title">
                <strong>Select the access type of your course</strong>
              </span>
              <FormControl
                component="fieldset"
                className="course-create__access"
              >
                <RadioGroup
                  name="access"
                  aria-label="course-access"
                  value={type}
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="paid"
                    control={<Radio color="primary" className="access-radio" />}
                    label={
                      <div className="radio-container">
                        <span className="title">Paid</span>
                        <span className="description">
                          This course will be accessible only to users that
                          purchased it through the site (or have been enrolled
                          via an API or an administrator)
                        </span>
                        <div className="active-check">
                          <DoneOutlineRoundedIcon />
                        </div>
                      </div>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="draft"
                    control={<Radio color="primary" className="access-radio" />}
                    label={
                      <div className="radio-container">
                        <span className="title">Draft</span>
                        <span className="description">
                          This course will be in authoring mode hence not
                          published/visible. This is the preferable mode when a
                          course is under development.
                        </span>
                        <div className="active-check">
                          <DoneOutlineRoundedIcon />
                        </div>
                      </div>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="soon"
                    control={<Radio color="primary" className="access-radio" />}
                    label={
                      <div className="radio-container">
                        <span className="title">Coming Soon</span>
                        <span className="description">
                          The course will be published however it will not allow
                          enrollments. A course cardd will be shown indicating
                          that the course will become available soon.
                        </span>
                        <div className="active-check">
                          <DoneOutlineRoundedIcon />
                        </div>
                      </div>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="free"
                    control={<Radio color="primary" className="access-radio" />}
                    label={
                      <div className="radio-container">
                        <span className="title">Free</span>
                        <span className="description">
                          The course will be accessible to anyone for free.
                          Users will be able to sign up and study the course.
                        </span>
                        <div className="active-check">
                          <DoneOutlineRoundedIcon />
                        </div>
                      </div>
                    }
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Grid
              className="course-create__left"
              item
              xs={12}
              md={4}
              style={{ backgroundColor: "blue" }}
            >
              <span className="course-crete__title">Course Price</span>
              <AccountBalanceRoundedIcon fontSize="large" />
              <p></p>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={`course-create__right ${input_classes.root}`}
            >
              <span className="title">
                <strong>Give a price to your course</strong>
              </span>
              <p className="description">
                This is the starting price for your course
              </p>
              <FormControl
                className={input_classes.inputRoot}
                error={priceError !== ""}
              >
                <InputLabel
                  htmlFor="create-course-price"
                  className={input_classes.label}
                >
                  Starting Price
                </InputLabel>
                <Input
                  className={input_classes.input}
                  id="create-course-price"
                  value={price}
                  onChange={(event) => {
                    event.target.value.trim() === ""
                      ? setPriceError("Please enter a starting price")
                      : setPriceError("");
                    let val = event.target.value;
                    if (val.slice(val.length - 1) !== "." && val.trim() !== "")
                      val = parseFloat(val);
                    // if (isNaN(val)) val = "";
                    // setPrice(val);
                    if (!isNaN(val)) val = setPrice(val);
                  }}
                  aria-describedby="create-course-price"
                  startAdornment={
                    <InputAdornment position="start" className="input-prefix">
                      £
                    </InputAdornment>
                  }
                />
                <FormHelperText>{priceError}</FormHelperText>
              </FormControl>
              <FormControlLabel
                className={input_classes.margin}
                control={
                  <Checkbox
                    checked={useDiscount}
                    name="usediscountPrice"
                    onChange={(event) => setUseDiscount(event.target.checked)}
                  />
                }
                label="Use Discounted Price"
              />
              <FormControl
                className={(input_classes.inputRoot, input_classes.margin)}
                error={discountPriceError !== ""}
                disabled={!useDiscount}
              >
                <InputLabel
                  htmlFor="create-course-price"
                  className={input_classes.label}
                >
                  Discounted Price
                </InputLabel>
                <Input
                  className={input_classes.input}
                  id="create-course-price"
                  value={discountPrice}
                  onChange={(event) => {
                    event.target.value.trim() === ""
                      ? setDiscountPriceError("Please enter a discount price")
                      : setDiscountPriceError("");
                    let val = event.target.value;
                    if (val.slice(val.length - 1) !== "." && val.trim() !== "")
                      val = parseFloat(val);
                    if (!isNaN(val)) val = setDiscountPrice(val);
                  }}
                  aria-describedby="create-course-price"
                  startAdornment={
                    <InputAdornment position="start" className="input-prefix">
                      £
                    </InputAdornment>
                  }
                />
                <FormHelperText>{discountPriceError}</FormHelperText>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <Grid
              className="course-create__left"
              item
              xs={12}
              md={4}
              style={{ backgroundColor: "green" }}
            >
              <span className="course-crete__title">Course Card Preview</span>
              {/* <PermMediaRoundedIcon fontSize="large" /> */}
              <p></p>
              <CourseTile
                course={
                  course
                    ? course
                    : {
                        title: title,
                        slug: slug,
                        price: price,
                        discountPrice: discountPrice,
                        useDiscount: useDiscount,
                        type: type,
                        thumbnail: imageSrc,
                        description: description,
                      }
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={`course-create__right ${input_classes.root}`}
            >
              <span className="title">
                <strong>Upload an image for your course card</strong>
              </span>
              <p className="description">
                Go for something original and high quality (generic stock photos
                suck). You need something memorable that will resonate with your
                target audience
              </p>
              <DropzoneAreaBase
                acceptedFiles={["image/jpeg", "image/png"]}
                maxFileSize={5000000}
                filesLimit={1}
                dropzoneClass={`dropzone ${image ? "dropzone__hasImage" : ""}`}
                fileObjects={image ? [image] : []}
                onAdd={(files) => {
                  const file = files[0];
                  if (file) {
                    setImage(file);
                    if (file.data) setImageSrc(file.data);
                  }
                }}
                onDelete={() => {
                  setImage();
                  setImageSrc();
                }}
              />
              {imageError === "" ? (
                <React.Fragment />
              ) : (
                <Alert severity="error" className={input_classes.margin}>
                  {imageError}
                </Alert>
              )}
              <FormControl
                className={(input_classes.inputRoot, input_classes.margin)}
                error={descriptionError !== ""}
              >
                <InputLabel
                  htmlFor="create-course-description"
                  className={input_classes.label}
                >
                  Your description
                </InputLabel>
                <Input
                  className={input_classes.input}
                  id="create-course-description"
                  multiline
                  value={description}
                  disabled={loading}
                  onChange={(event) => {
                    event.target.value.trim() === ""
                      ? setDescriptionError("Please enter a description")
                      : setDescriptionError("");
                    setDescription(event.target.value);
                  }}
                  aria-describedby="create-course-description"
                />
                <FormHelperText>{descriptionError}</FormHelperText>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      default:
        return <React.Fragment />;
    }
  };
  const handleNext = () => {
    let err = false;
    if (activeStep === 0) {
      if (title === null || title.trim() === "") {
        setTitleError("Please enter a title");
        err = true;
      }
      if (slug === null || slug.trim() === "") {
        setSlugError("Please enter a friendly url");
        err = true;
      } else if (slugData.includes(slug)) {
        err = true;
        setSlugError("A course already exists with this slug!");
      }
    } else if (activeStep === 1) {
      if (type !== "paid") {
        err = true;
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      }
    } else if (activeStep === 2) {
      const compPrice = 0.01;
      if (price === "") {
        err = true;
        setPriceError("Please enter a starting price");
      } else if (isNaN(parseFloat(price) + compPrice)) {
        err = true;
        setPriceError("Please enter a valid price");
      }
      if (useDiscount === true) {
        if (discountPrice === "") {
          err = true;
          setDiscountPriceError("Please enter a discount price");
        } else if (isNaN(parseFloat(discountPrice) + compPrice)) {
          err = true;
          setDiscountPriceError("Please enter a valid price");
        }
      } else {
        setDiscountPriceError("");
      }
      // discountPrice;
      // useDiscount;
    } else if (activeStep === 3) {
      if (!image) {
        err = true;
        setImageError("Please upload an image");
      }
      if (description === null || description.trim() === "") {
        setDescriptionError("Please enter a description");
        err = true;
      }
      if (err === false) {
        err = true;
        setLoading(true);
        let dat = {
          title: title,
          slug: slug,
          price: price,
          access: type,
          discountPrice: discountPrice,
          useDiscount: useDiscount,
          image: image.file,
          description: description,
          delete: false,
        };
        if (course) {
          dat.id = course.id;
        }
        console.log(dat);
        updateCourse({
          variables: dat,
        })
          .then(({ data }) => {
            const token = data["action"];
            if (token === null) {
              alert("An error has occured, Please try again!");
            } else {
              refetch();
              modalClose();
            }
          })
          .catch((e) => {
            console.log(e);
            alert("An error has occured, Please try again!");
            setLoading(false);
          });
      }
    }
    if (err === false) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    activeStep === 3 && type !== "paid"
      ? setActiveStep((prevActiveStep) => prevActiveStep - 2)
      : setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid container className={`${classes.root} course-create`}>
      {/* <Grid item xs={12}>
        <Carousel
          autoPlay={false}
          interval={2000}
          animation="slide"
          navButtonsAlwaysVisible={true}
        >
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </Grid> */}
      <Grid container className="course-create__content">
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          // <div>
          //   <Typography className={classes.instructions}>
          //   {getStepContent(activeStep)}
          //   </Typography>
          // </div>
          getStepContent(activeStep)
        )}
      </Grid>
      {/* <Grid item xs={12} md={8}></Grid> */}
      <Grid item xs={12} md={8} className="course-create__bottom-holder">
        <div className="course-create__bottom">
          <div>
            <Button
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
              className={classes.button}
            >
              Back
            </Button>
          </div>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            className="course-create__stepper"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className={classes.btnWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={loading}
              className={`${classes.button} ${
                activeStep === steps.length - 1 ? classes.buttonFinal : ""
              }`}
            >
              {activeStep === steps.length - 1 ? "Create" : "Next"}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
// function Item(props) {
//   return (
//     <Paper>
//       <h2>{props.item.name}</h2>
//       <p>{props.item.description}</p>

//       <Button className="CheckButton">Check it out!</Button>
//     </Paper>
//   );
// }
export default CreateCourse;
