import React from "react";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { Api } from "../../Api";
import {
  Paper,
  Grid,
  Tooltip,
  Button,
  Typography,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Alert,
  AlertTitle,
  Box,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Vimeo from "@u-wave/react-vimeo";
import { useMutation } from "@apollo/client";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ContainerLoader from "../ContainerLoader";
import { Document, Page } from "react-pdf";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";
import ZoomOutRoundedIcon from "@material-ui/icons/ZoomOutRounded";
import ZoomOutMapRoundedIcon from "@material-ui/icons/ZoomOutMapRounded";
import GradientButton from "../GradientButton";
import ExamDisplay from "./ExamDisplay";
import { spacing } from "../utilities/utils";

const zoomNum = 0.25;
const maxZoom = 4.0;
const minZoom = 0.25;

const ViewActivityContent = ({ track, updateQuery }) => {
  const [zoom, setZoom] = React.useState(1);
  const theme = useTheme();
  const useStyles = makeStyles({
    docMain: {
      width: "100%",
      position: "relative",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: spacing(theme, 2, 4, 3),
    },
    pageMain: {
      "& svg": {
        width: "100%!important",
        height: "auto!important",
        objectFit: "contain",
      },
      "& .react-pdf__Page__svg": {
        width: `${100 * zoom}%!important`,
        height: "100%!important",
        transition: "all .3s",
        margin: "0 auto",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.4), 0px 2px 2px 0px rgba(0,0,0,0.3), 0px 1px 5px 0px rgba(0,0,0,0.3);",
      },
    },
  });
  const classes = useStyles();
  const activity = track.activity;
  const type = activity.activityType;

  const [trackId, setTrackId] = React.useState();
  const [vimeoObj, setVimeoObj] = React.useState();
  const [tracked, setTracked] = React.useState([]);
  const [createTrack] = useMutation(Api.createCourseUserTrack);
  const [updateTrackMutate] = useMutation(Api.updateCourseUserTrack);
  const [videoTime, setVideoTime] = React.useState(0);
  const [watchedTime, setWatchedTime] = React.useState(track.watchedTime);
  const [pageNumber, setPageNumber] = React.useState(
    track.currentPage > 0 ? track.currentPage : 1
  );

  const [pageCount, setPageCount] = React.useState(0);
  const [activityLog, setActivityLog] = React.useState([]);
  const [examSubmitData, setExamSubmitData] = React.useState();
  const [examReview, setExamReview] = React.useState(false);
  const questionCount = examSubmitData?.answers.length || 0;

  const [loading, setLoading] = React.useState(false);
  const setExamDat = (ted) => {
    const ed = activity.examData ? JSON.parse(activity.examData) : null;
    return ed && ed.q
      ? {
          id: ted ? ted.length + 1 : 1,
          answers: ed.q.map((obj) => ({
            question: obj,
            values: [],
            error: "",
            success: "",
            mark: null,
            valid: false,
          })),
          feedback: [],
          submitted: null,
          passed: false,
          marks: null,
        }
      : null;
  };
  const retryExam = () => {
    const ted = track.examData ? JSON.parse(track.examData) : null;
    setExamSubmitData(setExamDat(ted));
  };
  React.useEffect(() => {
    if (track.id !== trackId) {
      setTrackId(track.id);
      setZoom(1);
      setPageNumber(track.currentPage > 0 ? track.currentPage : 1);
      setWatchedTime(track.watchedTime);
      setTrackId(track.id);
      setAutoSaveLoading(false);
      setExamReview(false);
      const ted = track.examData ? JSON.parse(track.examData) : null;
      setExamSubmitData(
        type === "exam"
          ? ted && ted.length > 0
            ? ted[ted.length - 1]
            : setExamDat(ted)
          : null
      );
      setActivityLog([]);
      track.activity.activityType === "exam"
        ? setLoading(false)
        : setLoading(true);
      const id = track.id;
      if (!tracked.includes(id)) {
        setTracked((t) => [...t, id]);
        createTrack({
          variables: {
            trackId: id,
          },
        })
          .then((data) => {
            if (!data.data?.createCourseUserTrackL?.courseTrack) {
              setTracked(tracked.filter((item) => item !== id));
            }
          })
          .catch((e) => {
            setTracked(tracked.filter((item) => item !== id));
          });
      }
    }
  }, [track]);

  const updateTrack = (vars, stLoading) => {
    const log = activityLog;
    setActivityLog([]);
    if (activityLog.length > 0) {
      vars["activityLog"] = JSON.stringify(activityLog);
      setActivityLog([]);
    }
    updateTrackMutate({
      variables: {
        trackId: track.id,
        activityLog: JSON.stringify(log),
        ...vars,
      },
    })
      .then(({ data }) => {
        const trs = data.updateCourseUserTrack.courseTrack;
        trs.length > 0 &&
          updateQuery((old) => {
            let track_update = [...old.usersCourseTrack];
            trs.map((a) => {
              for (var i = 0; i < track_update.length; i++) {
                if (track_update[i].id === a.id) {
                  track_update[i] = { ...track_update[i], ...a };
                  break;
                }
              }
              return null;
            });
            return {
              usersCourseTrack: track_update,
            };
          });
        stLoading && stLoading(false);
      })
      .catch((e) => {
        console.log(e);
        alert(Api.errorAlert(e));
        stLoading && stLoading(false);
      });
  };
  const [autoSaveLoading, setAutoSaveLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const updateExam = (passData) => {
    const finalData = passData ?? examSubmitData;
    !passData && setAutoSaveLoading(true);
    let ted = track.examData ? JSON.parse(track.examData) : null;
    let sDat = [];
    if (ted) {
      const ind = ted.findIndex((o) => o.id === finalData.id);
      ind >= 0 ? (ted[ind] = finalData) : ted.push(finalData);
      sDat = ted;
    } else {
      sDat = [finalData];
    }
    const finD = JSON.stringify(sDat);
    !(ted && finD === track.examData)
      ? updateTrack(
          passData ? { examData: finD, examToMark: true } : { examData: finD },
          passData ? setLoading : setAutoSaveLoading
        )
      : passData
      ? setLoading(false)
      : setAutoSaveLoading(false);
  };
  const submitExam = () => {
    setLoading(true);
    const esd = { ...examSubmitData, submitted: new Date() };
    updateExam(esd);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // pageCount
  // currentPage
  // examData
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="submit-exam"
        aria-describedby="submit-exam-"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box
              sx={{
                borderBottom: 1,
                mb: 2,
                pb: 1
              }}>
              <Typography variant="h5">Submit Exam</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" id="transition-modal-description">
                Are you sure you want to submit the exam for activity <br />
                <strong>{`${track.section.order}.${activity.order} ${activity.title}`}</strong>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                borderTop: 1,
                mt: 3,
                pt: 2,
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}>
              <Box
                sx={{
                  mr: 1
                }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
              <GradientButton
                loading={loading}
                onClick={submitExam}
                // color="primary"
                // variant="outlined"
                endIcon={<SendOutlinedIcon />}
              >
                Submit
              </GradientButton>
            </Box>
          </div>
        </Fade>
      </Modal>
      <ContainerLoader loading={loading} />
      {(type === "exam" || type === "pdf") && (
        <Paper elevation={2} style={{ position: "relative", zIndex: 1 }}>
          <Box
            sx={{
              px: 2,
              minHeight: "48px",
              display: "flex",
              alignItems: "center"
            }}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Box
                  component="span"
                  sx={{
                    fontWeight: 600,
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}>
                  {activity.title}
                </Box>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 14,
                  px: 2
                }}>
                {type === "pdf" ? (
                  <React.Fragment>
                    <Box>
                      <IconButton
                        color="primary"
                        disabled={pageNumber - 1 === 0}
                        onClick={() => {
                          if (pageNumber - 1 > 0) {
                            setPageNumber(pageNumber - 1);
                            updateTrack({
                              currentPage: pageNumber - 1,
                              pageCount: pageCount,
                            });
                          }
                        }}
                      >
                        <ChevronLeftRoundedIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        px: 2
                      }}>{`${pageNumber} of ${pageCount} Pages`}</Box>
                    <Box>
                      <IconButton
                        color="primary"
                        // variant="outlined"
                        onClick={() => {
                          if (pageNumber + 1 <= pageCount) {
                            setPageNumber(pageNumber + 1);
                            updateTrack({
                              currentPage: pageNumber + 1,
                              pageCount: pageCount,
                            });
                          }
                        }}
                        disabled={pageNumber + 1 > pageCount}
                      >
                        <ChevronRightRoundedIcon />
                      </IconButton>
                    </Box>
                  </React.Fragment>
                ) : (
                  type === "exam" && (
                    <Box
                      sx={{
                        px: 2
                      }}>{`${
                      examSubmitData?.answers
                        ? examSubmitData.answers.filter(
                            (obj) =>
                              obj.values[0] && obj.values[0].trim().length > 0
                          ).length
                        : 0
                    } / ${questionCount} Questions Answered`}</Box>
                  )
                )}
              </Box>
              <Grid item xs={3}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    overflow: "scroll",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mr: 3
                  }}>
                  {type === "pdf" ? (
                    <React.Fragment>
                      <Tooltip title="Reset Zoom">
                        <Box>
                          <IconButton
                            disabled={zoom === 1}
                            color="secondary"
                            onClick={() => setZoom(1)}
                          >
                            <ZoomOutMapRoundedIcon />
                          </IconButton>
                        </Box>
                      </Tooltip>
                      <Tooltip title="Zoom Out" color="secondary">
                        <IconButton
                          disabled={zoom === minZoom}
                          onClick={() =>
                            zoom - zoomNum >= minZoom && setZoom(zoom - zoomNum)
                          }
                        >
                          <ZoomOutRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      <Box
                        component="span"
                        sx={{
                          px: 1
                        }}>
                        {parseInt(zoom * 100)}%
                      </Box>
                      <Tooltip title="Zoom In" color="secondary">
                        <IconButton
                          disabled={zoom === maxZoom}
                          onClick={() =>
                            zoom + zoomNum <= maxZoom
                              ? setZoom(zoom + zoomNum)
                              : setZoom(maxZoom)
                          }
                        >
                          <ZoomInRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      {activity.downloadable && (
                        <Tooltip title="Download (Coming Soon)">
                          <Box>
                            <IconButton color="secondary" disabled>
                              <GetAppRoundedIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      )}
                    </React.Fragment>
                  ) : (
                    type === "exam" && (
                      <React.Fragment>
                        {autoSaveLoading && (
                          <Box
                            sx={{
                              position: "relative",
                              display: "flex",
                              pr: 2
                            }}>
                            <Tooltip title="Auto Save">
                              <CircularProgress size={20} />
                            </Tooltip>
                          </Box>
                        )}
                        {!examSubmitData?.submitted && (
                          <Tooltip
                            title={
                              examSubmitData?.answers
                                ? examSubmitData.answers.filter(
                                    (obj) =>
                                      obj.values[0] &&
                                      obj.values[0].trim().length > 0
                                  ).length !== questionCount
                                  ? "Please answer all the questions!"
                                  : "Submit Exam!"
                                : "Loading..."
                            }
                          >
                            <Box
                              sx={{
                                py: 1
                              }}>
                              <Button
                                color="primary"
                                disabled={
                                  examSubmitData?.answers
                                    ? examSubmitData.answers.filter(
                                        (obj) =>
                                          obj.values[0] &&
                                          obj.values[0].trim().length > 0
                                      ).length !== questionCount
                                    : true
                                }
                                variant="outlined"
                                endIcon={<SendOutlinedIcon />}
                                onClick={handleOpen}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Tooltip>
                        )}
                      </React.Fragment>
                    )
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      <Box
        sx={{
          overflow: "scroll",
          position: "relative",
          height: `calc(100vh - 128px ${type === "pdf" ? "- 48px" : ""})`,
          pb: 3
        }}>
        {type === "video" && (
          <Box
            sx={{
              pt: 3,
              pb: 1,
              mx: 2,
              borderBottom: "2px solid lightGrey"
            }}>
            <Typography variant="h5">{activity.title}</Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            px: type === "pdf" ? 1 : 2,
            pt: 3,
            pb: 5,
            height: "100%"
          }}>
          {type === "video" ? (
            activity.vimeoId ? (
              <Box
                sx={{
                  border: "2px solid lightGrey",
                  borderRadius: "10px",
                  overflow: "hidden",
                  height: "100%",
                  width: "100%"
                }}>
                <Vimeo
                  start={track.watchedTime}
                  video={activity.vimeoId}
                  onError={(e) => {
                    Api.errorAlert(e);
                  }}
                  onReady={(obj) => {
                    setLoading(false);
                    setVimeoObj(obj);
                    obj
                      .getDuration()
                      .then((data) => {
                        setVideoTime(data);
                      })
                      .catch((e) => {
                        console.log(e);
                        Api.errorAlert(e);
                      });
                  }}
                  onTimeUpdate={() => {
                    vimeoObj
                      .getCurrentTime()
                      .then((seconds) => {
                        const s = Math.floor(seconds);
                        setWatchedTime(s);
                        if (s !== watchedTime && s % 10 === 0) {
                          updateTrack({ watchedTime: s, videoTime: videoTime });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        Api.errorAlert(e);
                      });
                  }}
                  responsive
                />
              </Box>
            ) : (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                An error has occurred! Please contact us if the error persists.
              </Alert>
            )
          ) : type === "pdf" ? (
            <React.Fragment>
              <Document
                renderMode="svg"
                className={classes.docMain}
                file={Api.formatMediaUrl(track.activity.dataFile)}
                // externalLinkTarget={Api.formatMediaUrl(track.activity.dataFile)}
                onLoadSuccess={({ numPages }) => {
                  setPageCount(numPages);
                  setLoading(false);
                }}
                onLoadError={() => {
                  setLoading(false);
                }}
                // onLoadProgress
                error={
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    An error has occurred! Please contact us if the error
                    persists.
                  </Alert>
                }
              >
                <Page pageNumber={pageNumber} className={classes.pageMain} />
              </Document>
            </React.Fragment>
          ) : (
            type === "exam" && (
              <ExamDisplay
                examSubmitData={examSubmitData}
                setExamSubmitData={setExamSubmitData}
                examReview={examReview}
                setExamReview={setExamReview}
                retryExam={retryExam}
                updateExam={updateExam}
              />
            )
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ViewActivityContent;
