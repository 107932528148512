import React from "react";
import {
  Grid,
  Paper,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Portal,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import Vimeo from "@u-wave/react-vimeo";
import { useMutation } from "@apollo/client";
import { Api, activityType } from "../../Api";
import TextField from "../forms/TextField";
import { DropzoneAreaBase } from "material-ui-dropzone";
// import PDFViewer from "pdf-viewer-reactjs";
import EditExamActivity from "./EditExamActivity";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { secondsToStr } from "../utilities/utils";

const useStyles = makeStyles((theme) => ({
  buttonFinal: {
    // height: 35,
    width: "100%",
    "& > span": {
      filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))",
    },
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  grey: {
    color: "grey",
  },
  btnWrapper: {
    flex: 1,
    maxWidth: 120,
    maxHeight: 35,
    position: "relative",
  },
  btnFill: { maxWidth: "100%" },
  btnBorder: { borderTop: "1px dashed lightGrey" },
  bottomBorder: { borderBottom: "1px dashed lightGrey" },
  vimeoContainer: {
    height: 104,
  },
  stickyBtn: {
    position: "sticky",
    bottom: "0",
    backgroundColor: "white",
  },
  relativeContainer: {
    position: "relative",
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  imgContainer: {
    height: "100%",
    width: 185,
    border: "1px solid lightGrey",
    borderRadius: 5,
    overflow: "hidden",
  },
  img: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
  },
  flex1: {
    flex: 1,
  },
  label: {
    // textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 16,
  },
  margin: {
    marginTop: 10,
  },
  animatePanel: {
    transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
  },
  animatePanelActive: { marginLeft: "-100%" },
  animatePanelHidden: {
    marginRight: "-100%",
  },
}));
const checkBoxProps = {
  xs: 12,
  md: 6,
  lg: 4,
  xl: 3,
};
const drawerContainerProps = {
  ml: 3,
  mr: 4,
  my: 3,
};
function UpdateActivity({ activity, refetch, drawerNavController }) {
  const classes = useStyles();
  const time = activity.videoTime;
  const type = activity.activityType;
  const typeObj = activityType[type];
  const [loading, setLoading] = React.useState(false);
  const [updateActivityMutate] = useMutation(Api.updateCourseActivity);
  const [title, setTitle] = React.useState(activity.title);
  const [titleError, setTitleError] = React.useState("");
  const titleRef = React.useRef(null);
  const [vimeoId, setVimeoId] = React.useState(activity.vimeoId);
  const vimeoIdRef = React.useRef(null);
  const [vimeoIdError, setVimeoIdError] = React.useState("");
  const [videoTime, setVideoTime] = React.useState(activity.videoTime);
  const [prefix, setPrefix] = React.useState();
  const [paid, setPaid] = React.useState(activity.paid);
  const [draft, setDraft] = React.useState(activity.draft);
  const [comingSoon, setComingSoon] = React.useState(activity.comingSoon);
  const [description, setDescription] = React.useState(activity.description);
  const [descriptionError, setDescriptionError] = React.useState("");
  const [vimeoLoading, setVimeoLoading] = React.useState(false);
  const [vimeoPlayer, setVimeoPlayer] = React.useState();
  const [activityFile, setActivityFile] = React.useState();
  const [fileName, setFileName] = React.useState(activity.fileName);
  const [pageCount, setPageCount] = React.useState(activity.pageCount);
  const [pdfViewer, setPdfViewer] = React.useState();
  const [downloadable, setDownloadable] = React.useState(activity.downloadable);
  const [examData, setExamData] = React.useState(
    activity.examData ? JSON.parse(activity.examData) : { q: [], length: 0 }
  );
  const [showExamEdit, setShowExamEdit] = React.useState(false);

  React.useEffect(() => {
    if (type === "video") {
      setPrefix(secondsToStr(time ? time : 0));
    }
  }, []);
  React.useEffect(() => {
    let isCancelled = false;
    if (type === "pdf" && activityFile && !pageCount) {
      try {
        if (!isCancelled) {
          // setPdfViewer(
          //   <PDFViewer
          //     document={{
          //       base64: activityFile.data.replace(
          //         "data:application/pdf;base64,",
          //         ""
          //       ),
          //     }}
          //     getMaxPageCount={(pages) => {
          //       if (!isCancelled && pages > 0) {
          //         setPageCount(pages);
          //         setPdfViewer();
          //       }
          //     }}
          //   />
          // );
          alert("No method available to get page count")
        } else {
          setPdfViewer();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setPdfViewer();
    }
    return () => {
      isCancelled = true;
    };
  }, [activityFile]);
  const updateActivityData = () => {
    setLoading(true);
    let err = false;
    if (title.trim() === "") {
      err = true;
      setTitleError("Please enter a title!");
    }
    if (err === false) {
      updateActivityMutate({
        variables: {
          sectionId: activity.section.id,
          activityId: activity.id,
          title: title,
          // order
          paid: paid,
          draft: draft,
          comingSoon: comingSoon,
          delete: false,
          activityType: type,
          dataFile: activityFile ? activityFile.file : null,
          vimeoId: videoTime ? vimeoId : null,
          examData:
            activity.activityType === "exam" ? JSON.stringify(examData) : null,
          description: description,
          videoTime: videoTime,
          pageCount: pageCount,
          downloadable: downloadable,
        },
      })
        .then((data) => {
          if (data?.data?.action?.courseActivity?.id) {
            if (refetch) refetch();
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const vimeoIdChange = (e) => {
    const val = e.target.value;
    setVimeoId(val);
    if (val.trim() === "") {
      setVimeoIdError("Please enter a Vimeo ID!");
      vimeoIdRef.current.focus();
      setVimeoLoading(false);
      setVideoTime();
    } else if (!isNaN(val)) {
      setVimeoIdError("");
    } else {
      setVimeoIdError("Please enter a valid Vimeo ID!");
      setVideoTime();
    }
  };
  const checkVimeoError = (e) => {
    setVimeoIdError(e.message);
    // vimeoIdRef.current.focus();
    // setVimeoLoading(false);
  };
  const checkVimeoReady = (video) => {
    setVimeoPlayer(video);
  };

  const checkVimeoLoaded = (e) => {
    setVimeoLoading(true);
    if (vimeoPlayer) {
      vimeoPlayer
        .getDuration()
        .then((data) => {
          activity.videoTime = data;
          setVideoTime(data);
          setPrefix(secondsToStr(data));
          setVimeoLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setVimeoIdError(e.message);
          setVimeoLoading(false);
        });
    } else {
      setVimeoLoading(false);
      setVimeoIdError(
        "No player found, Please close the Drawer and try again."
      );
    }
  };
  return (
    <Box
      sx={{
        width: {
          md: "70vw",
          lg: "50vw",
        },
      }}
    >
      <Portal container={drawerNavController.current}>
        {showExamEdit && (
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Button
              color="secondary"
              startIcon={
                <ArrowBackRoundedIcon
                  color="secondary"
                  className="inh-ignore"
                />
              }
              onClick={() => setShowExamEdit(false)}
            >
              Back
            </Button>
          </Box>
        )}
      </Portal>
      <Grid container>
        {/* <Grid item></Grid> */}
        <Grid
          item
          xs={12}
          className={`${showExamEdit ? classes.animatePanelActive : ""} ${
            classes.animatePanel
          }`}
        >
          <Box {...drawerContainerProps}>
            <Box
              sx={{
                mb: 3,
              }}
            >
              <TextField
                error={titleError}
                updateError={setTitleError}
                errorText="Please enter a title!"
                value={title}
                updateValue={setTitle}
                loading={loading}
                id="update-activity-title"
                label="Activtiy Title"
                required
                inpref={titleRef}
              />
            </Box>
            <Box
              sx={{
                mb: 4,
              }}
            >
              <TextField
                error={descriptionError}
                multiline
                updateError={setDescriptionError}
                errorText="Please enter a description!"
                value={description}
                updateValue={setDescription}
                loading={loading}
                id="update-activity-description"
                label="Description"
              />
            </Box>
            {type === "video" ? (
              <Box
                sx={{
                  my: 3,
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  className={classes.vimeoContainer}
                >
                  <Box className={classes.imgContainer}>
                    {isNaN(vimeoId) ||
                    (vimeoId && vimeoId.trim() === "") ||
                    !vimeoId ? (
                      <React.Fragment />
                    ) : (
                      <Vimeo
                        video={vimeoId}
                        onError={checkVimeoError}
                        onReady={checkVimeoReady}
                        onLoaded={checkVimeoLoaded}
                        responsive
                      />
                    )}
                  </Box>
                  <Box
                    className={classes.relativeContainer}
                    sx={{
                      ml: 2,
                      mr: 3,
                    }}
                  >
                    <Box
                      component="span"
                      className="activity-item__prefix"
                      style={{ fontWeight: "bold" }}
                    >
                      {prefix}
                    </Box>
                    {vimeoLoading ? (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    ) : (
                      <React.Fragment />
                    )}
                  </Box>

                  <Box className={classes.flex1}>
                    <TextField
                      error={vimeoIdError}
                      required
                      // updateError={setVimeoIdError}
                      // errorText="Please enter a Vimeo ID!"
                      onChange={vimeoIdChange}
                      value={vimeoId}
                      // updateValue={setVimeoId}
                      loading={loading}
                      id="update-activity-vimeo-id"
                      label="Vimeo ID"
                      inpref={vimeoIdRef}
                    />
                  </Box>
                </Grid>
              </Box>
            ) : type === "exam" ? (
              <Box
                sx={{
                  pt: 2,
                  mt: 2,
                }}
              >
                <Grid container alignItems="center">
                  <Typography style={{ fontWeight: 600 }}>{`${
                    examData.length
                  } Question${examData.length === 1 ? "" : "s"}`}</Typography>
                  <Box
                    className={classes.btnWrapper}
                    sx={{
                      ml: 3,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowExamEdit(!showExamEdit)}
                      disabled={loading}
                      className={classes.buttonFinal}
                    >
                      Edit Exam
                    </Button>
                  </Box>
                </Grid>
              </Box>
            ) : (
              <React.Fragment>
                <Grid container alignItems="center">
                  {typeObj &&
                    typeObj.setIconProps(typeObj, {
                      fontSize: "large",
                      className: "activity-item__order",
                    })}
                  <Box
                    sx={{
                      mr: 2,
                    }}
                  />
                  <Typography>
                    {fileName ? fileName : "Please upload a file"}
                  </Typography>
                  <Box
                    sx={{
                      ml: "auto",
                    }}
                  ></Box>
                  <Typography>
                    {pageCount ? (
                      <React.Fragment>
                        <strong>{pageCount}</strong> Page
                        {pageCount === 1 ? "" : "s"}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </Typography>
                  {/* <Typography><small>{ activity.fileName===null ||(fileName==activity.fileName && activityFile)?"":"(Changes not saved)"}</small></Typography> */}
                </Grid>
                {/* {type==="pdf"&&activityFile && !pageCount  ?
                  :<></>
                } */}
                {pdfViewer ? (
                  <Box
                    sx={{
                      display: "none",
                    }}
                  >
                    {pdfViewer}
                  </Box>
                ) : (
                  <></>
                )}
                {type === "file" ? <React.Fragment /> : <></>}
                <Box
                  sx={{
                    mb: 2,
                  }}
                />
                <FormControlLabel
                  className={(classes.margin, classes.label, classes.flex1)}
                  control={
                    <Checkbox
                      checked={downloadable}
                      name="downloadable"
                      disabled={loading}
                      onChange={(event) =>
                        setDownloadable(event.target.checked)
                      }
                    />
                  }
                  label="Downloadable"
                />
                <Box
                  sx={{
                    mb: 3,
                  }}
                />
                <DropzoneAreaBase
                  acceptedFiles={
                    type === "pdf" ? [".pdf"] : [".doc", ".docx", ".txt"]
                  }
                  maxFileSize={5000000}
                  filesLimit={1}
                  dropzoneClass={`dropzone ${
                    activityFile ? "dropzone__hasImage" : ""
                  }`}
                  fileObjects={activityFile ? [activityFile] : []}
                  showFileNames={true}
                  onAdd={(files) => {
                    const file = files[0];
                    if (file) {
                      setActivityFile(file);
                      setFileName(file.file.name);
                      setPageCount();
                    }
                  }}
                  onDelete={() => {
                    setActivityFile();
                    setFileName();
                    setPageCount();
                  }}
                />
              </React.Fragment>
            )}
            <Box
              sx={{
                mt: 4,
                mb: 3,
              }}
            >
              <Grid container wrap="nowrap">
                <Grid item {...checkBoxProps}>
                  <FormControlLabel
                    className={(classes.margin, classes.label, classes.flex1)}
                    control={
                      <Checkbox
                        checked={paid}
                        name="paid"
                        disabled={loading}
                        onChange={(event) => setPaid(event.target.checked)}
                      />
                    }
                    label="Paid"
                  />
                </Grid>
                <Grid item {...checkBoxProps}>
                  <FormControlLabel
                    className={(classes.margin, classes.label, classes.flex1)}
                    control={
                      <Checkbox
                        checked={draft}
                        name="draft"
                        disabled={loading}
                        onChange={(event) => setDraft(event.target.checked)}
                      />
                    }
                    label="Draft"
                  />
                </Grid>
                <Grid item {...checkBoxProps}>
                  <FormControlLabel
                    className={(classes.margin, classes.label, classes.flex1)}
                    control={
                      <Checkbox
                        checked={comingSoon}
                        name="comingSoon"
                        disabled={loading}
                        onChange={(event) =>
                          setComingSoon(event.target.checked)
                        }
                      />
                    }
                    label="Coming Soon"
                  />
                </Grid>
              </Grid>
            </Box>
            {/* <Box mt={3} pt={2} className={classes.btnBorder}/>
          <Box className={classes.btnWrapper,classes.btnBorder,classes.btnFill}>
            <Button
              variant="contained"
              color="primary"
              onClick={updateActivityData}
              disabled={loading}
              className={classes.buttonFinal}
              type="submit"
            >
              Update
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Box> */}
          </Box>
          <Paper elevation={3} className={classes.stickyBtn}>
            <Box
              className={classes.btnBorder}
              sx={{
                pt: 2,
                mx: 3,
              }}
            />
            <Box
              className={(classes.btnWrapper, classes.btnFill)}
              sx={{
                mx: 3,
                pb: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={updateActivityData}
                disabled={loading}
                type="submit"
                className={classes.buttonFinal}
              >
                Update
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid className={classes.animatePanelHidden} item xs={12}>
          <EditExamActivity
            classes={classes}
            drawerContainerProps={drawerContainerProps}
            setShow={setShowExamEdit}
            data={examData}
            setData={setExamData}
          />
        </Grid>
      </Grid>
      {/* <Grid container */}
    </Box>
  );
}

export default UpdateActivity;
