import React from "react";
import { Alert, AlertTitle, Box } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import ContainerLoader from "../ContainerLoader";
import { DataGrid } from "@material-ui/data-grid";
import { Switch, Route, Redirect } from "react-router-dom";
import { isFunction } from "../utilities/utils";
import useResizeObserver from "use-resize-observer";

const EditObj = ({
  Component,
  appendRow,
  obj,
  setObj,
  baseUrl,
  updateQs,
  data,
  params,
  listKey,
}) => {
  React.useEffect(() => appendRow && appendRow(obj, baseUrl), [obj]);
  React.useEffect(() => {
    // console.log("data-change");
    if (data && data.hasOwnProperty(listKey) && data[listKey].length > 0) {
      setObj(data[listKey].find((i) => i.id === params.id));
    }
  }, [data]);
  return !obj ? (
    <Redirect to={baseUrl} />
  ) : (
    <React.Fragment>
      <ContainerLoader loading={!obj?.id} />
      {obj?.id && <Component object={obj} updateQs={updateQs} />}
    </React.Fragment>
  );
};

function QueryLoading({
  query,
  variables,
  Render,
  listKey,
  listTitle,
  listEmpty,
  pollInterval,
  refKey,
  renderProps,
  smallAlert,
  dataGrid,
  editRoute,
  EditComponent,
  appendRow,
  baseUrl,
  aboveTable: AboveTable,
  aboveTableProps,
}) {
  const { columns, ...dataGridRem } = dataGrid || {};
  const { data, loading, error, refetch, updateQuery } = useQuery(query, {
    variables: variables || {},
    pollInterval: pollInterval || null,
  });
  const { ref: onRefChange, height: gridHeight = 700 } = useResizeObserver();
  const [obj, setObj] = React.useState({});
  const updateQs = (id, dat, del) => {
    const deleteObj = !!del;
    //   console.log("update");
    // refetch();
    listKey &&
      updateQuery((old) => {
        let update = [...old[listKey]];
        let updated = false;
        for (var i = 0; i < update.length; i++) {
          if (update[i].id === id) {
            updated = true;
            if (deleteObj) {
              update.splice(i, 1);
            } else {
              update[i] = { ...update[i], ...dat };
            }

            break;
          }
        }
        if (!updated && dat?.id) {
          update.push(dat);
        }
        return {
          [listKey]: update,
        };
      });
  };
  return (
    <Box>
      <Switch>
        {editRoute && EditComponent && (
          <Route
            exact
            path={editRoute}
            render={({ match }) => {
              const params = match.params;
              return (
                <React.Fragment>
                  <ContainerLoader loading={loading} />
                  {!loading &&
                    data &&
                    (EditComponent && params && data && data[listKey] ? (
                      <EditObj
                        updateQs={updateQs}
                        appendRow={appendRow}
                        data={data}
                        params={params}
                        Component={EditComponent}
                        baseUrl={baseUrl}
                        listKey={listKey}
                        obj={obj}
                        setObj={setObj}
                      />
                    ) : (
                      baseUrl && <Redirect to={baseUrl} />
                    ))}
                </React.Fragment>
              );
            }}
          />
        )}
        <Route>
          {dataGrid && listKey ? (
            //   height={height - 236 - 10}
            <Box
              sx={{
                mb: 3,
                pb: 3,
              }}
            >
              {AboveTable && (
                <Box
                  sx={{
                    mb: 3,
                  }}
                >
                  <AboveTable
                    data={data}
                    refetch={refetch}
                    updateData={updateQs}
                    {...aboveTableProps}
                  />
                </Box>
              )}
              <Box
                sx={{
                  height: gridHeight,
                }}
              >
                <DataGrid
                  loading={loading}
                  ref={onRefChange}
                  rows={!loading && data && data[listKey] ? data[listKey] : []}
                  // pageSize={10}
                  autoHeight={true}
                  // onPageSizeChange={(e) => console.log(e)}
                  // autoPageSize={true}
                  // onCellClick={(cell, e) => {
                  //   console.log(cell, e);
                  // }}
                  showToolbar
                  pagination
                  pageSize={25}
                  columns={
                    isFunction(columns)
                      ? columns({ updateQs: updateQs })
                      : columns
                  }
                  {...dataGridRem}
                />
              </Box>
            </Box>
          ) : (
            <React.Fragment>
              <ContainerLoader loading={loading} />
              {loading ? (
                <React.Fragment />
              ) : !data && error ? (
                <Alert severity="error">
                  {!smallAlert && (
                    <AlertTitle>An Error has Occurred</AlertTitle>
                  )}
                  {smallAlert
                    ? "An Error has Occurred"
                    : "Please try again in a few moments, If the error persists please contact us!"}
                </Alert>
              ) : listKey &&
                data[listKey] &&
                ((Array.isArray(data[listKey]) && data[listKey].length === 0) ||
                  !Array.isArray(data[listKey])) ? (
                <Alert severity="info">
                  {!smallAlert && <AlertTitle>Info</AlertTitle>}
                  {listEmpty ? (
                    listEmpty
                  ) : (
                    <React.Fragment>
                      No {listTitle || "Item"}s found! -{" "}
                      <strong>Please Create a {listTitle || "Item"}</strong>
                    </React.Fragment>
                  )}
                </Alert>
              ) : (
                <Render
                  data={listKey ? data[listKey] : refKey ? data[refKey] : data}
                  {...renderProps}
                />
              )}
            </React.Fragment>
          )}
        </Route>
      </Switch>
    </Box>
  );
}

export default QueryLoading;
