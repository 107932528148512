import { useMutation } from "@apollo/client";
import { Box, Grid, Typography } from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import React from "react";
import { Api } from "../../../Api";
import ButtonMain from "../../Button";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
const id = "batch-upload-image-field";

const BatchUpload = ({ updateObj, display }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createImage] = useMutation(Api.estatelens.updateImage);
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (e) => {
    if (images.length <= 0) {
      enqueueSnackbar("Please upload at least 1 Image", { variant: "info" });
    } else {
      e.preventDefault(e);
      setLoading(true);
      createImage({
        variables: {
          displayId: display.id,
          imageFiles: images,
        },
      })
        .then(({ data: { updateImage:{newImages} } }) => {
          updateObj({images:newImages});
          enqueueSnackbar(`${newImages.length} Images Uploaded`, { variant: "success" });
          setLoading(false);
          setImages([]);
        })
        .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
      
    }
  };
  const removeImage = (i)=> (e) =>{
    setImages((im)=>im.filter((x,index)=>index!==i))
  }
  return (
    <Box component="form" onSubmit={(e) => e.preventDefault(e)}>
      {/* <DropzoneAreaBase
        acceptedFiles={["image/jpeg"]}
        maxFileSize={5000000}
        // filesLimit={1}
        dropzoneClass={`dropzone ${images.length>0 ? "dropzone__hasImage" : ""}`}
        fileObjects={images}
        onAdd={(files) => {
            // const file = files[0];
            // if (file) {
            // setImage(file);
            // if (file.data) setImageSrc(file.data);
            // }
            setImages((img)=>[...img,...files])
        }}
        onDelete={(obj) => {
            console.log(obj)
        }}
        /> */}
      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          {images.map((img, i) => (
            <Box sx={{ display: "flex",alignContent:"center",alignItems:"center", }} key={i}>
              <Typography sx={{width:200}}>{img.name}</Typography>
              <ButtonMain disabled={loading} tooltip={{title:"Remove Image"}} color="error" boxProps={{ml:3}} onClick={removeImage(i)} iconButton>
                <CancelRoundedIcon />
              </ButtonMain>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} sm={4} md={6} justify="center">
          <label htmlFor={id}>
            <input
              id={id}
              type="file"
              accept="image/*"
              name="image"
              style={{ display: "none" }}
              multiple
              onChange={({ target: { files } }) =>
                setImages((img) => [...img, ...files])
              }
            />
            <ButtonMain
              boxProps={{ component: "span" }}
              className="btn-choose"
              component="span"
              disabled={loading}
            >
              {`Add Files`}
            </ButtonMain>
          </label>
          <ButtonMain boxProps={{ mt: 5 }} type="submit" loading={loading} tooltip={{title:"Submit"}} onClick={onSubmit}>
            Upload
          </ButtonMain>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatchUpload;
