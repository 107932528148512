import React from "react";
import "./DashBoardLayout.css";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";
import NavBar from "./DashNavBar";
import { IconButton, Box } from "@material-ui/core";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import DashBoardSideNav from "./DashBoardSideNav";
import useWindowDimensions from "../utilities/useWindowDimensions";
import DashboardTabs from "./DashboardTabs";
import { isFunction } from "../utilities/utils";

const iconProps = {};
const DashboardLayout = ({
  title,
  titleIcon,
  links,
  baseUrl,
  ContentHeader,
}) => {
  const contentNavHeight = 58;
  const appbarRef = React.useRef(null);
  const [appBarHeight, setAppBarHeight] = React.useState(64);
  React.useEffect(() => {
    appbarRef?.current?.offsetHeight &&
      setAppBarHeight(appbarRef?.current?.offsetHeight);
  }, [appbarRef?.current?.offsetHeight]);
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState(false);
  const windowSize = useWindowDimensions();
  // const location = useLocation();
  const toggleDrawer = (open) => (event) => {
    // console.log("call");
    if (windowSize.width <= 768) {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      // console.log(open);
      setMobileDrawerOpen(open);
    } else {
      setMobileDrawerOpen(false);
      setCollapse(!collapse);
    }
  };
  return (
    <div className="dashboard-main">
      <NavBar appbarRef={appbarRef} />
      <div className="dashboard">
        <DashBoardSideNav
          title={title}
          collapse={collapse}
          titleIcon={titleIcon}
          links={links}
          mobileDrawerOpen={mobileDrawerOpen}
          setMobileDrawerOpen={setMobileDrawerOpen}
          toggleDrawer={toggleDrawer}
          windowSize={windowSize}
          appBarHeight={appBarHeight}
          contentNavHeight={contentNavHeight}
        />
        <Box
          className="dashboard__content"
          sx={{
            pb: 5,
          }}
        >
          <Switch>
            {links.map(
              (link, i) =>
                !link.disabled && (
                  <Route
                    key={i}
                    path={link.url}
                    {...link.routeProps}
                    render={(props) => (
                      <React.Fragment>
                        {link.headerProps && link.headerProps.links && (
                          <Box
                            className="nav-link content-header separator"
                            sx={{
                              minHeight: contentNavHeight,
                              top: appBarHeight,
                              px: 2,
                            }}
                          >
                            <Box
                              sx={{
                                pr: 1,
                              }}
                            >
                              {windowSize.width <= 768 ? (
                                <IconButton
                                  // sx={{ }}
                                  onClick={toggleDrawer(true)}
                                  color="primary"
                                >
                                  <ListRoundedIcon />
                                </IconButton>
                              ) : (
                                <React.Fragment />
                              )}
                            </Box>

                            {ContentHeader ? (
                              <ContentHeader />
                            ) : (
                              link.headerProps.links.map((li, k) => (
                                <Route
                                  path={
                                    isFunction(li.to) ? li.to(props) : li.to
                                  }
                                  key={k}
                                >
                                  <NavLink
                                    to={
                                      isFunction(li.to) ? li.to(props) : li.to
                                    }
                                    className="header-link"
                                    exact
                                  >
                                    {k > 0 && (
                                      <span className="separator-slash"></span>
                                    )}
                                    {li.Icon ? (
                                      <li.Icon {...iconProps} />
                                    ) : (
                                      li.icon || <React.Fragment />
                                    )}
                                    <span className="active-line"></span>
                                    {isFunction(li.title)
                                      ? li.title(props)
                                      : li.title}
                                    {/* <Box
                                      component="span"
                                      sx={{ color: "grey.500", px: 1 }}
                                    >
                                      /
                                    </Box> */}
                                  </NavLink>
                                </Route>
                              ))
                            )}
                          </Box>
                        )}
                        <Box
                          sx={{
                            position: "relative",
                          }}
                        >
                          {link?.tabs && link.tabs.length > 0 ? (
                            <DashboardTabs link={link} baseUrl={link.url} />
                          ) : (
                            <link.component {...props} />
                          )}
                        </Box>
                      </React.Fragment>
                    )}
                  />
                )
            )}
            {baseUrl && (
              <Route>
                <Redirect to={baseUrl} />
              </Route>
            )}
          </Switch>
        </Box>
      </div>
    </div>
  );
};

export default DashboardLayout;
