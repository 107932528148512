import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import GradientButton from "./GradientButton";
import { Box } from "@material-ui/core";
import ActionButtons from "./forms/ActionButtons";

const btnSx = { mx: 2 };

const ButtonMain = ({
  boxProps,
  children,
  modal,
  mutation,
  ...buttonProps
}) => {
  // mdoal = {title, actions,Render, renderProps}
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <GradientButton
        variant="contained"
        onClick={handleClickOpen}
        boxProps={boxProps}
        type="button"
        {...buttonProps}
      >
        {children}
      </GradientButton>
      {modal && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby={modal.label ?? "button-modal"}
          fullWidth={true}
          scroll="body"
          maxWidth="md"
          {...modal.dialogProps}
          keepMounted
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              boxShadow: 2,
              borderBottom: "1px solid #eee",
            }}
          >
            {modal.title}
            <IconButton
              sx={{ ml: "auto", float: "right" }}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
          <Box sx={{ pt: 4 }}>
            {modal.contentText && (
              <DialogContentText>{modal.contentText}</DialogContentText>
            )}
            {modal.Render && (
              
                <modal.Render {...modal.renderProps} />
              
              )}
              </Box>
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: "1px solid #eee",
              py: 1,
              px: { xs: 1, md: 2 },
              boxShadow: 2,
            }}
          >
            <Button sx={btnSx} onClick={handleClose} variant="contained">
              Cancel
            </Button>
            {modal.actions && modal.actions?.length > 0 && (
              <ActionButtons closeModal={handleClose} actions={modal.actions} />
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ButtonMain;
