import React from "react";
import { Grid, Box,Button,IconButton,CircularProgress } from "@material-ui/core";
import CourseActivity from "./CourseActivity";
import "./CourseSectionMain.css";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import { Api } from "../../Api";
import {useMutation} from "@apollo/client"
function CourseSectionMain({ section, classes, refetch }) {
  
  const [loading, setLoading] = React.useState(false);
  const [sectionActive,setSectionActive]=React.useState(false);
  const [mutateAddSection] = useMutation(Api.updateCourseSection);
  // console.log(section.id)
  // console.log(activityError)
  // console.log(activityData)
  const refreshRef = React.useRef(null);
  const deleteItem = () => {
    setSectionActive(true);
    setLoading(true);
    mutateAddSection({
      variables: {
        courseId: section.course.id,
        sectionId:section.id,
        delete:true
      },
    })
      .then(({ data }) => {
        console.log(data);
        const token = data["action"]["courseSection"];
        if (token == null) {
          alert("An error has occured, Please try again!");
        } else {
          refetch();
          setLoading(false)
          setSectionActive(false)
        }
      })
      .catch((e) => {
        alert("An error has occured, Please try again!");
        console.log(e);
      });
  }
  return (
    <Grid item xs={12} className={`section-title ${classes.section_main} ${sectionActive ? 'section-title-active':''}`}>
      <Grid container alignItems="center" className={`section-title-controller ${classes.section_main_title}`}>
      <Box
        component="h3"
        className={classes.section_title}
        sx={{
          my: 0
        }}>
        <Box
          component="span"
          className={classes.section_title_num}
          sx={{
            mr: 1
          }}>
          {section.order}.
        </Box>
        {section.title}
      </Box>
      <Grid container className="section-title__controls" wrap="nowrap">
        <Box
          sx={{
            ml: 2
          }} />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          disabled={loading}
          className="acivity-item__button"
          onClick={() => {}}
        >
          <SettingsRoundedIcon fontSize="small" />
          </Button>
          <Box
            sx={{
              mx: 1
            }} />
          <Box ref={refreshRef}/>
        {/* </Box> */}
        <Box
          sx={{
            mx: 1
          }} />
        <IconButton
          color="secondary"
          aria-label="Delete Activity"
          size="small"
          disabled={loading}
          onClick={deleteItem}
          component="span"
        >

          <DeleteOutlineRoundedIcon />
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </IconButton>
      </Grid>
      </Grid>
      <Box
        sx={{
          pl: 3
        }}>
      <CourseActivity section={section} refreshRef={refreshRef}/>
    </Box>
    </Grid>
  );
}

export default CourseSectionMain;
