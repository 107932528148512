import React, { useState } from "react";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import CreateCourse from "../../components/admin/CreateCourse";
import { makeStyles } from "@material-ui/styles";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { green } from "@material-ui/core/colors";
import { useLazyQuery } from "@apollo/client";
import { Api } from "../../Api";
import ContainerLoader from "../../components/ContainerLoader";
import CourseTile from "../../components/course/CourseTile";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import CourseDetailAppBar from "./courses/CourseDetailAppBar";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../reducer";
// import CourseSections from "./courses/CourseSections";
import {
  Alert,
  AlertTitle,
  Modal,
  Backdrop,
  Button,
  Grid,
  Fade,
  useTheme,
} from "@material-ui/core";
import { spacing } from "../../components/utilities/utils";
import GradientButton from "../../components/GradientButton";

function Courses() {
  const theme = useTheme();
  const useStyles = makeStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      // paddingBottom: spacing(theme, 2, 4, 3),
      width: "80vw",
    },
    button: {
      "& > span": {
        filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))",
      },
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      border: 0,
      borderRadius: 5,
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      color: "white",
      height: 48,
      padding: "0 30px",
      width: "100%",
    },
    wrapper: {
      margin: spacing(theme, 1),
      marginTop: "10px",
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const { loading, error, data, refetch } = useQuery(Api.getCourses);
  const [loading, setLoading] = React.useState(true);
  const [getCourses, { called, loading: cLoading, data, error, refetch }] =
    useLazyQuery(Api.getCourses);
  const [{ courses }, dispatch] = useStateValue();
  const [courseTitle, setCourseTitle] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    if ((!courses || courses.length === 0) && called === false) {
      getCourses();
    } else if (courses.length > 0 && called === false) {
      setLoading(false);
    }
    if (data && called === true && cLoading === false) {
      if (data.courses) {
        dispatch({
          type: actionTypes.SET_COURSES,
          courses: data.courses,
        });
      }
      setLoading(false);
    }
  }, [data, cLoading]);

  const handleClose = () => {
    setOpen(false);
  };

  const renderRoute = (props) => {
    let course;
    courses.map((cr) => {
      if (cr.slug === props.match.params.slug) {
        course = cr;
      }
      return course;
    });
    if (!course) return <Redirect to="/admin/courses/" />;
    return (
      <CourseDetailAppBar
        setCourseTitle={setCourseTitle}
        course={course}
        {...props}
      />
    );
  };
  return (
    <div className="container">
      <ContainerLoader loading={loading} />
      <Grid container spacing={3}>
        <Switch>
          {loading === false ? (
            <Route path="/admin/courses/:slug/:section" render={renderRoute} />
          ) : (
            <React.Fragment />
          )}
          {loading === false ? (
            <Route path={"/admin/courses/:slug"} render={renderRoute} />
          ) : (
            <React.Fragment />
          )}
          <Route path="/admin/courses/" exact>
            <GradientButton
              endIcon={<AddCircleRoundedIcon />}
              onClick={handleOpen}
              fluid
              boxProps={{
                sx: { marginLeft: "auto", my: 3 },
              }}
            >
              Create Course
            </GradientButton>
          </Route>
        </Switch>
      </Grid>
      <Modal
        aria-labelledby="create-course"
        aria-describedby="create-course"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {loading === false && (
              <CreateCourse
                refetch={refetch}
                modalClose={handleClose}
                courseData={courses ? courses : []}
              />
            )}
            {/* <ContainerLoader loading={loading} /> */}
          </div>
        </Fade>
      </Modal>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {loading === false &&
          (courses && courses.length > 0 ? (
            <Route path="/admin/courses/" exact>
              {courses.map((course, i) => (
                <Grid item xs={12} md={4} lg={3} key={i}>
                  <CourseTile
                    course={course}
                    url={`/admin/courses/${course.slug}/`}
                  />
                </Grid>
              ))}
            </Route>
          ) : document.location.pathname === "/admin/courses/" ? (
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              {error ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
                </Alert>
              ) : (
                <Alert severity="info">
                  <AlertTitle>
                    <strong>No Courses Found</strong>
                  </AlertTitle>
                  Please create a course
                </Alert>
              )}
            </Grid>
          ) : (
            <Redirect to="/admin/courses/" />
          ))}
      </Grid>
    </div>
  );
}

export default Courses;
