import React from "react";
import { Route, Switch } from "react-router-dom";
import { Box } from "@material-ui/core";

function DashboardTabs({ link, baseUrl }) {
  const [tabs, setTabs] = React.useState([...link.tabs].reverse());
  React.useEffect(() => {
    setTabs([...link.tabs].reverse());
  }, [link]);
  return (
    <Box
      sx={{
        mt: 3,
        mx: 3
      }}>
      <Switch>
        {tabs.map(
          (tab, k) =>
            tab.component && (
              <Route path={tab.url} key={k}>
                <tab.component {...tab.componentProps} />
              </Route>
            )
        )}
      </Switch>
    </Box>
  );
}

export default DashboardTabs;
