import { Grid, Box, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { Api } from "../../../Api";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import AnalyticVal from "../../../components/admin/AnalyticVal";
import { makeStyles } from "@material-ui/styles";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/Button";
import QueryLoading from "../../../components/gql/QueryLoading";
import { IFrame } from "../../../components/utilities/IFrame";
import MutationLoading from "../../../components/gql/MutationLoading";
import FormField from "../../../components/forms/FormField";
import { displayDateFromStr } from "../../../components/utilities/utils";

const Emails = ({ object: email, updateQs }) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    editorWrapper: { border: "2px solid #eeeeee!important", borderRadius: 10 },
    editor: { padding: "0 10px" },
    toolbar: { border: "0 0 0 2px 0!important" },
    list: {
      width: "100%",
      maxWidth: "36ch",
      backgroundColor: theme.palette.background.paper,
    },
  });
  const classes = useStyles();
  const [curId, setCurId] = React.useState(0);
  const [draft, setDraft] = React.useState(email.userDraft);
  const [time, setTime] = React.useState(email.time);
  const [name, setName] = React.useState(email.name);
  const [nameError, setNameError] = React.useState("");
  const [finalHtml, setFinalHtml] = React.useState(null);

  const genEditorState = () => {
    const contentBlock = htmlToDraft(
      email?.userDraft?.content || email.content
    );
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    // const editorState =
    // return email.content || email?.userDraft?.content
    //   ? EditorState.createWithContent(
    //       ContentState.createFromBlockArray(
    //         convertFromHTML(email?.userDraft?.content || email.content)
    //       )
    //     )
    //   : EditorState.createEmpty()}
    const st = EditorState.createWithContent(contentState);
    setFinalHtml(draftToHtml(convertToRaw(st.getCurrentContent())));
    return st;
  };
  const getFinalHtml = (st) =>
    (st || editorState) &&
    draftToHtml(
      convertToRaw(
        st ? st.getCurrentContent() : editorState.getCurrentContent()
      )
    ).trim();
  const [editorState, setEditorState] = React.useState(null);
  const stats = [
    { value: email.linkClicks, title: "Total Link Clicks" },
    { value: email.registers, title: "Registers" },
    { value: email.sales, title: "Total Sales" },
    { value: email.sends, title: "Total Sends" },
  ];
  const [subject, setSubject] = React.useState("");
  const [subjectError, setSubjectError] = React.useState();
  const [linkData, setLinkData] = React.useState();
  const [draftBool, setDraftBool] = React.useState();
  const [showUnsubscribe, setShowUnsubscribe] = React.useState(
    email.showUnsubscribe
  );
  const [updated, setUpdated] = React.useState(false);
  const [updatedQuickSave, setUpdatedQuickSave] = React.useState(false);
  const [autoFetchCondition, setAutoFetchCondition] = React.useState(false);

  React.useEffect(() => {
    // console.log("email-cghange");
    if (curId !== email.id) {
      setCurId(email.id);
      setSubject(email?.userDraft?.subject || email.subject);
      setDraft(email?.userDraft);
      setEditorState(genEditorState());
      setSubjectError("");
      setTime(email.time);
      setName(email.name);
      // setFinalHtml(getFinalHtml());
      setDraftBool(email.draft);
    }
    setLinkData(email.linksData && JSON.parse(email.linksData));
    checkUpdated();
    checkUpdatedQuickSave();
    // console.log(email);
  }, [email]);

  React.useEffect(() => {
    checkUpdatedQuickSave();
    checkUpdated();
    checkAutoFetch();
  }, [finalHtml, subject]);
  const handleEditorStateChange = (st) => {
    setFinalHtml(getFinalHtml(st).trim());
    setEditorState(st);
  };
  const checkUpdatedQuickSave = () =>
    setUpdatedQuickSave(
      draftBool !== email.draft ||
        email.showUnsubscribe !== showUnsubscribe ||
        email.time !== time ||
        email.name !== name
    );
  const checkUpdated = () => {
    setUpdated(
      updatedQuickSave ||
        (email.subject && email.subject !== subject.trim()) ||
        (email.content && email.content !== finalHtml)
    );
  };

  const checkAutoFetch = (draftContent) => {
    const dContent = draftContent ?? draft?.content;
    setAutoFetchCondition(
      (!draft && updated) ||
        (draft && draft.subject !== subject) ||
        (finalHtml && dContent && dContent !== finalHtml)
        ? true
        : false
    );
  };
  const saveVars = {
    emailId: email.id,
    draft: draftBool,
    showUnsubscribe: showUnsubscribe,
    time: time || 0,
    name: name,
  };
  return (
    <Box>
      <Typography variant="h6">
        <strong>{email.name}</strong>
      </Typography>
      <Box
        sx={{
          mt: 3,
          mb: 4,
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          {stats.map((stat, i) => (
            <AnalyticVal
              key={i}
              title={stat.title}
              value={stat.value}
              count={i + 3}
            />
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
          <TextField
            value={name}
            error={nameError}
            updateError={setNameError}
            errorText="Name is required"
            updateValue={setName}
            id="email-name-input"
            label="Email Name"
            required
          />
        </Box>
        <Box
          sx={{
            mb: 3,
          }}
        >
          <TextField
            value={time}
            updateValue={setTime}
            id="email-time-input"
            label="Time from Trigger event (seconds)"
            integer={true}
          />
        </Box>
        <FormField
          setValue={setDraftBool}
          id="email-draft-set"
          type="checkbox"
          label="Draft Mode"
          value={draftBool}
          helperTrue="In Draft Mode this email will not be sent to users, Un-tick this box to publish email"
          helperFalse="In Draft Mode this email will not be sent to users, Un-tick this box to publish email"
          name="draft"
        />
        <FormField
          setValue={setShowUnsubscribe}
          id="email-show-unsubscribe-set"
          type="checkbox"
          label="Show Unsubscribe Link"
          value={showUnsubscribe}
          name="draft"
        />
      </Box>
      {linkData && (
        <Box
          sx={{
            mb: 4,
          }}
        >
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography variant="h6">
              <strong>Links Breakdown</strong>
            </Typography>
          </Box>
          {linkData.map((link, i) => (
            <Box key={i}>
              "{link.url}" - {link.clicks} Clicks
            </Box>
          ))}
        </Box>
      )}

      <Box>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography variant="h6">
                <strong>Edit Email</strong>
              </Typography>
            </Box>
            <Box
              sx={{
                mb: 3,
              }}
            >
              <TextField
                value={subject}
                error={subjectError}
                updateError={setSubjectError}
                helperText={
                  <Typography component="span">
                    Variables: <br />
                    {"{ name } - Signup name"}
                  </Typography>
                }
                errorText="Email Subject is required"
                updateValue={setSubject}
                id="email-subject-input"
                label="Email Subject"
                required
                multiline
              />
            </Box>
            <Box
              sx={{
                mb: 3,
              }}
            >
              <Typography variant="h5">
                <strong>Content</strong>
              </Typography>
              <Typography>
                <Box
                  component="span"
                  sx={{
                    color: "text.disabled",
                  }}
                >
                  Variables: <br />
                  {"{{ user.first_name }}"} - First Name
                  <br />
                  {"{{ user.last_name }}"} - Last Name (If found in signup name)
                  <br />
                  {"{{ user.name }}"} - Name (Signup name)
                  <br />
                  <br />
                  Links (Paste into link target): <br />
                  {"{{ product_1_url }} - Main Order Page(/order)"}
                  <br />
                  {"{{ webinar_url }} - Webinar View(/webinar)"}
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                mb: 3,
              }}
            >
              {updated && (
                <MutationLoading
                  query={Api.updateEmailDraft}
                  variables={{
                    draftId: draft?.id,
                    content: finalHtml,
                    subject: subject,
                    emailId: email.id,
                  }}
                  defaultData={{ draft: email.userDraft }}
                  updateValue={({ draft: draftD }) => {
                    // console.log("fetch-draft: ", draftD?.content);
                    setDraft(draftD);
                    updateQs(email.id, { userDraft: draftD });
                    checkAutoFetch(draftD?.content);
                  }}
                  autoFetchCondition={autoFetchCondition}
                  autofetch={10000}
                  dataKey="updateEmailDraft"
                  loadingBefore
                  render={DraftMessage}
                  loadingRender={() => <Box component="span">Saving</Box>}
                />
              )}
            </Box>
            {editorState && (
              <Editor
                spellCheck
                wrapperClassName={classes.editorWrapper}
                toolbarClassName={classes.toolbar}
                editorClassName={classes.editor}
                editorState={editorState}
                onEditorStateChange={handleEditorStateChange}
                // options={[
                //   "inline",
                //   "blockType",
                //   "fontSize",
                //   "fontFamily",
                //   "list",
                //   "textAlign",
                //   "colorPicker",
                //   "link",
                //   "embedded",
                //   "emoji",
                //   "image",
                //   "remove",
                //   "history",
                // ]}
                toolbar={{
                  colorPicker: {
                    colors: [
                      "rgb(0, 153, 255)",
                      "rgb(74,74,74)",
                      "rgb(97,189,109)",
                      "rgb(26,188,156)",
                      "rgb(84,172,210)",
                      "rgb(44,130,201)",
                      "rgb(147,101,184)",
                      "rgb(71,85,119)",
                      "rgb(204,204,204)",
                      "rgb(65,168,95)",
                      "rgb(0,168,133)",
                      "rgb(61,142,185)",
                      "rgb(41,105,176)",
                      "rgb(85,57,130)",
                      "rgb(40,50,78)",
                      "rgb(0,0,0)",
                      "rgb(247,218,100)",
                      "rgb(251,160,38)",
                      "rgb(235,107,86)",
                      "rgb(226,80,65)",
                      "rgb(163,143,132)",
                      "rgb(239,239,239)",
                      "rgb(255,255,255)",
                      "rgb(250,197,28)",
                      "rgb(243,121,52)",
                      "rgb(209,72,65)",
                      "rgb(184,49,47)",
                      "rgb(124,112,107)",
                      "rgb(209,213,216)",
                    ],
                  },
                }}
              />
            )}
            <Box
              sx={{
                mt: 3,
              }}
            >
              <Grid
                container
                spacing={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={6} md={4} lg={3}>
                  <MutationLoading
                    query={Api.updateEmail}
                    variables={saveVars}
                    disabledCondition={!updatedQuickSave}
                    updateValue={(dat) => {
                      updateQs(email.id, dat.email);
                      // setDraft(null);
                    }}
                    dataKey="updateEmail"
                    buttonProps={{
                      children: "Quick Save",
                      boxProps: { ml: "auto" },
                      // buttonProps: { autoFocus: true },
                      fullWidth: true,
                    }}
                    successMessage="Email Saved Successfully"
                  />
                </Grid>
                <Button
                  boxProps={{ ml: "auto" }}
                  // disabled={!updated}
                  color="primary"
                  modal={{
                    title: "Email Preview",
                    // contentText:"Preview "
                    Render: EmailPreview,
                    renderProps: {
                      vars: {
                        emailId: email.id,
                        content: finalHtml,
                        subject: subject,
                      },
                      draft: draft,
                      updated: updated,
                      updateQs: updateQs,
                    },
                    actions: [
                      {
                        mutation: {
                          query: Api.updateEmail,
                          variables: {
                            content: finalHtml,
                            subject: subject,
                            ...saveVars,
                          },
                          disabledCondition: !updated,
                          updateValue: ({ email: emailDat }) => {
                            updateQs(email.id, emailDat);
                            setDraft(null);
                          },
                          buttonProps: {
                            children: "Save Email",
                          },
                          successMessage: "Email Saved Successfully",
                        },
                      },
                    ],
                  }}
                >
                  Preview
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const EmailRender = ({ data, draft, vars }) => {
  const [webinarEmail, setWebinarEmail] = React.useState();
  const [webinarEmailError, setWebinarEmailError] = React.useState();
  return (
    <Box>
      <Box>
        <Typography>
          <strong>Subject:</strong> {data.subject}
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid #eeeeee",
          borderRadius: 10,
          my: 3,
          px: 2,
          py: 0,
        }}
      >
        <IFrame>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.content || "Error!",
            }}
          ></div>
        </IFrame>
      </Box>
      <Box
        sx={{
          mt: 3,
          pb: 1,
          borderBottom: "1px solid #eeeeee",
        }}
      >
        <Typography>
          <strong>Send Test Email:</strong>
        </Typography>
      </Box>

      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              my: 2,
            }}
          >
            <TextField
              value={webinarEmail}
              error={webinarEmailError}
              updateError={setWebinarEmailError}
              errorText="Webinar User email"
              updateValue={setWebinarEmail}
              id="webinar-email-input"
              label="Webinar User Email"
              required
              email
            />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginLeft: "auto" }}>
          <MutationLoading
            query={Api.sendTestEmail}
            variables={{
              draftId: draft?.id,
              emailId: vars.emailId,
              subject: vars.subject,
              content: vars.content,
              webinarEmail: webinarEmail,
            }}
            disabledCondition={webinarEmailError !== "" || webinarEmail === ""}
            dataKey="sendTestEmail"
            buttonProps={{
              fullWidth: true,
              children: "Send Test",
              color: "secondary",
              variant: "outlined",
              boxProps: { my: 2 },
            }}
            successMessage="Email Queued Successfully"
          />
        </Grid>
      </Grid>

      {/* <Box mt={2} pt={2} borderTop="1px solid #eeeeee">
        
      </Box> */}
    </Box>
  );
};

const EmailPreview = ({ vars, draft }) => {
  return (
    <Box>
      <QueryLoading
        query={Api.renderEmail}
        variables={vars}
        refKey="renderEmail"
        Render={EmailRender}
        renderProps={{ draft: draft, vars: vars }}
      />
    </Box>
  );
};
export default Emails;

const DraftMessage = (props) => (
  <Box component="span">
    {props.draft
      ? `Draft - Last Save ${displayDateFromStr(props?.draft?.updatedAt)}`
      : "No changes found"}
  </Box>
);
