import React from "react";
import Button from "../Button";
import { Box } from "@material-ui/core";
import Form from "../forms/Form";
import { getSubmitVariables } from "../utilities/utils";

function ModalForm({
  buttonProps,
  modalTitle,
  genFields,
  modalActionProps,
  boxProps,
  label,
  modalProps,
  id,
}) {
  const [fields, setFields] = React.useState(genFields);
  React.useEffect(
    () =>
      fields ? setFields({ ...fields, ...genFields }) : setFields(genFields),
    [genFields]
  );
  const [loading, setLoading] = React.useState(false);
  const getVariables = () => getSubmitVariables(fields);

  const [valid, setValid] = React.useState(false);
  const {
    dismissOnSubmit,
    buttonProps: btnp,
    ...actionProps
  } = modalActionProps ?? {};

  return (
    <Box {...boxProps}>
      <Button
        modal={{
          label: label,
          Render: Form,
          renderProps: {
            fields: fields,
            setFields: setFields,
            setValid: setValid,
            setLoading: setLoading,
            loading: loading,
            id: id,
          },
          title: modalTitle,
          actions: [
            {
              mutation: {
                setLoading: setLoading,
                variables: getVariables,
                disabledCondition: !valid,
                ...actionProps,
                updateValue: (dat) => {
                  setFields(genFields);
                  modalActionProps?.updateValue &&
                    modalActionProps.updateValue(dat);
                  // closeModal && dismissOnSubmit && closeModal();
                },
                buttonProps: {
                  // autoFocus: true,
                  type: "submit",
                  tooltip: !valid && {
                    title: "Please fill in all required fields!",
                  },
                  ...btnp,
                },
              },
            },
          ],
          ...modalProps,
        }}
        {...buttonProps}
      />
    </Box>
  );
}

export default ModalForm;
