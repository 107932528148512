import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Drawer, Tooltip, IconButton, Box, Grow } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";

const iconProps = {};

const ListLink = ({ link, mobile, toggleDrawer, condense, i }) => {
  const [isHover, setIsHover] = React.useState(false);
  const location = useLocation();
  const handleClick = link.disabled
    ? (e) => e.preventDefault()
    : (!link?.tabs || link.tabs.length === 0) && toggleDrawer && mobile
    ? toggleDrawer(false)
    : (e) => null;
  const handleSubClick = (tab) =>
    link.disabled || tab.disabled
      ? (e) => e.preventDefault()
      : toggleDrawer && mobile
      ? toggleDrawer(false)
      : (e) => null;

  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {link.sectionTitle && (
        <Box
          sx={{
            mr: "10px",
            ml: 2,
            pt: 3,
            pb: 1,
            fontWeight: 600,
            borderBottom: "2px solid #3f51b5",
            display: "flex",
            alignItems: "flex-end",
            whiteSpace: "nowrap",
          }}
        >
          {link.sectionTitle?.id}
          <Box
            component="span"
            className={mobile ? "" : "collapse-hide"}
            sx={{
              ml: 1,
              display: "inline-block",
            }}
          >
            - {link.sectionTitle?.title}
          </Box>
        </Box>
      )}
      {/* locked */}
      <NavLink
        to={link.url}
        {...link.linkProps}
        key={i}
        onClick={handleClick}
        className={`nav-link ${link.disabled ? "disabled" : ""} ${
          condense ? "condense" : ""
        }`}
      >
        {link.Icon ? <link.Icon {...iconProps} /> : link.icon}
        <span className="active-line collapse-hide"></span>
        <Box
          component="span"
          className={mobile ? "" : "collapse-hide"}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {link.title}
        </Box>
        <Box
          component="span"
          sx={{
            ml: "auto",
            pl: 1,
            display: "flex",
          }}
        >
          <Box
            component="span"
            sx={{
              color: "text.disabled",
              display: "flex",
              alignItems: "center",
              fontSize: 14,
              pr: 1,
            }}
          >
            {link.count}
          </Box>
          {link.locked && (
            <Tooltip
              leaveDelay={200}
              placement="top"
              title="Locked!"
              aria-label="locked"
              arrow
            >
              <Box
                component="span"
                sx={{
                  color: "text.disabled",
                  display: "flex",
                  alignItems: "center",
                  pl: "5px",
                }}
              >
                <LockRoundedIcon fontSize="small" />
              </Box>
            </Tooltip>
          )}
          {link.completed && (
            <Tooltip
              leaveDelay={200}
              placement="top"
              title="Completed!"
              aria-label="completed"
              arrow
            >
              <Box
                component="span"
                sx={{
                  color: "success.main",
                  display: "flex",
                  alignItems: "center",
                  pl: "5px",
                }}
              >
                <CheckCircleOutlineRoundedIcon
                  fontSize="small"
                  className="white-outline"
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </NavLink>
      {link?.tabs && link.tabs.length > 0 && (
        <Grow in={isHover || location.pathname.includes(link.url)}>
          <Box>
            {link.tabs
              .filter((tab) =>
                (tab.url !== link.url && location.pathname.includes(tab.url)) ||
                isHover ||
                mobile
                  ? true
                  : tab.url === link.url
                  ? tab.url === location.pathname
                  : location.pathname.includes(tab.url)
              )
              .map((tab, k) => (
                <NavLink
                  to={tab.url}
                  exact={tab.url === link.url}
                  key={k}
                  onClick={handleSubClick(tab)}
                  className={`nav-link sub-link-disp condense ${
                    tab.disabled ? "disabled" : ""
                  }`}
                >
                  <Box
                    component="span"
                    sx={{
                      pl: 2,
                    }}
                  >
                    {tab.title}
                  </Box>
                </NavLink>
              ))}
          </Box>
        </Grow>
      )}
    </Box>
  );
};

const RenderList = ({
  links,
  mobile,
  toggleDrawer,
  condense,
  appBarHeight,
}) => (
  <Box
    // pb={3}
    className="hide-scrollbar"
    sx={{
      height: `calc(100vh - ${appBarHeight + 58}px)`,
      pr: mobile ? 0 : "20px",
      mr: mobile ? 0 : "-20px",
      pb: 3,
      overflow: mobile ? "hidden scroll" : "visible scroll",
    }}
  >
    {links.map((link, i) => (
      <ListLink
        key={i}
        i={i}
        link={link}
        mobile={mobile}
        toggleDrawer={toggleDrawer}
        condense={condense}
      />
    ))}
  </Box>
);

function DashBoardSideNav({
  title,
  titleIcon,
  links,
  mobile,
  toggleDrawer,
  collapse,
  condense,
  appBarHeight,
  contentNavHeight,
}) {
  return (
    <Box
      className={`dashboard__nav${mobile ? " dashboard mobile" : ""}${
        collapse ? " collapse" : ""
      }`}
      // sx={{  }}
    >
      <Box
        className="nav-link separator"
        sx={{
          height: contentNavHeight,
          position: !mobile && "sticky",
          px: 1,
          top: mobile ? 0 : appBarHeight,
          bgcolor: "white",
          zIndex: 600,
        }}
      >
        {/* {titleIcon ? titleIcon : <React.Fragment />} */}
        {!mobile && (
          <IconButton
            onClick={toggleDrawer && toggleDrawer(true)}
            color="primary"
          >
            <ListRoundedIcon />
          </IconButton>
        )}
        <span className="active-line"></span>
        <span className={mobile ? "" : "collapse-hide"}>{title}</span>
      </Box>
      {links ? (
        <RenderList
          condense={condense}
          links={links}
          mobile={mobile}
          toggleDrawer={toggleDrawer}
          appBarHeight={appBarHeight}
        />
      ) : (
        <React.Fragment />
      )}
    </Box>
  );
}

const DashExp = ({
  title,
  titleIcon,
  links,
  toggleDrawer,
  collapse,
  mobileDrawerOpen,
  setMobileDrawerOpen,
  windowSize,
  condense,
  appBarHeight,
  contentNavHeight,
}) => {
  React.useEffect(() => {
    if (mobileDrawerOpen && windowSize.width > 768) {
      setMobileDrawerOpen(false);
    }
  }, [windowSize, mobileDrawerOpen, setMobileDrawerOpen]);
  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={mobileDrawerOpen}
        onClose={toggleDrawer && toggleDrawer(false)}
      >
        <DashBoardSideNav
          title={title}
          titleIcon={titleIcon}
          links={links}
          mobile={true}
          toggleDrawer={toggleDrawer}
          condense={condense}
          contentNavHeight={contentNavHeight}
        />
      </Drawer>
      <DashBoardSideNav
        title={title}
        collapse={collapse}
        titleIcon={titleIcon}
        links={links}
        toggleDrawer={toggleDrawer}
        condenss={condense}
        appBarHeight={appBarHeight}
        contentNavHeight={contentNavHeight}
      />
    </React.Fragment>
  );
};

export default DashExp;
