import GradientButton from "../GradientButton";
import MutationLoading from "../gql/MutationLoading";

const ActionButtons = ({
  actions,
  variables,
  closeModal,
  disabled,
  resetForm,
  setLoading,
  loading }) => (
  <>
    {actions.map((action, i) =>
      action.mutation ? (
        <MutationLoading
          key={i}
          resetForm={action.resetOnSubmit === false ? null : resetForm}
          disabled={disabled}
          setLoading={setLoading}
          variables={variables}
          {...action.mutation}
          closeModal={closeModal}
          loading={loading}
        />
      ) : (
        <GradientButton
          key={i}
          variant="contained"
          boxProps={action.boxProps}
          sx={{ mx: 2 }}
          {...action?.buttonProps}
        >
          {action.children}
        </GradientButton>
      )
    )}
  </>
);

export default ActionButtons;
