import React, { useState } from "react";
import { createPortal } from "react-dom";

export const IFrame = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  const [height, setHeight] = React.useState();
  const [width, setWidth] = React.useState();
  React.useEffect(() => {
    contentRef &&
      setHeight(contentRef.contentWindow.document.body.scrollHeight);
    contentRef && setWidth(contentRef.contentWindow.document.body.scrollWidth);
  }, [contentRef]);
  //  width={width}
  return (
    <iframe
      {...props}
      ref={setContentRef}
      height={height}
      width={width}
      // style={{ height: "100%", width: "100%" }}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
