import React from "react";
import {
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { red } from "@material-ui/core/colors";
import { Api } from "../../Api";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import { useMutation } from "@apollo/client";
import { secondsToStr } from "../utilities/utils";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: red[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnWrapper: {
    position: "relative",
  },
}));
function CourseActivityItem({
  activity,
  openActivitySide,
  typeIcons,
  refetch,
  activeEdit,
}) {
  const [prefix, setPrefix] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [updateActivityMutate] = useMutation(Api.updateCourseActivity);
  const classes = useStyles();
  const deleteItem = () => {
    setLoading(true);
    updateActivityMutate({
      variables: {
        sectionId: activity.section.id,
        activityId: activity.id,
        delete: true,
      },
    })
      .then((data) => {
        if (data?.data?.action?.courseActivity?.id) {
          if (refetch) refetch();
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (activity.activityType === "video") {
      setPrefix(
        activity.vimeoId
          ? secondsToStr(activity.videoTime ? activity.videoTime : 0)
          : "(Please add a video)"
      );
    } else if (activity.activityType === "pdf") {
      setPrefix(
        activity.dataFile
          ? `${activity.pageCount} Page${activity.pageCount === 1 ? "" : "s"}`
          : "(Please upload a PDF)"
      );
    } else if (activity.activityType === "exam") {
      const len = activity.examData?.length
        ? JSON.parse(activity.examData).length
        : 0;
      setPrefix(`${len} Question${len === 1 ? "" : "'s"}`);
    }
  }, [activity]);

  return (
    <Grid
      container
      alignItems="center"
      className={`activity-item__container ${classes.btnWrapper} ${
        loading || activeEdit === `${activity.id}-${activity.section.id}`
          ? "activity-item__container-active"
          : ""
      }`}
    >
      {typeIcons[activity.activityType]}
      <Box
        component="span"
        className="activity-item__order"
        sx={{
          ml: 1
        }}>
        {activity.section.order}.{activity.order}
      </Box>
      <Box
        component="span"
        sx={{
          ml: 1
        }}>
        {activity.title}
      </Box>
      <Box
        component="span"
        className="activity-item__prefix"
        sx={{
          mx: 2
        }}>
        {prefix}
      </Box>
      <Grid container className="activity-item__controls" wrap="nowrap">
        <Button
          color="primary"
          variant="outlined"
          size="small"
          disabled={loading}
          className="acivity-item__button"
          onClick={() => openActivitySide(activity)}
        >
          <SettingsRoundedIcon />
        </Button>

        {/* <IconButton
          color="primary"
          aria-label="Delete Activity"
          size="small"
          disabled={loading}
          onClick={() => openActivitySide(activity)}
          component="span"
        >
          <SettingsRoundedIcon />
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </IconButton> */}
        <Box
          sx={{
            mx: 1
          }} />
        <IconButton
          color="secondary"
          aria-label="Delete Activity"
          size="small"
          disabled={loading}
          onClick={deleteItem}
          component="span"
        >
          <DeleteOutlineRoundedIcon />
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default CourseActivityItem;
