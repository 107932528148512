import React from "react";
import {
  Grid,
  Drawer,
  IconButton,
  Box,
  Portal,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/core";
import { Api, typeIcons } from "../../Api";
import ContainerLoader from "../ContainerLoader";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import CreateActivity from "./CreateActivity";
import "./CourseActivity.css";
import UpdateActivity from "./UpdateActivity";
import CourseActivityItem from "./CourseActivityItem";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import { green } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  root: {},
  section_title: {
    "& h3": {
      fontSize: 19,
    },
    fontSize: 19,
    marginTop: 15,
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: "2px solid lightgray",
    width: "70%",
  },
  section_title_num: {
    marginRight: 10,
  },
  sideHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    "& svg:not(.inh-ignore)": {
      fontSize: 35,
      color: "white",
    },
    "& h3": {
      fontSize: "1.25rem",
      textTransform: "capitalize",
    },
    backgroundColor: "#494949",
    color: "white",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CourseActivity = ({ section, refreshRef }) => {
  const classes = useStyles();
  const [sideActivity, setSideActivity] = React.useState();
  const [activityOpen, setActivityOpen] = React.useState(false);
  const [activeEdit, setActiveEdit] = React.useState("");
  const [refetchLoading, setRefetchLoading] = React.useState(false);
  const [activityCreateType, setActivityCreateType] = React.useState("");
  const drawerNavController = React.useRef(null);
  const { loading, error, data, refetch } = useQuery(Api.getCourseActivities, {
    variables: {
      sectionId: section.id,
    },
  });
  const openActivitySide = (activity) => {
    if (activity) {
      setActiveEdit(`${activity.id}-${activity.section.id}`);
      setSideActivity(activity);
      setActivityOpen(true);
    }
  };
  React.useEffect(() => {
    if (activityOpen === true && sideActivity) {
      data.courseActivities.map((act) => {
        if (sideActivity.id === act.id) {
          setSideActivity({
            section: section,
            ...act,
          });
        }
        return sideActivity.id;
      });
    }
  }, [data]);
  if (loading) return <ContainerLoader loading={loading} />;
  return (
    <React.Fragment>
      <Portal container={refreshRef.current}>
        <IconButton
          color="primary"
          aria-label="Refresh Section"
          size="small"
          disabled={loading || refetchLoading}
          onClick={() => {
            setActivityCreateType("");
            setRefetchLoading(true);
            refetch()
              .then((data) => {
                setRefetchLoading(false);
              })
              .catch((e) => {
                console.log(e);
                setRefetchLoading(false);
              });
          }}
          component="span"
        >
          <RefreshRoundedIcon />
          {refetchLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </IconButton>
      </Portal>
      <Drawer
        anchor={"right"}
        open={activityOpen}
        onClose={() => {
          setActivityOpen(false);
          setActiveEdit("");
        }}
        // ref={drawerRef}
      >
        {sideActivity ? (
          <React.Fragment>
            <Paper elevation={3}>
              <Grid
                container
                className={classes.sideHeader}
                alignItems="center"
              >
                <Box
                  className={classes.flexBox}
                  ref={drawerNavController}
                ></Box>
                {typeIcons[sideActivity.activityType] ? (
                  <Box
                    className={classes.flexBox}
                    sx={{
                      ml: 2
                    }}>
                    {typeIcons[sideActivity.activityType]}
                  </Box>
                ) : (
                  <React.Fragment />
                )}
                <Box
                  component="h3"
                  sx={{
                    ml: 2
                  }}>
                  Update {sideActivity.activityType}
                </Box>
                <Box
                  component="span"
                  sx={{
                    ml: "auto"
                  }}>
                  <IconButton onClick={() => setActivityOpen(false)}>
                    <HighlightOffRoundedIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Grid>
            </Paper>
            <UpdateActivity
              activity={sideActivity}
              refetch={refetch}
              drawerNavController={drawerNavController}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </Drawer>

      <Box
        className={classes.root}
        sx={{
          mb: 5,
          mt: 1
        }}>
        {loading === false && !error && data.courseActivities ? (
          data.courseActivities.length > 0 ? (
            data.courseActivities.map((act, i) => (
              <CourseActivityItem
                key={i}
                refetch={refetch}
                typeIcons={typeIcons}
                activeEdit={activeEdit}
                openActivitySide={openActivitySide}
                activity={{
                  section: section,
                  ...act,
                }}
              />
            ))
          ) : (
            <Grid item xs={12}>
              {error ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
                </Alert>
              ) : (
                <React.Fragment />
              )}
            </Grid>
          )
        ) : (
          <React.Fragment />
        )}
        <Box
          sx={{
            mb: 1,
            mt: 2
          }} />
        <CreateActivity
          section={section}
          refetch={refetch}
          typeIcons={typeIcons}
          type={activityCreateType}
          setType={setActivityCreateType}
          fetchLoading={loading || refetchLoading}
        />
      </Box>
    </React.Fragment>
  );
};

export default CourseActivity;
