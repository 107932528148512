import React from "react";
import PropTypes from "prop-types";

export const isString = (val) =>
  typeof val === "string" || val instanceof String;

export const validateForm = (fields, updateField) => {
  let valid = true;
  const list = Object.keys(fields);
  for (var i in list) {
    const key = list[i];
    const { update, isValid } = isFormFieldValid(fields[key]);
    updateField && updateField(key, update);
    if (valid && !isValid) {
      valid = false;
      if (!updateField) return false;
    }
  }
  return valid;
};
export const isEmpty = (val) => !val || (isString(val) && val.trim() === "");

export const isFormFieldValid = ({
  type,
  value: val,
  required: reqPass,
  requiredConditional,
  errorText,
  label,
}) => {
  const required = !!requiredConditional || reqPass;
  let update = {};
  const integer = type === "number";
  if (required && isEmpty(val)) {
    update.error = errorText ?? `Please enter a ${label}`;
  } else if (type === "email" && !isEmailValid(val)) {
    update.error = "Please enter a valid Email";
  } else {
    update.error = "";
  }
  if (
    !integer ||
    (integer && val === "") ||
    (integer && val.trim() !== "" && !isNaN(val))
  ) {
    update.value = val;
  }
  return { isValid: !update?.error, update: update };
};

export const getSubmitVariables = (fields) => {
  let obj = {};
  Object.keys(fields).map((item, i) => {
    const value =
      fields[item].value &&
      typeof fields[item].value === "string" &&
      fields[item].value.trim() === ""
        ? null
        : fields[item].value;
    obj[item] = value || fields[item].default;
  });
  return obj;
};

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email) => emailRe.test(email.trim().toLowerCase());

export const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

export const useInterval = (callback, delay) => {
  const intervalRef = React.useRef();
  const callbackRef = React.useRef(callback);
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  React.useEffect(() => {
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(
        () => callbackRef.current(),
        delay
      );
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
};

export const secondsToTime = (secs) => {
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
};

export const secondsToStr = (secs) => {
  const obj = secondsToTime(secs || 0);
  const separator = ":";
  if (obj.h > 0) {
    return `${obj.h < 10 ? "0" : ""}${obj.h}${separator}${
      obj.m < 10 ? "0" : ""
    }${obj.m}${separator}${obj.s < 10 ? "0" : ""}${obj.s}`;
  } else if (obj.m > 0) {
    return `${obj.m < 10 ? "0" : ""}${obj.m}${separator}${
      obj.s < 10 ? "0" : ""
    }${obj.s}`;
  } else {
    return `00${separator}${obj.s < 10 ? "0" : ""}${obj.s}`;
  }
};

export const retLowest = (r, str) =>
  `${r !== Math.floor(r) ? r.toFixed(2) : r} ${str}${r === 1 ? "" : "s"}`;

export const getLowestTimeString = (secs) => {
  let r = secs / 3600 / 24 / 30;
  if (r >= 1) {
    return retLowest(r, "Month");
  }
  r = secs / 3600 / 24;
  if (r >= 1) {
    return retLowest(r, "Day");
  }
  r = secs / 3600;
  if (r >= 1) {
    return retLowest(r, "Hour");
  }
  r = secs / 60;
  if (r >= 1) {
    return retLowest(r, "Min");
  }
  r = secs;
  return retLowest(r, "Sec");
  //   const values = [
  //     { tim: 60, str: "Min" },
  //     { tim: 60, str: "Hr" },
  //     { tim: 24, str: "Day" },
  //     { tim: 30, str: "Month" },
  //   ];
  //   var i;
  //   // 1200
  //   // l=20
  //   //
  //   for (i = 0; i < values.length; i++) {
  //     if (r / values[i === values.length - 1 ? i : i + 1].tim < 1) {
  //       return `${r.toFixed(2)} ${values[i].str}${r === 1 ? "" : "s"}`;
  //     }
  //     r = r / values[i].tim;
  //   }
};
const spacingReturn = (theme, dict) => {
  if (dict?.d) {
    return theme.spacing(dict.a, dict.b, dict.c, dict.d);
  } else if (dict?.c) {
    return theme.spacing(dict.a, dict.b, dict.c);
  } else if (dict?.b) {
    return theme.spacing(dict.a, dict.b);
  }
  return theme.spacing(dict.a);
};
const spacingVal = 8;
export const spacing = (theme, a, b, c, d) =>
  theme?.spacing
    ? spacingReturn(theme, { a: a, b: b, c: c, d: d })
    : `${a ? `${a * spacingVal}px ` : ""}${b ? `${b * spacingVal}px ` : ""}${
        c ? `${c * spacingVal}px` : ""
      }${d ? `${d * spacingVal}px` : ""}`;
export const formatDate = (dt) => new Date(dt);
export const displayDateFromStr = (dt) => new Date(dt).toLocaleString();
export const getTimeDifference = (date1, date2) =>
  Math.abs(date2 - date1) / 1000;
