import { Box } from "@material-ui/core";
import React from "react";
import LoginForm from "../components/auth/LoginForm";
// import { actionTypes } from "../reducer";
// import { Api } from "../Api";
// import { useLazyQuery } from "@apollo/client";
// import PageLoader from "../components/PageLoader";
// import { useStateValue } from "../StateProvider";
const Auth = () => {
  return (
    <Box
      className="auth-container"
      sx={{
        bgcolor: "#ffba32",
        p: 2,
        width: "100%",
        mt: 3,
        mx: { md: 2, lg: 3 },
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          maxWidth: { xs: "100%", md: "60%", lg: "40%", xl: "30%" },
          mx: "auto",
          width: "100%",
          bgcolor: "white",
          borderRadius: "10px",
          minHeight: 450,
          boxShadow: "2px 5px 20px rgba(0,0,0,0.3)",
        }}
      >
        <LoginForm />
      </Box>
    </Box>
  );
};

export default Auth;
