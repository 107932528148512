import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { isEmailValid, isFormFieldValid } from "../utilities/utils";
// import ToggleIcon from "material-ui-toggle-icon";

// const useStyles = makeStyles((theme) => ({
// inputRoot: { width: "100%" },
// label: {
//   // textTransform: "uppercase",
//   fontWeight: 500,
//   fontSize: 16,
// },
// input: {
//   fontSize: 16,
// },
// margin: {
//   marginTop: 10,
// },
// }));

function CustomTextField({
  error,
  updateError,
  errorText,
  value,
  updateValue,
  loading,
  id,
  label,
  required,
  onChange,
  inpref,
  updateField,
  multiline,
  maxWidthRemove,
  integer: intPass,
  readOnly,
  helperText,
  helperTextComponent: HelperTextComponent,
  helperTextComponentProps,
  fieldProps,
  inputProps,
}) {
  const finalId = `form-text-${id}`;
  const { type: fieldType, InputProps, ...fieldPropsF } = fieldProps ?? {};
  // const classes = useStyles();
  const [hidePassword, setHidePassword] = React.useState(true);
  const handleOnChange = (event) => {
    const val = event.target.value;
    if (onChange) {
      onChange(event);
    } else {
      const { update } = isFormFieldValid({
        type: fieldType,
        value: val,
        required: required,
        errorText: errorText,
        label: label,
      });
      updateField(update);
    }
  };

  return (
    <TextField
      id={finalId}
      error={!!(error && error !== "")}
      fullWidth={maxWidthRemove ? false : true}
      readOnly={readOnly ? readOnly : false}
      disabled={loading ?? false}
      multiline={multiline ?? false}
      required={!!required}
      value={value}
      inputRef={inpref}
      inputProps={{ ...inputProps }}
      type={
        fieldType &&
        ((fieldType === "password" && hidePassword === true) ||
          (fieldType !== "password" && fieldType !== "text"))
          ? fieldType
          : "text"
      }
      InputProps={{
        endAdornment: fieldType === "password" && (
          <IconButton
            disabled={loading ?? false}
            edge="end"
            // size="small"
            onClick={(e) => setHidePassword(!hidePassword)}
          >
            {hidePassword ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <VisibilityOffIcon fontSize="small" />
            )}
            {/* <ToggleIcon
              on={hidePassword}
              onIcon={<VisibilityIcon />}
              offIcon={<VisibilityOffIcon />}
            /> */}
          </IconButton>
        ),
        ...InputProps,
      }}
      label={label}
      onChange={handleOnChange}
      helperText={
        error && error !== "" ? (
          error
        ) : HelperTextComponent ? (
          <HelperTextComponent {...helperTextComponentProps} />
        ) : (
          helperText
        )
      }
      {...fieldPropsF}
    />
  );
}

export default CustomTextField;
// const FinalInput = ({ password, variant, ...props }) => {
//   console.log(props.label);
//   // if (password === true) {
//   //   return <PasswordField variant={variant} {...props} />;
//   // }
//   switch (variant) {
//     case "filled":
//       return <FilledInput {...props} />;
//     case "outlined":
//       return <OutlinedInput {...props} />;
//     default:
//       return <Input {...props} />;
//   }
// };
/* <TextField
  id={id}
  className={maxWidthRemove ? "" : classes.inputRoot}
  readOnly={readOnly ? readOnly : false}
  disabled={loading ?? false}
  multiline={multiline ?? false}
  value={value ? value : ""}
  error={error && error !== ""}
  inputRef={inpref ? inpref : password ? passwordRef : null}
  type={password ? "password" : email ? "email" : "text"}
  inputProps={{ className: classes.input }}
  aria-describedby={id}
  label={label}
  onChange={handleOnChange}
  helperText={error ? error : helperText}
  {...inputProps}
/> 
  return (
    <FormControl
      error={error && error !== ""}
      fullWidth={maxWidthRemove ? false : true}
    >
      {variant !== "outlined" && (
        <InputLabel htmlFor={finalId}>{label}</InputLabel>
      )}
      <FinalInput
        // className={classes.input}
        fullWidth={maxWidthRemove ? false : true}
        label={variant === "outlined" ? label : null}
        variant={variant}
        id={finalId}
        readOnly={readOnly ? readOnly : false}
        disabled={loading ?? false}
        multiline={multiline ?? false}
        // value={value ? value : ""}
        password={password}
        inputRef={inpref}
        type={password && hidePassword ? "password" : email ? "email" : "text"}
        // ref={ref}
        aria-describedby={id}
        onChange={handleOnChange}
        {...inputProps}
      />
      <FormHelperText>{error ? error : helperText}</FormHelperText>
    </FormControl>
  );
  */
