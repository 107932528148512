import React from "react";
import "./App.css";
import Home from "./pages/Index";
import Auth from "./pages/Auth";
import FsAdmin from "./pages/Admin";
// import { AnimatePresence, motion } from "framer-motion";
import { Switch, Route, Redirect } from "react-router-dom";
import NotFound from "./pages/404";
import { useStateValue } from "./StateProvider";
import DashMain from "./pages/DashBoard";
import CourseView from "./pages/dashboard/CourseView.js";
import MainSide from "./components/navbar/MainSide";

const links = [
  {
    path: "/",
    component: Home,
    routeProps: { exact: true },
    loginRequired: false,
    superuser: false,
  },
  {
    path: "/admin/",
    component: FsAdmin,
    routeProps: {},
    loginRequired: true,
    superuser: true,
  },
  {
    path: "/dashboard/courses/:slug/",
    component: CourseView,
    routeProps: {},
    loginRequired: true,
    superuser: false,
  },
  {
    path: "/dashboard/",
    component: DashMain,
    routeProps: {},
    loginRequired: true,
    dashboardAccess: true,
    superuser: true,
  },
  // {
  //   path:"/dashboard/courses/:slug/:type/:sectionId/:activityId",
  //   component: CourseView,
  //   routeProps: {},
  //   loginRequired: true,
  //   superuser: false,
  // },
  // {
  //   path:"/dashboard/courses/:slug/:type/:sectionId/",
  //   component: CourseView,
  //   routeProps: {},
  //   loginRequired: true,
  //   superuser: false,
  // },
  // {
  //   path:"/dashboard/courses/:slug/:type/",
  //   component: CourseView,
  //   routeProps: {},
  //   loginRequired: true,
  //   superuser: false,
  // },
];

// const pageVariants = {
//   initial: {
//     opacity: 0,
//   },
//   in: {
//     opacity: 1,
//   },
//   out: {
//     opacity: 0,
//   },
// }
// const pageStyle = {
//   position: "absolute",
//   width:"100%"
// };
// const pageTransition = {
//   type: "tween",
//   ease: "anticipate",
//   duration: 0.5
// };

// const RenderComp = (props)=>
// <props.component {...props}  />
//   <motion.div
//   style={pageStyle}
//   initial="initial"
//   animate="in"
//   exit="out"
//   variants={pageVariants}
//   transition={pageTransition}
// >

// </motion.div>

// const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;
// <Detector
//         render={({ online }) => {
//           if (!showNetworkError && online === true) {
//             return <React.Fragment />;
//           } else {
//             setShowNetworkError(true);
//             return (
//               <Snackbar
//                 open={showNetworkError}
//                 autoHideDuration={online === false ? null : 3000}
//                 onClose={() => {
//                   if (online === true) setShowNetworkError(false);
//                 }}
//                 anchorOrigin={{
//                   vertical: "bottom",
//                   horizontal: "right",
//                 }}
//                 // TransitionComponent={SlideTransition}
//               >
//                 <Alert severity={online === false ? "error" : "success"}>
//                   {online === false
//                     ? "You don't seem to be connected to the Internet"
//                     : "Back Online"}
//                 </Alert>
//               </Snackbar>
//             );
//           }
//         }}
//       />
const App = () => {
  const [{ user }, dispatch] = useStateValue();
  const params = new URLSearchParams(document.location.search.substring(1));
  const next = params.get("next");

  const canAccess = (link) => {
    // link.superuser === true && user?.isSuperuser === true) || !link.superuser || link.superuser===false
    if (
      (link.superuser === true && !user?.isSuperuser) ||
      (link.dashboardAccess === true && !user?.dashboardAccess)
    ) {
      return false;
    }
    return true;
  };
  return (
    <div className="mca">
      {user && <MainSide keepMounted />}
      {/* <AnimatePresence>  */}
      {/* <BrowserRouter> */}
      {/* location={location} key={location.pathname} */}
      <Switch>
        <Route path="/login">
          {user ? (
            <Redirect
              to={next && next.includes("/login") ? "/" : next ? next : "/"}
            />
          ) : (
            <Auth />
          )}
        </Route>
        {links.map((link, i) => (
          <Route
            key={i}
            {...link.routeProps}
            path={link.path}
            render={(props) =>
              link.loginRequired === true ? (
                user === null ? (
                  <Redirect to={`/login?next=${document.location.pathname}`} />
                ) : canAccess(link) ? (
                  <link.component {...props} />
                ) : (
                  <Redirect to="/" />
                )
              ) : (
                <link.component {...props} />
              )
            }
          />
        ))}
        <Route component={NotFound} />
      </Switch>
      {/* </BrowserRouter> */}
      {/* </AnimatePresence> */}
    </div>
  );
};

export default App;
