import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Switch } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";

function SwitchE({
  value,
  switchProps,
  query,
  valueName,
  id,
  idName,
  updateQs,
}) {
  const [checked, setChecked] = React.useState(value ?? false);
  const [loading, setLoading] = React.useState(false);
  const [updateVal] = useMutation(query);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event) => {
    setLoading(true);
    const checkedVal = !checked;
    setChecked(checkedVal);
    updateVal({
      variables: { [idName ?? "id"]: id, [valueName]: checkedVal },
    })
      .then(({ data }) => {
        console.log();
        setLoading(false);
        enqueueSnackbar("Email Updated", {
          variant: "success",
        });
        updateQs(data.updateEmail.email);
      })
      .catch((e) => {
        enqueueSnackbar(e?.message || "An error occurred whilst updating!", {
          variant: "error",
        });
        setChecked(!checkedVal);
        setLoading(false);
      });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}>
      <Switch
        checked={checked}
        onClick={handleChange}
        onChange={handleChange}
        disabled={loading}
        disableRipple={loading}
        {...switchProps}
      />
      {loading && <CircularProgress size={16} />}
    </Box>
  );
}

export default SwitchE;
