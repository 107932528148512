import React from "react";
import {
  Grid,
  Button,
  FormControl,
  Input,
  InputLabel,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import "./CreateActivity.css";
import { makeStyles } from "@material-ui/styles";
import { Api, typeIcons, activityTypes } from "../../Api";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  inputRoot: { width: "100%" },
  label: {
    // textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 15,
  },
  input: {
    fontSize: 16,
  },
  zIndex: { zIndex: 1 },
  margin: {
    marginTop: 10,
  },
  buttonFinal: {
    // height: 35,
    width: "100%",
    "& > span": {
      filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))",
    },
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnWrapper: {
    flex: 1,
    maxWidth: 120,
    maxHeight: 35,
    position: "relative",
    marginLeft: 10,
    marginTop: 15,
  },
}));

function CreateActivity({ section, refetch, type, setType, fetchLoading }) {
  //   const [showAdd, setShowAdd] = React.useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [paid, setPaid] = React.useState(section.course.paid);
  const [draft, setDraft] = React.useState(false);
  const [comingSoon, setComingSoon] = React.useState(false);
  const [activityName, setActivityName] = React.useState("");
  const [activityNameError, setActivityNameError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [mutateAddActivity] = useMutation(Api.updateCourseActivity);
  // const [order, setOrder] = React.useState();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const addActivity = () => {
    setLoading(true);
    if (activityName.trim() === "") {
      setActivityNameError("Please enter an activity name");
      inputRef.current.focus();
      setLoading(false);
    } else {
      mutateAddActivity({
        variables: {
          sectionId: section.id,
          // activityId
          title: activityName,
          // order
          paid: paid,
          draft: draft,
          comingSoon: comingSoon,
          delete: false,
          activityType: type,
          // dataFile:data_file,
          // vimeoId:vimeo_id,
          // examData:exam_data,
          // description:description
        },
      })
        .then((data) => {
          console.log(data);
          setLoading(false);
          setActivityName("");
          setActivityNameError("");
          refetch();
        })
        .catch((e) => {
          console.log(e);

          alert("An error has occured, Please try again!");
          setLoading(false);
        });
    }
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="activity__add"
          color="secondary"
          variant="outlined"
          disabled={fetchLoading || loading}
        >
          <AddCircleOutlineRoundedIcon />
          <Box
            component="span"
            sx={{
              ml: 1
            }}>
            Add Activity
          </Box>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          // className={classes.zIndex,'activity__add_dropdown'}
          className="activity__add_dropdown"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
              // className="activity__add_dropdown"
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className="activity_list"
                  >
                    {activityTypes.map((item) => (
                      <MenuItem
                        className="activity_list__item"
                        onClick={(event) => {
                          setType(item.key);
                          handleClose(event);
                        }}
                      >
                        {item.setIconProps(item, {
                          className: "activity_list__icon",
                        })}
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {!typeIcons[type] ? (
        <React.Fragment />
      ) : (
        <div className="activity_add__main">
          <Box
            className="activity_add__icon"
            sx={{
              mr: 2
            }}>
            {typeIcons[type]}
          </Box>
          <Box>
            <Grid container alignItems="center" direction="column">
              <FormControl
                className={classes.inputRoot}
                error={activityNameError !== ""}
              >
                <InputLabel
                  htmlFor={`create-activity-name-${section.id}`}
                  className={classes.label}
                >
                  Activity Name
                </InputLabel>
                <Input
                  className={classes.input}
                  id={`create-activity-name-${section.id}`}
                  disabled={loading}
                  value={activityName}
                  inputRef={inputRef}
                  onChange={(event) => {
                    const val = event.target.value;
                    val.trim() === ""
                      ? setActivityNameError("Please enter an activity name")
                      : setActivityNameError("");
                    setActivityName(val);
                  }}
                  aria-describedby="create-section-name"
                />
                <FormHelperText>{activityNameError}</FormHelperText>
              </FormControl>
              <Grid container>
                <FormControlLabel
                  className={(classes.margin, classes.label)}
                  control={
                    <Checkbox
                      checked={paid}
                      name="paid"
                      size="small"
                      onChange={(event) => setPaid(event.target.checked)}
                    />
                  }
                  label="Paid"
                />
                <FormControlLabel
                  className={(classes.margin, classes.label)}
                  control={
                    <Checkbox
                      checked={draft}
                      name="draft"
                      size="small"
                      onChange={(event) => setDraft(event.target.checked)}
                    />
                  }
                  label="Draft"
                />
                <FormControlLabel
                  className={(classes.margin, classes.label)}
                  control={
                    <Checkbox
                      checked={comingSoon}
                      name="comingSoon"
                      size="small"
                      onChange={(event) => setComingSoon(event.target.checked)}
                    />
                  }
                  label="Coming Soon"
                />
              </Grid>
            </Grid>
          </Box>
          <div className={classes.btnWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={addActivity}
              disabled={loading || fetchLoading}
              className={classes.buttonFinal}
              type="submit"
            >
              Create
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateActivity;
