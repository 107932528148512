import React from "react";
import { TypeIcon } from "../../Api";
// import ListRoundedIcon from "@material-ui/icons/ListRounded";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Grid,
  Tooltip,
  Button,
  Typography,
  Alert,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import Vimeo from "@u-wave/react-vimeo";
import "../dashboard/DashBoardLayout.css";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
// import { useMutation } from "@apollo/client";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
// import ContainerLoader from "../ContainerLoader";
import ViewActivityContent from "./ViewActivityContent";
// import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
// import { green } from "@material-ui/core/colors";
import useWindowDimensions from "../utilities/useWindowDimensions";
import DashBoardSideNav from "../dashboard/DashBoardSideNav";

function View({
  courseUser,
  setLoading,
  classes,
  match,
  trackLoading,
  trackData,
  updateQuery,
}) {
  const history = useHistory();
  const [activeTrack, setActiveTrack] = React.useState();
  const [prevTrack, setPrevTrack] = React.useState();
  const [nextTrack, setNextTrack] = React.useState();
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState(false);
  const windowSize = useWindowDimensions();

  // React.useEffect(() => {
  //   if (courseUser && !trackData) {
  //     getTracks(});
  //     setLoading(true);
  //   }
  // }, [courseUser]);
  React.useEffect(() => {
    if (trackData && trackLoading === false) {
      if (trackData.usersCourseTrack) {
        const act = match.params.activityId;
        const sect = match.params.sectionId;
        if (act) {
          const actObj = trackData.usersCourseTrack.findIndex(
            (e) =>
              e.activity.order === parseInt(act) &&
              e.section.order === parseInt(sect)
          );
          if (actObj >= 0 && trackData.usersCourseTrack[actObj].unlocked) {
            setActiveTrack(trackData.usersCourseTrack[actObj]);
            setPrevTrack(
              actObj > 0 ? trackData.usersCourseTrack[actObj - 1] : null
            );
            setNextTrack(trackData.usersCourseTrack[actObj + 1]);
          } else {
            history.push(
              `/dashboard/courses/${courseUser.course.slug}/view/1/1`
            );
          }
        }
        setLoading(false);
      } else {
        alert("An error has occurred. Please refresh the page and try again!");
      }
    }
  }, [
    trackData,
    trackLoading,
    match.params.activityId,
    match.params.sectionId,
  ]);
  const toggleDrawer = (open) => (event) => {
    if (windowSize.width <= 768) {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setMobileDrawerOpen(open);
    } else {
      setMobileDrawerOpen(false);
      setCollapse(!collapse);
    }
  };
  return (
    <Grid container className="dashboard" style={{ height: "100%" }}>
      <DashBoardSideNav
        title={"Access"}
        collapse={collapse}
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
        toggleDrawer={toggleDrawer}
        windowSize={windowSize}
        // titleIcon={titleIcon}
        links={
          trackData?.usersCourseTrack &&
          trackData.usersCourseTrack.map((track, i, arr) => ({
            url: `/dashboard/courses/${courseUser.course.slug}/view/${track.section.order}/${track.activity.order}`,
            title: (
              <React.Fragment>
                <Typography
                  className={`${classes.order} white-outline-hover`}
                  color="secondary"
                  variant="subtitle1"
                >
                  <Box component="span">
                    {track.section.order}.{track.activity.order}
                  </Box>
                </Typography>
                <Box
                  sx={{
                    ml: 1,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {track.activity.title}
                </Typography>
              </React.Fragment>
            ),
            icon: (
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TypeIcon
                    // fontSize={"large"}
                    color="secondary"
                    type={track.activity.activityType}
                    className="white-outline"
                  />
                </Box>
              </React.Fragment>
            ),
            routeProps: { exact: true },
            // component: Home,
            linkProps: { exact: true },
            locked: track.unlocked === null,
            completed: track.completed !== null && track.completed !== "",
            disabled: track.unlocked === null,
            sectionTitle: arr[i - 1]?.section?.order !==
              track.section.order && {
              id: track.section.order,
              title: track.section.title,
            },
          }))
        }
      />
      <Box
        sx={{
          flex: "1",
        }}
      >
        {activeTrack ? (
          <React.Fragment>
            <Paper
              square
              elevation={
                activeTrack.activity.activityType === "pdf" ||
                activeTrack.activity.activityType === "exam"
                  ? 1
                  : 2
              }
              style={{ zIndex: 100, position: "relative" }}
            >
              <Box
                sx={{
                  p: "14px",
                  height: "64px",
                }}
              >
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={3} className={classes.gridStart}>
                    {prevTrack && (
                      <Tooltip
                        arrow
                        leaveDelay={200}
                        placement="bottom"
                        title={
                          prevTrack.activity.comingSoon
                            ? "Coming Soon!"
                            : prevTrack.unlocked
                            ? `${prevTrack.section.order}.${prevTrack.activity.order} - ${prevTrack.activity.title}`
                            : "Locked!"
                        }
                        aria-label="locked"
                      >
                        <Link
                          to={`/dashboard/courses/${courseUser.course.slug}/view/${prevTrack.section.order}/${prevTrack.activity.order}`}
                          onClick={(e) =>
                            prevTrack.activity.comingSoon === true ||
                            !prevTrack.unlocked
                              ? e.preventDefault()
                              : false
                          }
                          style={{ maxWidth: "100%" }}
                        >
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={
                              prevTrack.activity.comingSoon === true ||
                              !prevTrack.unlocked
                            }
                            style={{ maxWidth: "100%" }}
                          >
                            <Box
                              component={ChevronLeftRoundedIcon}
                              sx={{
                                ml: -1,
                              }}
                            />
                            <TypeIcon type={prevTrack.activity.activityType} />
                            <Box
                              component="span"
                              sx={{
                                mx: 1,
                              }}
                            >
                              {prevTrack.section.order}.
                              {prevTrack.activity.order}
                            </Box>
                            <Box
                              component="span"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {prevTrack.activity.title}
                            </Box>
                            {!prevTrack.unlocked && (
                              <Box
                                component={LockRoundedIcon}
                                sx={{
                                  ml: 1,
                                }}
                              />
                            )}
                          </Button>
                        </Link>
                      </Tooltip>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Typography color="primary">
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 600,
                          mr: 1,
                        }}
                      >
                        {activeTrack.section.order}.{activeTrack.activity.order}
                      </Box>
                    </Typography>
                    <Typography color="secondary">
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {activeTrack.activity.title}
                      </Box>
                    </Typography>
                  </Box>
                  <Grid item xs={3} className={classes.gridEnd}>
                    {nextTrack && (
                      <Tooltip
                        leaveDelay={200}
                        placement="bottom"
                        title={
                          nextTrack.activity.comingSoon
                            ? "Coming Soon!"
                            : nextTrack.unlocked
                            ? `${nextTrack.section.order}.${nextTrack.activity.order} - ${nextTrack.activity.title}`
                            : "Locked!"
                        }
                        aria-label="locked"
                      >
                        <Link
                          to={`/dashboard/courses/${courseUser.course.slug}/view/${nextTrack.section.order}/${nextTrack.activity.order}`}
                          onClick={(e) =>
                            nextTrack.activity.comingSoon === true ||
                            !nextTrack.unlocked
                              ? e.preventDefault()
                              : false
                          }
                          style={{ maxWidth: "100%" }}
                        >
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={
                              nextTrack.activity.comingSoon === true ||
                              !nextTrack.unlocked
                            }
                            style={{ maxWidth: "100%" }}
                          >
                            {!nextTrack.unlocked && (
                              <Box
                                component={LockRoundedIcon}
                                sx={{
                                  mr: 1,
                                }}
                              />
                            )}
                            <TypeIcon type={nextTrack.activity.activityType} />
                            <Box
                              component="span"
                              sx={{
                                mx: 1,
                              }}
                            >
                              {nextTrack.section.order}.
                              {nextTrack.activity.order}
                            </Box>
                            <Box
                              component="span"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {nextTrack.activity.title}
                            </Box>
                            <Box
                              component={ChevronRightRoundedIcon}
                              sx={{
                                mr: -1,
                              }}
                            />
                          </Button>
                        </Link>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <ViewActivityContent
                track={activeTrack}
                updateQuery={updateQuery}
              />
            </Box>
          </React.Fragment>
        ) : (
          <Box
            sx={{
              px: 2,
              py: 3,
            }}
          >
            <Alert severity="info">
              {/* <AlertTitle>Error</AlertTitle> */}
              Please select an activity!
            </Alert>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default View;
