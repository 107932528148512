import React from "react";
// import { Redirect, Link } from "react-router-dom";

function CourseDetail({ course }) {
  return (
    <div>
      <h1>Course Detail {course.name}</h1>
    </div>
  );
}

export default CourseDetail;
