import React from "react";
import TextField from "./TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import Button from "../Button";

function FormField({
  updateField,
  id,
  type,
  label,
  error,
  value,
  readOnly,
  multiline,
  required: requiredPass,
  loading,
  int,
  options,
  disabled,
  helperTrue,
  helperFalse,
  helperText,
  helperTextComponent,
  helperTextComponentProps,
  name,
  setValue,
  requiredDependent,
  requiredOr,
  requiredConditional,
  ...fieldProps
}) {
  const required = !!requiredConditional ?? requiredPass;
  const updateParentField = (data) => {
    setValue ? setValue(data) : updateField(name, data);
  };
  const updateValue = (data) => {
    setValue ? setValue(data) : updateField(name, { value: data });
  };
  // const updateError = (data) => {
  //   updateField(name, { error: data });
  // };

  if (type === "select") {
    return (
      <FormControl
        required={!!required}
        fullWidth
        disabled={loading ? loading : disabled}
      >
        <InputLabel id={`select-label-${id}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${id}`}
          id={`select-${id}`}
          label={label}
          value={value}
          onChange={(event) => updateValue(event.target.value)}
        >
          {options.map((o, i) => (
            <MenuItem value={o.value} key={i}>
              {o.text ? o.text : o.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (type === "checkbox") {
    return (
      <FormControl
        required={!!required}
        error={!!error}
        component="fieldset"
        fullWidth
        disabled={readOnly}
      >
        {/* <FormLabel component="legend">Pick two</FormLabel> */}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => updateValue(e.target.checked)}
                name={name}
              />
            }
            label={label}
          />
        </FormGroup>
        <FormHelperText>
          {value === false && helperFalse ? helperFalse : ""}
          {value === true && helperTrue ? helperTrue : ""}
        </FormHelperText>
      </FormControl>
    );
  } else if (type === "file") {
    return (
      <>
        {label && (
          <Typography
            component="label"
            sx={{ pb: 1, fontWeight: 700, display: "block" }}
            htmlFor={id}
          >
            {label}
          </Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!value && fieldProps?.displayPreview?.url && (
            <Box
              sx={{ maxHeight: 75, mr: 2 }}
              component="img"
              src={fieldProps.displayPreview.url}
            />
          )}
          <label htmlFor={id}>
            <input
              id={id}
              type="file"
              accept={fieldProps.accept}
              name={name}
              required={!!required}
              style={{ display: "none" }}
              onChange={(event) => {
                const file = event.target.files[0];
                // console.log(file);
                if (file) {
                  updateValue(file);
                }
              }}
            />
            <Button
              boxProps={{ component: "span" }}
              className="btn-choose"
              component="span"
              disabled={loading}
            >
              {`${value ? "Change" : "Choose"} Files`}
            </Button>
          </label>
          <Typography
            sx={{
              maxWidth: "100%",
              border: 1,
              borderLeftWidth: 0,
              borderColor: "grey.500",
              textOverflow: "ellipsis",
              overflow: "hidden",
              borderRadius: 1,
              whiteSpace: "no-wrap",
              px: 2,
              py: 1,
            }}
          >
            {value ? value.name : fieldProps?.displayPreview?.name}
          </Typography>
        </Box>
      </>
    );
    return (
      <DropzoneAreaBase
        // acceptedFiles={}
        maxFileSize={5000000}
        filesLimit={1}
        dropzoneClass={`dropzone ${value ? "dropzone__hasImage" : ""}`}
        fileObjects={value ? [value] : []}
        showFileNames={true}
        onAdd={(files) => {
          const file = files[0];
          if (file) {
            updateValue(file);
          }
        }}
        onDelete={() => {
          updateValue(null);
        }}
        {...fieldProps}
      />
    );
  }
  return (
    <TextField
      error={error}
      // updateError={updateError}
      value={value}
      // updateValue={updateValue}
      updateField={updateParentField}
      loading={readOnly ? readOnly : loading}
      label={label}
      readOnly={readOnly}
      required={required}
      multiline={multiline}
      integer={int}
      id={id}
      type={type}
      helperText={helperText}
      helperTextComponent={helperTextComponent}
      helperTextComponentProps={helperTextComponentProps}
      {...fieldProps}
    />
  );
}

export default FormField;
