import React from "react";
import "./ContainerLoader.css";
import { Box, CircularProgress } from "@material-ui/core";

function ContainerLoader({ loading, relative }) {
  return (
    <Box
      className={`${!relative ? "container-loader" : ""} ${
        loading ? "" : "out"
      }`}
      sx={{
        display: "flex",
        alignItems: "center"
      }}>
      {loading === true ? (
        <CircularProgress
          style={relative && { height: "100%", width: "100%" }}
        />
      ) : (
        <React.Fragment />
      )}
    </Box>
  );
}

export default ContainerLoader;
