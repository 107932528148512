import { Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import { Api } from "../../Api";
import QueryLoading from "../gql/QueryLoading";
import { secondsToStr } from "../utilities/utils";

const getVal = (data) => {
  if (data?.dataType) {
    switch (data.dataType) {
      // case "int":
      //     return data.data.trim() !== "" && parseInt()
      case "price":
        return `£${data.data}`;
      case "seconds":
        return !isNaN(data.data.trim()) !== ""
          ? secondsToStr(parseInt(data.data))
          : data.data;
      default:
        return data.data;
    }
  } else {
    return data.data;
  }
};

const AnalyticVal = ({
  title,
  count,
  query,
  qs,
  startDate,
  endDate,
  value,
  queryKey,
}) => {
  const TextRender = ({ data: analytic }) => (
    <Typography variant={count > 1 ? "h4" : "h3"} align="center">
      {analytic?.data ? getVal(analytic) : analytic}
    </Typography>
  );

  return (
    <Grid item xs={count > 1 ? 3 : 6}>
      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          mb: 3
        }}>
        <Box
          sx={{
            position: "relative",
            minHeight: "50px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            mb: 1
          }}>
          {query ? (
            <QueryLoading
              query={query}
              Render={TextRender}
              pollInterval={600000}
              smallAlert
              variables={{
                returnData: qs,
                startDate: startDate,
                endDate: endDate,
              }}
              refKey={queryKey}
            />
          ) : (
            <TextRender data={value} />
          )}
        </Box>
        <Typography variant={count > 1 ? "body1" : "h6"} align="center">
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

export default AnalyticVal;
