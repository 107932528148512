import React from "react";
import {
  Grid,
  TextField,
  Checkbox,
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
} from "@material-ui/core";
import { createFilterOptions } from "@material-ui/core/Autocomplete";
import { Api } from "../../../Api";
import { useLazyQuery, useMutation } from "@apollo/client";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useStateValue } from "../../../StateProvider";
import { actionTypes } from "../../../reducer";
import { makeStyles } from "@material-ui/styles";
import GradientButton from "../../GradientButton";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  flex1: {
    flex: 1,
  },
  grey: {
    color: "grey",
  },
}));

const filterOptions = createFilterOptions({
  // matchFrom: 'start',
  matchFrom: "any",
  ignoreCase: true,
  limit: 25,
  // stringify: option => option.email,
  stringify: (option) =>
    `${option.email} ${option.id} ${option.firstName} ${option.lastName}`,
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EnrollUsers({
  course,
  updateCourseUserData,
  enrollLoading,
  setEnrollLoading,
}) {
  const classes = useStyles();
  const [{ users, user }, dispatch] = useStateValue();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [getUsers, { called, loading, data }] = useLazyQuery(Api.getUsers, {
    variables: { excludeUser: false },
  });
  const [enrollUserError, setEnrollUserError] = React.useState("");
  const [enrollUsersMutation] = useMutation(Api.courseEnrollUsers);
  React.useEffect(() => {
    if (users.length === 0 && called === false) {
      getUsers();
    }
    if (data && called === true && loading === false) {
      if (data.allUsers) {
        dispatch({
          type: actionTypes.SET_ALL_USERS,
          users: data.allUsers,
        });
      }
    }
  }, [data, loading]);
  React.useEffect(() => {
    if (users.length > 0) {
      setOptions(users);
    }
  }, [users]);
  const { enqueueSnackbar } = useSnackbar();
  const enrollUsers = () => {
    setEnrollLoading(true);
    if (selectedUsers.length === 0) {
      setEnrollUserError("Please select at least 1 User!");
      setEnrollLoading(false);
    } else {
      // setOpen()
      enrollUsersMutation({
        variables: {
          courseId: course.id,
          userIds: selectedUsers.map((a) => a.id),
        },
      })
        .then(({ data: userDat }) => {
          const usersRet = userDat.enrollUsers.courseUsers;
          if (usersRet) {
            enqueueSnackbar(
              `${usersRet.length}/${selectedUsers.length} User${
                usersRet.length !== 0 ? "s" : ""
              } Enrolled Successfully`,
              { variant: "success" }
            );
          }
          updateCourseUserData((old) => ({
            courseUsers: [...old.courseUsers, ...usersRet],
          }));
          let users_update = [...users];
          for (const a of usersRet) {
            for (var i = 0; i < users_update.length; i++) {
              if (users_update[i].id === a.user.id) {
                users_update[i] = {
                  ...users_update[i],
                  coursesEnrolledIn: a.user.coursesEnrolledIn,
                };
                break;
              }
            }
          }
          dispatch({
            type: actionTypes.SET_ALL_USERS,
            users: users_update,
          });
          setSelectedUsers([]);
          setEnrollLoading(false);
        })
        .catch((e) => {
          Api.errorAlert(e);
          setEnrollLoading(false);
        });
    }
  };
  return (
    <Grid item xs={12} md={8} lg={6}>
      <Grid container alignItems="center">
        <Autocomplete
          id="user-enroll-search"
          multiple
          style={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          disableCloseOnSelect={true}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionDisabled={(option) =>
            option.coursesEnrolledIn
              ? option.coursesEnrolledIn.includes(course.id)
              : false
          }
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          includeInputInList={true}
          groupBy={(option) => option.email[0].toUpperCase()}
          options={options}
          filterOptions={filterOptions}
          loading={loading}
          clearOnBlur={false}
          autoComplete={true}
          className={classes.flex1}
          value={selectedUsers}
          onChange={(event, value) => {
            if (value.length === 0) {
              setEnrollUserError("Please select at least 1 User!");
            } else {
              setEnrollUserError("");
            }
            setSelectedUsers(value);
          }}
          renderOption={(props, option, { selected }) => {
            const enrolled = option.coursesEnrolledIn
              ? option.coursesEnrolledIn.includes(course.id)
              : false;
            return (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected || enrolled}
                />
                <Grid container direction="column">
                  <Box>
                    <Typography component="h6">
                      {option.firstName} {option.lastName}{" "}
                      {enrolled && "(Enrolled)"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography component="small" className={classes.grey}>
                      {user.id === option.id && "(You) - "} {option.email} -{" "}
                      {option.id}{" "}
                    </Typography>
                  </Box>
                </Grid>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="User"
              error={enrollUserError === "" ? false : true}
              helperText={
                enrollUserError === ""
                  ? "Search by ID / Email / Name"
                  : enrollUserError
              }
              // variant="outlined"
              disabled={loading || enrollLoading}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <GradientButton
          onClick={enrollUsers}
          boxProps={{ ml: 2 }}
          disabled={loading || enrollLoading}
          loading={enrollLoading}
        >
          Enroll
        </GradientButton>
      </Grid>
    </Grid>
  );
}
export default EnrollUsers;
