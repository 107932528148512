import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import { Api } from "../../Api";
import { config } from "../../Constants";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";

const Logout = ({
  component: Render,
  next,
  setLoading: setLoadingP,
  loading: loadingP,
  renderProps,
  ...props
}) => {
  const [values, dispatch] = useStateValue();
  const [loadingF, setLoadingF] = React.useState(false);
  const loading = loadingP ?? loadingF;
  const setLoading = setLoadingP ?? setLoadingF;
  const onClick = (e) => {
    setLoading(true);
    dispatch({
      type: actionTypes.SET_AUTH_DATA,
      authData: {
        token: null,
      },
    });
  };
  //   };
  return (
    <Render loading={loading} onClick={onClick} {...renderProps} {...props} />
  );
};

export default Logout;
