import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { Api } from "../../../Api";
import QueryLoading from "../../../components/gql/QueryLoading";
import {
  getLowestTimeString,
  displayDateFromStr,
} from "../../../components/utilities/utils";

const WebinarDisplay = ({ data: webinars }) => (
  <Box>
    {webinars.map((webinar, i) => (
      <Box
        key={i}
        sx={{
          my: 3
        }}>
        <Box
          sx={{
            mb: 1,
            pb: 1,
            borderBottom: "1px solid #eeeeee"
          }}>
          <Grid container>
            <Grid item xs={4}>
              <strong>Name</strong>
            </Grid>
            <Grid item xs={4}>
              <strong>Last Update</strong>
            </Grid>
            <Grid item xs={4}>
              <strong>Watched Time</strong>
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={4}>
            {webinar.name}
          </Grid>
          <Grid item xs={4}>
            {displayDateFromStr(webinar.lastUpdate)}
          </Grid>
          <Grid item xs={4}>
            {getLowestTimeString(webinar.watchedTime)}
          </Grid>
        </Grid>
      </Box>
    ))}
  </Box>
);

function Status() {
  return (
    <Box>
      <Typography variant="h5">
        <Box
          component="span"
          sx={{
            fontWeight: 900
          }}>
          Webinar Status:
        </Box>
      </Typography>
      <QueryLoading
        query={Api.getWebinars}
        listKey="webinars"
        listTitle="Webinar"
        Render={WebinarDisplay}
        pollInterval={60000}
      />
    </Box>
  );
}

export default Status;
