import { Box } from "@material-ui/core";
import React from "react";
import FormField from "./FormField";
import ActionButtons from "./ActionButtons";
import {
  getSubmitVariables,
  isEmpty,
  isFormFieldValid,
  validateForm,
} from "../utilities/utils";

const FormMainRender = ({
  // buttonProps,
  isValid,
  children,
  loading,
  actions,
  setLoading,
  formProps,
  variables,
  resetForm,
  id,
}) => {
  return actions ? (
    <Box component="form" id={id} sx={{width:"100%"}} onSubmit={(e) => e.preventDefault(e)}>
      <Box {...formProps}>
        {children}
        <ActionButtons
          setLoading={setLoading}
          actions={actions}
          loading={loading}
          resetForm={resetForm}
          disabled={!isValid || loading}
          variables={variables}
        />
      </Box>
      {/* <Button
        type="submit"
        loading={loading}
        disabled={!isValid}
        tooltip={
          !isValid && buttonProps.disabledTooltipText
            ? buttonProps.disabledTooltipText
            : "Please fill in all required fields!"
        }
        {...buttonProps}
      /> */}
    </Box>
  ) : (
    <>{children}</>
  );
};

function Form({
  fields: fs,
  genFields,
  setFields: setFs,
  loading,
  setValid: setValidPass,
  // buttonProps,
  actions,
  setLoading,
  formFieldBoxProps,
  id,
  ...formProps
}) {
  const [fieldsF, setFieldsF] = React.useState(fs);
  const fields = setFs ? fs : fieldsF;
  const setFields = setFs ?? setFieldsF;
  const resetForm = () => {
    !setFs && setFieldsF(fs);
    setFs && genFields && setFields(genFields);
  };
  const [isValid, setIsValid] = React.useState(false);
  const setValid = setValidPass || setIsValid;
  const syncFields = (pass) => {
    const t = Object.keys(fields).filter(
      (x) => fields[x].requiredOr || fields[x].requiredDependent
    );
    let fs = { ...fields, ...pass };
    const tAr = {};
    t.map((k) => {
      const obj = fs[k];
      const l =
        obj.requiredOr || obj.requiredDependent
          ? {
              requiredConditional: obj.requiredDependent
                ? !isEmpty(fs[obj.requiredDependent].value)
                : isEmpty(fs[obj.requiredOr].value),
            }
          : null;
      if (l)
        tAr[k] = {
          ...obj,
          ...(obj?.requiredConditional !== l.requiredConditional
            ? isFormFieldValid({ ...obj, ...l })?.update
            : {}),
          ...l,
        };
      return null;
    });
    fs = { ...fs, ...tAr };
    setValid(validateForm(fs));
    setFields(fs);
  };
  const updateField = (key, data) =>
    syncFields({ [key]: { ...fields[key], ...data } });

  React.useEffect(() => {
    syncFields();
  }, []);
  const canSubmit = () => {};

  return (
    <FormMainRender
      actions={actions}
      isValid={isValid}
      loading={loading}
      setLoading={setLoading}
      formProps={formProps}
      resetForm={resetForm}
      id={id}
      variables={() => getSubmitVariables(fields)}
    >
      {Object.keys(fields).map(
        (item, i) =>
          !fields[item]?.hide && (
            <Box
              key={i}
              sx={{
                mb: 4,
                width: "100%",
              }}
              {...(fields[item]?.boxProps || formFieldBoxProps)}
            >
              <FormField
                loading={fields[item].loading || loading}
                updateField={updateField}
                id={id ? `${id}-field-${item}` : item}
                name={item}
                {...fields[item]}
              />
            </Box>
          )
      )}
    </FormMainRender>
  );
}

export default Form;
