import React from "react";
// import Drawer from "../components/AdminDrawer";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Home from "./admin/Home";
import Courses from "./admin/Courses";
import Csv from "./admin/Csv";
import Settings from "./admin/Settings";
import Users from "./admin/Users";
import DashBoardLayout from "../components/dashboard/DashBoardLayout";
import { Chip, Box } from "@material-ui/core";
import hoiLogo from "../assets/hoi/logo.svg";
import HoiStatus from "./admin/hoi/Status.js";
import HoiAnalytics from "./admin/hoi/Analytics.js";
import Products from "./admin/hoi/Products";
import Emails from "./admin/hoi/Emails";
import { Api } from "../Api";
import DataGridEdit from "../components/gql/DataGridEdit";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import DraftsRoundedIcon from "@material-ui/icons/DraftsRounded";
import UpdateEmail from "../components/admin/hoi/UpdateEmail";
import TextDisplay from "../components/utilities/TextDisplay";
import {
  emailDataGrid,
  productsDataGrid,
  payoutDataGrid,
  ordersDataGrid,
  estateLensDisplayDataGrid,
} from "../data/DataGridDef";
import Orders from "./admin/hoi/Orders";
import PhotoDisplayEdit from "./admin/estatelens/PhotoDisplayEdit";
import EstateLensOverview from "./admin/estatelens/Overview";
import estateLensLogo from "../assets/estatelens/logo.svg";
import UpdateDisplay from "../components/admin/estatelens/UpdateDisplay";

const headerDict = ({ to, toParam, title, name }) => ({
  to: `${to}${toParam || ":id"}/`,
  title: title || "Loading...",
});

function Admin() {
  const [activeHoi, setActiveHoi] = React.useState(() =>
    headerDict({ to: "/admin/halalonlineincome/:item/:id/" })
  );
  const setHoiActive = (obj, baseUrl) => {
    setActiveHoi(
      headerDict({
        to: baseUrl,
        title: (
          <TextDisplay mobileText={`#${obj.id}`} text={obj.name || obj.title} />
        ),
        toParam: obj?.id,
      })
    );
  };

  const links = [
    {
      title: "Home",
      url: "/admin/",
      icon: <HomeRoundedIcon />,
      routeProps: { exact: true },
      component: Home,
      linkProps: { exact: true },
      headerProps: {
        links: [
          {
            title: "Home",
            icon: <HomeRoundedIcon />,
            to: "/admin/",
          },
        ],
      },
    },
    {
      title: "Courses",
      url: "/admin/courses/",
      icon: <MenuBookRoundedIcon />,
      routeProps: {},
      component: Courses,
      linkProps: {},
      headerProps: {
        links: [
          {
            title: "Courses",
            icon: <MenuBookRoundedIcon />,
            to: "/admin/courses/",
          },
        ],
      },
    },
    {
      title: "Users",
      url: "/admin/users/",
      icon: <PeopleAltRoundedIcon />,
      routeProps: {},
      component: Users,
      linkProps: {},
      headerProps: {
        links: [
          {
            title: "Users",
            icon: <PeopleAltRoundedIcon />,
            to: "/admin/users/",
          },
        ],
      },
    },
    {
      title: "Settings",
      url: "/admin/settings/",
      icon: <SettingsOutlinedIcon />,
      routeProps: {},
      component: Settings,
      linkProps: {},
      headerProps: {
        links: [
          {
            title: "Settings",
            icon: <SettingsOutlinedIcon />,
            to: "/admin/settings/",
          },
        ],
      },
    },
    // {
    //   title: "CSV",
    //   url: "/admin/csv/",
    //   icon: <FileCopyRoundedIcon />,
    //   routeProps: {},
    //   linkProps: {},
    //   component: Csv,
    //   headerProps: {
    //     links: [
    //       {
    //         title: "Estate Lens",
    //         icon: <img src={estateLensLogo} width={30} alt="EL" />,
    //         to: "/admin/estatelens/",
    //       },
    //     ],
    //   },
    // },
    {
      title: "Estate Lens",
      url: "/admin/estatelens/",
      icon: <img src={estateLensLogo} width={30} alt="EL" />,
      routeProps: {},
      linkProps: {},
      headerProps: {
        links: [
          {
            title: "Estate Lens",
            icon: <img src={estateLensLogo} width={30} alt="EL" />,
            to: "/admin/estatelens/",
          },
          {
            title: "360 Tour",
            to: "/admin/estatelens/360-tour/",
          },
          activeHoi,
        ],
      },
      tabs: [
        {
          title: "Overview",
          component: EstateLensOverview,
          url: "/admin/estatelens/",
          componentProps: {},
        },
        {
          title: "360 Tour",
          component: DataGridEdit,
          url: "/admin/estatelens/360-tour/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/estatelens/360-tour/",
            editRoute: "/admin/estatelens/360-tour/:id/",
            query: Api.estatelens.getDisplays,
            queryKey: "displays",
            EditComponent: PhotoDisplayEdit,
            ...estateLensDisplayDataGrid,
            aboveTable: UpdateDisplay,
          },
        },
      ],
    },
    {
      title: "HOI",
      url: "/admin/halalonlineincome/",
      icon: (
        // <Box>
        <img src={hoiLogo} width={30} alt="hoi" />
        // </Box>
      ),
      headerProps: {
        links: [
          {
            title: <TextDisplay text="Halal Online Income" mobileText="HOI" />,
            icon: <img src={hoiLogo} width={30} alt="hoi" />,
            to: "/admin/halalonlineincome/",
          },
          {
            title: "Analytics",
            to: "/admin/halalonlineincome/analytics/",
          },
          {
            title: "Products",
            to: "/admin/halalonlineincome/products/",
          },
          {
            title: "Emails",
            to: "/admin/halalonlineincome/emails/",
          },
          activeHoi,
        ],
      },
      routeProps: {},
      linkProps: {},
      tabs: [
        {
          title: "Overview",
          component: HoiStatus,
          url: "/admin/halalonlineincome/",
          componentProps: {},
        },
        {
          title: "Analytics",
          component: HoiAnalytics,
          url: "/admin/halalonlineincome/analytics/",
          componentProps: {},
        },
        {
          title: "Emails",
          component: DataGridEdit,
          url: "/admin/halalonlineincome/emails/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/emails/",
            editRoute: "/admin/halalonlineincome/emails/:id/",
            query: Api.getEmails,
            queryVariables: { hoi: true },
            queryKey: "emails",
            EditComponent: Emails,
            ...emailDataGrid,
            aboveTable: UpdateEmail,
          },
        },
        {
          title: "Pages",
          component: DataGridEdit,
          disabled: true,
          url: "/admin/halalonlineincome/pages/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/pages/",
            editRoute: "/admin/halalonlineincome/pages/:id/",
            query: Api.hoi.getHoiProducts,
            queryKey: "hoiProducts",
            EditComponent: Products,
            ...productsDataGrid,
          },
        },
        {
          title: "Users",
          disabled: true,
          component: DataGridEdit,
          url: "/admin/halalonlineincome/users/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/users/",
            editRoute: "/admin/halalonlineincome/users/:id/",
            query: Api.hoi.getHoiProducts,
            queryKey: "hoiProducts",
            EditComponent: Products,
            ...productsDataGrid,
          },
        },
        {
          title: "Products",
          component: DataGridEdit,
          url: "/admin/halalonlineincome/products/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/products/",
            editRoute: "/admin/halalonlineincome/products/:id/",
            query: Api.hoi.getHoiProducts,
            queryKey: "hoiProducts",
            EditComponent: Products,
            ...productsDataGrid,
          },
        },
        {
          title: "Orders",
          component: DataGridEdit,
          url: "/admin/halalonlineincome/orders/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/orders/",
            editRoute: "/admin/halalonlineincome/orders/:id/",
            query: Api.hoi.getOrders,
            queryKey: "hoiOrders",
            EditComponent: Orders,
            ...ordersDataGrid,
          },
        },
        {
          title: "Payouts",
          component: DataGridEdit,
          url: "/admin/halalonlineincome/payouts/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/payouts/",
            editRoute: "/admin/halalonlineincome/payouts/:id/",
            query: Api.hoi.getHoiPayouts,
            queryKey: "hoiPayouts",
            // EditComponent: Products,
            ...payoutDataGrid,
          },
        },
        {
          title: "Affiliate Users",
          component: DataGridEdit,
          disabled: true,
          url: "/admin/halalonlineincome/affiliate-users/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/affiliate-users/",
            editRoute: "/admin/halalonlineincome/affiliate-users/:id/",
            query: Api.hoi.getHoiProducts,
            queryKey: "hoiProducts",
            EditComponent: Products,
            ...productsDataGrid,
          },
        },
        {
          title: "References",
          component: DataGridEdit,
          disabled: true,
          url: "/admin/halalonlineincome/references/",
          componentProps: {
            setVal: setHoiActive,
            baseUrl: "/admin/halalonlineincome/references/",
            editRoute: "/admin/halalonlineincome/references/:id/",
            query: Api.hoi.getHoiProducts,
            queryKey: "hoiProducts",
            EditComponent: Products,
            ...productsDataGrid,
          },
        },
      ],
    },
  ];
  return (
    <DashBoardLayout
      links={links}
      title="Admin"
      titleIcon={<SettingsOutlinedIcon />}
      baseUrl="/admin/"
    />
    // <div className="admin-main">
    //   <NavBar />
    //   <div className="admin">
    //     {/* <Drawer /> */}
    //     <div className="admin__nav">
    //       <div className="nav-link separator">
    //         <SettingsOutlinedIcon />
    //         <span className="active-line"></span>
    //         Admin
    //       </div>
    //       {links.map((link)=>
    //         <NavLink to={link.url} className="nav-link">
    //           {link.icon}
    //           <span className="active-line"></span>
    //           {link.title}
    //         </NavLink>
    //       )}
    //     </div>
    //     {/* <Fade in=true> */}
    //     <div className="admin__content">
    //       <Switch>
    //         {links.map((link)=>
    //           <Route path={link.url} {...link.routeProps} />
    //         )}
    //       </Switch>
    //     </div>
    //     {/* </Fade> */}
    //   </div>
    // </div>
  );
}

export default Admin;
