import { Link } from "react-router-dom";
import { Api } from "../Api";
import UpdatePayout from "../components/admin/hoi/UpdatePayout";
import Switch from "../components/gql/Switch";
import React from "react";
import {
  displayDateFromStr,
  formatDate,
  getLowestTimeString,
  getTimeDifference,
} from "../components/utilities/utils";
import { Box, Typography } from "@material-ui/core";
import { isFunction } from "@material-ui/data-grid";

const getVal = (value, colDef, row) => value || colDef?.valueGetter({ row });

const RenderLink = ({ row, colDef, getValue, value, rowIndex, ...rest }) => {
  return (
    <Link
      to={colDef?.link.replace(
        ":id",
        colDef?.linkKey ? row[colDef.linkKey]?.id : row.id
      )}
    >
      {getVal(value, colDef, row)}
    </Link>
  );
};

export const emailDataGrid = {
  columns: ({ updateQs }) => [
    {
      field: "name",
      headerName: "Name",
      description: "Reference Name",
      sortable: true,
      width: 250,
      renderCell: RenderLink,
      link: "/admin/halalonlineincome/emails/:id/",
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   description: "Current Status",
    //   width: 100,
    //   renderCell: ({ row }) =>
    //     row.draft ? (
    //       <Chip
    //         variant="outlined"
    //         color="secondary"
    //         label="Draft"
    //         icon={<DraftsRoundedIcon />}
    //       />
    //     ) : row.published ? (
    // <Chip
    //   variant="outlined"
    //   label="Active"
    //   color="primary"
    //   icon={<CheckCircleOutlineRoundedIcon />}
    // />
    //     ) : (
    //       "N/A"
    //     ),
    // },
    {
      field: "emailTrigger",
      headerName: "Event",
      description: "Trigger Event",
      width: 200,
      valueGetter: ({ row }) => {
        return `${row.trigger?.title || "N/A"}`;
      },
    },
    {
      field: "draftStatus",
      headerName: "Draft",
      description: "Draft Status",
      sortable: true,
      width: 100,
      renderCell: ({ row }) => (
        <Switch
          value={!!row.draft}
          query={Api.updateEmail}
          valueName="draft"
          idName="emailId"
          id={row.id}
          updateQs={updateQs}
        />
      ),
      valueGetter: ({ row }) => `${row.draft ? 1 : 0}`,
    },
    {
      field: "time",
      headerName: "Time",
      description: "Time from event",
      width: 100,
      sortable: true,
      type: "number",
      // sortComparator:
      valueFormatter: (params) => getLowestTimeString(params.value),
    },
    {
      field: "linkClicks",
      headerName: "Clicks",
      description: "Link Clicks",
      width: 100,
      sortable: true,
      type: "number",
    },
    {
      field: "sends",
      headerName: "Sends",
      description: "Emails sent",
      width: 100,
      type: "number",
      sortable: true,
    },
    {
      field: "subject",
      headerName: "Subject",
      description: "Email Subject",
      width: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      description: "Updated At",
      width: 300,
      valueFormatter: (params) => displayDateFromStr(params.value),
    },
  ],
  sortModel: [
    {
      field: "draftStatus",
      sort: "asc",
    },
    // {
    //   field: "updatedAt",
    //   sort: "desc",
    // },
    {
      field: "emailTrigger",
      sort: "desc",
    },
    {
      field: "time",
      sort: "asc",
    },
  ],
};

export const productsDataGrid = {
  columns: [
    {
      field: "title",
      headerName: "Title",
      description: "Product Title",
      sortable: true,
      width: 250,
    },
    {
      field: "url",
      headerName: "Url",
      description: "Product Url",
      width: 250,
      sortable: true,
    },
    {
      field: "sales",
      headerName: "Sales",
      description: "Sales",
      width: 150,
      type: "number",
      sortable: true,
    },
  ],
  sortModel: [
    {
      field: "sales",
      sort: "desc",
    },
  ],
};

export const pageDataGrid = {
  columns: [
    {
      field: "title",
      headerName: "Title",
      description: "Product Title",
      sortable: true,
      width: 250,
    },
    {
      field: "url",
      headerName: "Url",
      description: "Product Url",
      width: 250,
      sortable: true,
    },
    {
      field: "sales",
      headerName: "Sales",
      description: "Sales",
      width: 150,
      type: "number",
      sortable: true,
    },
  ],
  sortModel: [
    {
      field: "views",
      sort: "desc",
    },
  ],
};

export const estateLensDisplayDataGrid = {
  columns: ({ updateQs }) => [
    {
      field: "id",
      headerName: "Tour ID",
      description: "Tour ID",
      renderCell: RenderLink,
      link: "/admin/estatelens/360-tour/:id/",
      width: 150,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Name",
      description: "Tour Name",
      renderCell: RenderLink,
      link: "/admin/estatelens/360-tour/:id/",
      width: 300,
      sortable: true,
    },
  ],
};

export const payoutDataGrid = {
  columns: ({ updateQs }) => [
    // payout_method{
    //   name
    // }
    // payoutAmount
    // payoutDate
    // payoutStarted
    // orderRefunded
    // paidOut
    // cancelled
    // payoutCompleted
    // cancelledReason
    // created_at
    {
      field: "id",
      headerName: "ID",
      description: "Payout Reference",
      sortable: true,
      width: 100,
      link: "/admin/halalonlineincome/payouts/:id/",
      renderCell: RenderLink,
    },
    {
      field: "orderId",
      headerName: "Order",
      description: "Order ID",
      valueGetter: ({ row }) => row.order?.id,
      renderCell: RenderLink,
      linkKey: "order",
      link: "/admin/halalonlineincome/orders/:id/",
      width: 100,
      sortable: true,
    },
    {
      field: "user",
      headerName: "Affiliate User",
      description: "Sales",
      width: 200,
      sortable: true,
      valueGetter: ({ row }) => row.affiliateUser?.nickname,
      renderCell: RenderLink,
      linkKey: "affiliateUser",
      link: "/admin/halalonlineincome/affiliate-users/:id/",
    },
    {
      field: "status",
      headerName: "Status",
      description: "Payout Status",
      width: 250,
      sortable: true,
      valueGetter: getPayoutStatus,
      renderCell: ({ value, colDef, row }) => {
        const val = payoutStatuses[getVal(value, colDef, row)];
        return (
          <Typography variant="subtitle2">
            <Box
              component="span"
              sx={{
                color: val?.color,
                fontWeight: "bold",
              }}
            >
              {isFunction(val.text) ? val.text(row?.payoutDate) : val.text}
            </Box>
          </Typography>
        );
      },
      //   renderCell: (props) => <UpdatePayout updateData={updateQs} {...props} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      description: "Update Payout",
      width: 250,
      type: "number",
      sortable: true,
      valueGetter: getPayoutStatus,
      renderCell: (props) =>
        getPayoutStatus(props) >= 4 ? (
          <React.Fragment>
            <UpdatePayout markPaidOut updateData={updateQs} {...props} />{" "}
            <UpdatePayout cancelPayout updateData={updateQs} {...props} />
          </React.Fragment>
        ) : (
          <Typography variant="subtitle2">
            <Box
              component="span"
              sx={{
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              No Actions Available
            </Box>
          </Typography>
        ),
    },
  ],
  sortModel: [
    {
      field: "status",
      sort: "desc",
    },
  ],
};

const payoutStatuses = {
  0: { text: "Paid out Successfully", color: "success.main" },
  1: { text: "Order Refunded", color: "error.main" },
  2: { text: "Order Disputed", color: "warning.main" },
  3: { text: "Payout Started", color: "success.main" },
  4: {
    text: (date) =>
      `Over Due By ${getLowestTimeString(
        getTimeDifference(new Date(), formatDate(date))
      )}`,
    // text: "Payout Over Due",
    color: "warning.main",
  },
  5: {
    text: (date) =>
      `Due in ${getLowestTimeString(
        getTimeDifference(new Date(), formatDate(date))
      )}`,
    color: "success.main",
  },
};

const getPayoutStatus = ({ row: payout }) => {
  if (!!payout.paidOut) return 0;
  else if (!!payout.orderRefunded) return 1;
  else if (!!payout.order.disputeStarted) return 2;
  else if (!!payout.order.payoutStarted) return 3;
  else if (formatDate(payout.payoutDate) < new Date()) return 4;
  return 5;
};

export const ordersDataGrid = {
  columns: ({ updateQs }) => [
    {
      field: "id",
      headerName: "ID",
      description: "Order ID",
      sortable: true,
      width: 100,
      renderCell: RenderLink,
      link: "/admin/halalonlineincome/orders/:id/",
    },
    {
      field: "productTitle",
      headerName: "Product",
      description: "Product Title",
      sortable: true,
      width: 250,
      valueGetter: ({ row }) => row.product?.title,
      renderCell: RenderLink,
      linkKey: "product",
      link: "/admin/halalonlineincome/products/:id/",
    },
    {
      field: "affiliateUserNickname",
      headerName: "Affiliate",
      description: "Affiliate User",
      width: 150,
      sortable: true,
      valueGetter: ({ row }) => row.affiliateUser?.nickname ?? "N/A",
      renderCell: RenderLink,
      linkKey: "affiliateUser",
      link: "/admin/halalonlineincome/affiliate-user/:id/",
    },
    {
      field: "paymentLength",
      headerName: "Length",
      description: "Payment Length",
      sortable: true,
      width: 100,
      type: "number",
    },
    {
      field: "amountPaid",
      headerName: "Amount Paid",
      description: "Total Amount Paid",
      sortable: true,
      width: 150,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      description: "Total Price",
      sortable: true,
      width: 150,
    },
    {
      field: "initialPayment",
      headerName: "First Payment",
      description: "First Payment",
      sortable: true,
      width: 150,
    },
  ],
  sortModel: [
    {
      field: "initialPayment",
      sort: "desc",
    },
  ],
};
