import React from "react";
import SendIcon from "@material-ui/icons/Send";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Api } from "../../Api";
import GradientButton from "../GradientButton";
import { Box, IconButton, Slide, Typography } from "@material-ui/core";
import { isEmailValid } from "../utilities/utils";
import Form from "../forms/Form";
import { useSnackbar } from "notistack";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../reducer";

const PasswordHelper = ({ show }) => {
  return show ? (
    <Link to="/login/forgot-password">Forgot your password?</Link>
  ) : (
    <React.Fragment />
  );
};
const genFields = {
  email: {
    value: "",
    label: "Email",
    errorText: "Please Enter an Email",
    email: true,
    required: true,
    name: "email",
    fieldProps: { variant: "outlined", autoComplete: "email", type: "email" },
  },
  password: {
    value: "",
    label: "Password",
    required: true,
    name: "password",
    password: true,
    helperTextComponent: PasswordHelper,
    helperTextComponentProps: {
      show: false,
    },
    fieldProps: {
      variant: "outlined",
      autoComplete: "current-password",
      inputProps: {
        "aria-describedby": "password-constraints",
      },
      type: "password",
    },
  },
  keepLoggedIn: {
    type: "checkbox",
    value: true,
    label: "Keep me Logged in",
    // helperTrue: "All previous activities will be marked as completed",
    // helperFalse: "Completed status of previous activities will not be modified",
    name: "keepLoggedIn",
  },
};
function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const history = useHistory();
  React.useEffect(() => {
    location.pathname === "/login/forgot-password"
      ? isEmailValid(fields?.email.value)
        ? setShowForgotPassword(true)
        : history.push("/login")
      : setShowForgotPassword(false);
  }, [location.pathname]);
  const setCanForgotPassword = () => {
    // console.log("call");
    const val = fields.email.value;
    isEmailValid(fields.email.value) &&
      setFields({
        ...fields,
        password: {
          ...fields.password,
          helperTextComponentProps: {
            show: val,
            updateShow: setShowForgotPassword,
          },
        },
      });
  };

  const [fields, setFields] = React.useState(genFields);
  const [{}, dispatch] = useStateValue();
  const parentContainer = React.useRef(null);
  const loginUpdate = ({ token, refreshToken, variables: vars }) => {
    if (token == null) {
      enqueueSnackbar("Incorrect Email or Password!", { variant: "error" });
    } else {
      dispatch({
        type: actionTypes.SET_AUTH_DATA,
        authData: {
          token: token,
          refreshToken: refreshToken,
          keepMeLoggedIn: vars.keepLoggedIn ? true : false,
        },
      });
    }
  };
  return (
    <Box>
      <Box
        sx={{
          boxShadow: "2px 5px 10px rgba(0,0,0,0.2);",
          // height: 60,
          display: "flex",
          px: 2,
          py: 3,
        }}
      >
        {/* <p>ALREADY MEMBERS</p> */}
        <Slide
          direction="left"
          in={location.pathname === "/login"}
          mountOnEnter
          unmountOnExit
        >
          <Typography>Sign In</Typography>
        </Slide>
        <Slide
          direction="left"
          in={location.pathname === "/login/forgot-password"}
          mountOnEnter
          unmountOnExit
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mr: 2,
              }}
            >
              <Link to="/login">
                <IconButton>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Link>
            </Box>
            <Typography>Forgotten Password</Typography>
          </Box>
        </Slide>
      </Box>
      <Box
        ref={parentContainer}
        // sx={{
        //   display: "flex",
        // }}
      >
        <Slide
          direction="left"
          in={!showForgotPassword}
          // mountOnEnter
          unmountOnExit
        >
          <Box>
            <Form
              fields={fields}
              setFields={setFields}
              genFields={genFields}
              sx={{ p: 2 }}
              actions={[
                {
                  mutation: {
                    buttonProps: {
                      children: "Sign In",
                      endIcon: <SendIcon />,
                      type: "submit",
                      fluid:true,
                    },
                    query: Api.getToken,
                    errorMessage: "Incorrect Email or Password!",
                    dataKey: "action",
                    updateValue: loginUpdate,
                    returnVariables: true,
                    ignoreSuccessMessage: true,
                    onError: (e) => setCanForgotPassword(),
                  },
                },
              ]}
            />
          </Box>
        </Slide>
        <ForgotPassword
          show={showForgotPassword}
          email={fields?.email?.value}
          parentContainer={parentContainer}
        />
      </Box>
    </Box>
  );
}

export default LoginForm;

const genForgotFields = () => ({
  code: {
    int: true,
    name: "token",
    value: "",
    label: "Code",
    errorText: "Please enter a Code",
    required: true,
    fieldProps: {
      variant: "outlined",
      autoComplete: "one-time-email",
      inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
    },
  },
});

const ForgotPassword = ({ show, email, parentContainer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [fields, setFields] = React.useState(genForgotFields());
  // const history = useHistory();
  const handleSubmit = () => {};
  return (
    <Slide direction="left" in={show} unmountOnExit>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 2,
          width: "100%",
          minWidth: "100%",
        }}
      >
        <Typography variant="h6">We have sent an email to</Typography>
        <Box
          sx={{
            my: 2,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Typography color="textSecondary">{email}</Typography>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Link to="/login">
              <Typography variant="subtitle2"> Change</Typography>
            </Link>
          </Box>
        </Box>
        <Form
          fields={fields}
          setFields={setFields}
          loading={loading}
          setValid={setValid}
        />
        <GradientButton
          endIcon={<SendIcon />}
          loading={loading}
          disabled={loading || !valid}
          // onClick={handleSubmit}
          type="submit"
          fullWidth="true"
        >
          Verify
        </GradientButton>
      </Box>
    </Slide>
  );
};
