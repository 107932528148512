import React from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import QueryLoading from "./QueryLoading";

const DataGridEdit = ({
  setVal,
  baseUrl,
  editRoute,
  aboveTable,
  aboveTableProps,
  query,
  queryVariables,
  queryKey,
  columns,
  sortModel,
  EditComponent,
}) => {
  const history = useHistory();
  // const onRowClick = ({ row }) =>
  //   editRoute && history.push(`${baseUrl}${row.id}/`);
  return (
    <Box>
      <QueryLoading
        editRoute={editRoute}
        appendRow={setVal}
        dataGrid={{
          columns: columns,
          sortModel: sortModel,
          // onRowClick: onRowClick,
        }}
        aboveTable={aboveTable}
        query={query}
        pollInterval={600000}
        variables={queryVariables}
        listKey={queryKey}
        EditComponent={EditComponent}
        baseUrl={baseUrl}
        aboveTableProps={aboveTableProps}
      />
    </Box>
  );
};

export default DataGridEdit;
