import { Typography, Grid, Box } from "@material-ui/core";
import React from "react";
import PhotoDisplay from "../../../components/admin/estatelens/PhotoDisplayOriginal";
import ImageList from "../../../components/admin/estatelens/ImageList";
import BatchUpload from "../../../components/admin/estatelens/BatchUpload";
import Form from "../../../components/forms/Form";
import { Api } from "../../../Api";
import { useSnackbar } from "notistack";
import LazyQueryLoading from "../../../components/gql/LazyQueryLoading";

const RenderText = ({ children }) => (
  <Typography sx={{ p: 3 }}>{children}</Typography>
);

const PhotoDisplayEdit = ({ object: display, updateQs }) => {
  const images = display?.images;
  const [selectedImage, setSelectedImage] = React.useState(images[0] || {});
  const [targetCo, setTargetCo] = React.useState({});
  const [hotspotDebug, setHotspotDebug] = React.useState(true);
  const updateSelected = (i, rt) => {
    const resetTarget = rt ?? true;
    if (!i?.id) return null;
    const im = i?.image ? i : images.find((x) => x.id === i.id);
    if (!selectedImage?.id || selectedImage?.id !== im?.id) {
      console.log(resetTarget);
      resetTarget && setTargetCo({});

      setSelectedImage(im);
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleHotspotClick = (
    event,
    { toImage, toLink, targetPitch, targetYaw }
  ) => {
    if (toImage) {
      setTargetCo({ pitch: targetPitch, yaw: targetYaw });
      updateSelected(toImage, false);
    } else {
      !toLink && enqueueSnackbar("Invalid Destination", { variant: "error" });
    }
  };
  const copyCallBack =
    (key) =>
    ({ [key]: html }) => {
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(html);
        enqueueSnackbar("Copied to Clipboard", { variant: "info" });
      } else {
        enqueueSnackbar("Clipboard Not Found!", { variant: "error" });
      }
    };
  const displayRef = React.useRef(null);
  const getHotspotObj = ({
    toLink,
    tooltip,
    pitch,
    yaw,
    toImage,
    id,
    targetYaw,
    targetPitch,
  }) => {
    const clickD = !tooltip && {
      clickHandlerFunc: handleHotspotClick,
      clickHandlerArgs: {
        toImage: toImage,
        targetYaw: targetYaw,
        targetPitch: targetPitch,
      },
    };
    const dat = {
      id: `hsp-${id}`,
      type: tooltip ? "info" : toImage?.id ? "scene" : "link",
      pitch: pitch,
      yaw: yaw,
      text: tooltip ? tooltip : toImage?.name,
      URL: toLink,
      sceneId: !toLink && toImage && `estate-lens-${display.id}-${toImage.id}`,
      targetHfov: "same",
      targetYaw: targetYaw,
      targetPitch: targetPitch,
      ...clickD,
    };
    return dat;
  };
  const updateObj =
    (deleteObj, img, cc) =>
    ({ image: im, hotspot, images: imagesPass }) => {
      const canCreate = cc || true;
      const image = img || im;
      let is = [...images];
      let hs = image && [...image.hotspots];
      if (image) {
        const foundIndex = is.findIndex((x) => x.id === image.id);
        if (foundIndex > -1) {
          if (hotspot) {
            const hotspotIndex = hs.findIndex((x) => x.id === hotspot.id);
            // const viewer = displayRef.current;
            const viewer = displayRef.current.getViewer();
            if (hotspotIndex > -1) {
              if (deleteObj) {
                hs.splice(hotspotIndex, 1);
              } else {
                hs[hotspotIndex] = { ...hs[hotspotIndex], ...hotspot };
              }

              viewer.removeHotSpot(`hsp-${hotspot.id}`);
              viewer.addHotSpot(getHotspotObj(hotspot));
            } else {
              if (canCreate) {
                if (image) setSelectedImage(image);
                hs.push(hotspot);
                viewer.addHotSpot(getHotspotObj(hotspot));
              }
            }
            is[foundIndex] = { ...is[foundIndex], hotspots: hs };
          } else {
            if (deleteObj) {
              is.splice(foundIndex, 1);
              if (selectedImage.id === image.id) setSelectedImage(images[0]);
            } else {
              is[foundIndex] = { ...is[foundIndex], ...image };
            }
          }
        } else if (!hotspot) {
          if (canCreate) {
            is.push(image);
            setSelectedImage(image);
          }
        }
      }
      if (imagesPass) {
        console.log(imagesPass)
        imagesPass.map((imgP) => {
          const index = is.findIndex((x) => x.id === imgP.id);
          if (index > -1) {
            is[index] = { ...is[index], ...imgP };
          }
          else{
            is.push(imgP)
          }
          return null;
        });
      }
      if(imagesPass||image){
        is.sort((a, b) => (a.order > b.order ? 1 : -1));
        updateQs(display.id, { images: is });
      }
    };
  return (
    <Grid container>
      <Box
        component={Grid}
        item
        xs={12}
        md={8}
        sx={{
          border: 1,
          borderColor: "grey.500",
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {selectedImage?.id ? (
          selectedImage?.image ? (
            <PhotoDisplay
              image={selectedImage}
              displayRef={displayRef}
              hotspotDebug={true}
              target={targetCo}
              resetTarget={() => setTargetCo({})}
              // updateSelected={updateSelected(true)}
              display={display}
              getHotspotObj={getHotspotObj}
              updateSelectedImage={updateObj(null, null)}
            />
          ) : (
            <RenderText>No Image Found</RenderText>
          )
        ) : (
          <RenderText>Please Select an Image</RenderText>
        )}
      </Box>
      <Box
        component={Grid}
        item
        xs={12}
        md={4}
        sx={{
          border: 1,
          borderColor: "grey.500",
          justifyContent: "center",
          display: "flex",
          alignContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {images && images.length > 0 ? (
          <ImageList
            images={images}
            updateObj={updateObj}
            // selectedHotSpotId={selectedHotSpot?.id}
            // setSelectedHotspot={updateSelected(false)}
            selectedId={selectedImage?.id}
            setSelected={updateSelected}
            displayRef={displayRef}
          />
        ) : (
          <RenderText>No Images</RenderText>
        )}
      </Box>
      <Grid item xs={12}>
        <Typography
          sx={{
            borderBottom: 1,
            borderColor: "grey.500",
            fontWeight: 700,
            mt: 3,
            py: 1,
          }}
          variant="h5"
        >
          Add Image
        </Typography>
      </Grid>
      <AddImage updateObj={updateObj} display={display} />
      <Grid item xs={12}>
        <Typography
          sx={{
            borderBottom: 1,
            borderColor: "grey.500",
            fontWeight: 700,
            mt: 3,
            py: 1,
            mb: 2,
          }}
          variant="h5"
        >
          Batch Upload
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BatchUpload updateObj={updateObj(false)} display={display} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            borderBottom: 1,
            borderColor: "grey.500",
            fontWeight: 700,
            mt: 3,
            py: 1,
            mb: 2,
          }}
          variant="h5"
        >
          Options
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pb: 3,
          borderColor: "grey.500",
          justifyContent: "flex-start",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: 3 }}>
          <LazyQueryLoading
            variables={{ id: display.id }}
            dataKey="getDisplayHtml"
            buttonProps={{
              children: "Get HTML",
            }}
            updateValue={copyCallBack("html")}
            query={Api.estatelens.getRawHtml}
          />
        </Box>
        <Box sx={{ mr: 3 }}>
          <LazyQueryLoading
            variables={{ id: display.id }}
            dataKey="getDisplayPreviewLink"
            buttonProps={{
              color: "primary",
              children: "Preview",
              tooltip: { title: "Get Preview Url" },
            }}
            updateValue={({ link }) => window.open(link, "_blank")}
            query={Api.estatelens.getPreviewLink}
          />
        </Box>
        <LazyQueryLoading
          variables={{ id: display.id }}
          dataKey="getDisplayPreviewLink"
          buttonProps={{
            color: "secondary",
            children: "Copy Preview",
            tooltip: { title: "Copy Preview Url" },
          }}
          updateValue={copyCallBack("link")}
          query={Api.estatelens.getPreviewLink}
        />
      </Grid>
    </Grid>
  );
};

const gridBSX = {
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  mb: 3,
  display: "flex",
};
const gridB = {
  component: Grid,
  item: true,
  xs: 12,
  sx: gridBSX,
};

const AddImage = ({ updateObj, display }) => {
  const [loading, setLoading] = React.useState(false);
  const fields = {
    displayId: {
      hide: true,
      value: display.id,
    },
    imageName: {
      value: "",
      label: "Display Name (E.g 'Kitchen')",
      required: true,
      boxProps: { md: 12, lg: 5, ...gridB },
    },
    imageFile: {
      type: "file",
      // acceptedFiles: ["jpeg", "jpg", "png"],
      value: null,
      required: true,
      accept: "image/*",
    },
  };
  return (
    <Form
      container
      component={Grid}
      columnSpacing={2}
      sx={{ my: 3 }}
      fields={fields}
      loading={loading}
      setLoading={setLoading}
      formFieldBoxProps={{
        ...gridB,
        md: 9,
        lg: 5,
        sx: { ...gridBSX, justifyContent: { xs: "flex-start" } },
      }}
      actions={[
        {
          mutation: {
            setLoading: setLoading,
            successMessage: "Image Added Successfully",
            query: Api.estatelens.updateImage,
            updateValue: updateObj(false),
            dataKey: "updateImage",
            buttonProps: {
              type: "submit",
              fullWidth: true,
              boxProps: { md: 2, ...gridB },
              disabledTooltip: {},
              children: "Upload",
            },
          },
        },
      ]}
    />
  );
};

export default PhotoDisplayEdit;
