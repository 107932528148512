import React from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { Api } from "../../Api";
import { config } from "../../Constants";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";
import PageLoader from "../PageLoader";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
const GraphAuth = ({ children }) => {
  const [refreshTokenMutation, { refetchQueries }] = useMutation(
    Api.refreshToken
  );
  const [logoutMutation, { client }] = useMutation(Api.logoutQueries);
  const logoutCall = (e) => {
    setTokens({ token: null, refreshToken: authData.token, error: e });
  };
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [{ user, authData }, dispatch] = useStateValue();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const getNext = () => {
    const params = new URLSearchParams(location.search);
    return params.get("next");
  };
  const [isKml,setIsKml] = React.useState(false)
  const [initialFetch, setInitialFetch] = React.useState(false);
  const setUserCallback = ({ usersCourses, me }) => {
    if (!me) logoutCall();
    else {
      dispatch({
        type: actionTypes.SET_COURSE_USERS,
        courseUsers: usersCourses,
      });
      dispatch({
        type: actionTypes.SET_USER,
        user: me,
      });
      !isKml && enqueueSnackbar("Logged In Successfully", {
        variant: "success",
      });
      loading && setLoading(false);
      // if (redirect) {
      //   history.push(getNext() || "/");
      //   loading && setLoading(false);
      // }
    }
  };
  const setUser = () => {
    !user &&
      client
        .query({ query: Api.getInitialUserData })
        .then(({ data }) => {
          setUserCallback(data);
        })
        .catch(logoutCall);
  };
  const logoutClearDataStorage = () => {
    localStorage.removeItem(config.storageItemKeys.token);
    localStorage.removeItem(config.storageItemKeys.refreshToken);
    localStorage.removeItem(config.storageItemKeys.keepMeLoggedIn);
  };
  const logoutDataReset = (e) => {
    enqueueSnackbar(e?.message || "Logged out Successfully", {
      variant: e?.message ? "error" : "success",
    });
    logoutClearDataStorage();
    user &&
      dispatch({
        type: actionTypes.SET_USER,
        user: null,
      });
    dispatch({
      type: actionTypes.SET_AUTH_DATA,
      authData: {
        token: null,
        refreshToken: null,
        keepMeLoggedIn: false,
      },
    });
    history.push(getNext() || "/");
    loading && setLoading(false);
  };
  // if no token -> logout
  const setTokens = ({ token, refreshToken, keepMeLoggedIn, error }) => {
    if (!token && user) {
      refreshToken
        ? logoutMutation({
            variables: {
              refreshToken,
            },
          })
            .then((result) => logoutDataReset())
            .catch(logoutDataReset)
        : logoutDataReset(error);
    } else if (!token) {
      logoutClearDataStorage();
    } else {
      localStorage.setItem(config.storageItemKeys.token, token);
      localStorage.setItem(config.storageItemKeys.refreshToken, refreshToken);
      keepMeLoggedIn &&
        localStorage.setItem(config.storageItemKeys.keepMeLoggedIn, "0");
      !user && setUser();
    }
  };
  const refreshToken = () => {
    if ((initialFetch && !user) || !authData.refreshToken) return null;
    const mt = initialFetch ? refetchQueries : refreshTokenMutation;
    setInitialFetch(true);
    mt({
      variables: {
        refreshToken: authData.refreshToken,
      },
    })
      .then(({ data }) => {
        const result = data.refreshToken;
        dispatch({
          type: actionTypes.SET_AUTH_DATA,
          authData: {
            token: result.token,
            refreshToken: result.refreshToken,
          },
        });
      })
      .catch(logoutCall);
  };
  const onLoad = () => {
    const refreshToken = localStorage.getItem(
      config.storageItemKeys.refreshToken
    );
    const kml =
      localStorage.getItem(config.storageItemKeys.keepMeLoggedIn) === "0";
    if (refreshToken && kml){
      setIsKml(true)
      dispatch({
        type: actionTypes.SET_AUTH_DATA,
        authData: {
          refreshToken: refreshToken,
          keepMeLoggedIn: kml,
        },
      });
    }
    else {
      if (refreshToken) logoutClearDataStorage();
      setLoading(false);
      setInitialFetch(true);
    }
  };
  React.useEffect(() => {
    onLoad();
    const timer = setInterval(refreshToken, 60000);
    return () => clearInterval(timer);
  }, []);
  React.useEffect(() => {
    initialFetch ? setTokens(authData) : refreshToken();
  }, [authData]);

  return loading ? <PageLoader /> : children;
};
export default GraphAuth;
