import React from "react";
// import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../reducer";
import { useHistory } from "react-router-dom";
import hoiLogo from "../../assets/hoi/logo.svg";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import {
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const links = [
  { title: "Home", to: "/", icon: <HomeRoundedIcon /> },
  { title: "Dashboard", to: "/dashboard/" },
  {
    title: "Courses",
    to: "/dashboard/courses/",
    icon: <MenuBookRoundedIcon />,
  },
  {
    title: "Admin",
    divider: true,
    to: "/admin/",
    superuser: true,
    icon: <SettingsOutlinedIcon />,
  },
  {
    title: "HOI",
    to: "/admin/halalonlineincome/",
    superuser: true,
    icon: (
      <img src={hoiLogo} style={{ maxHeight: "32px" }} width={30} alt="hoi" />
    ),
  },
];

function MainSide() {
  const classes = useStyles();
  const history = useHistory();
  const [{ mainSideOpen, user }, dispatch] = useStateValue();
  const toggleDrawer = (st) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    dispatch({
      type: actionTypes.SET_MAIN_SIDE_OPEN,
      mainSideOpen: st,
    });
  };
  return (
    <SwipeableDrawer
      anchor="left"
      open={mainSideOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {/* <List>
          {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>

        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
        <List>
          {links.map(
            (link, i) =>
              ((link.superuser && user.isSuperuser) || !link.superuser) && (
                <React.Fragment key={i}>
                  {link.divider && <Divider />}
                  {/* <NavLink to={link.to}> */}
                  <ListItem
                    button
                    key={i}
                    onClick={() => history.push(link.to)}
                  >
                    <ListItemIcon>{link.icon && link.icon}</ListItemIcon>
                    <ListItemText primary={link.title} />
                  </ListItem>
                  {/* </NavLink> */}
                </React.Fragment>
              )
          )}
        </List>
      </div>
    </SwipeableDrawer>
  );
}

export default MainSide;
