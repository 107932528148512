import React from "react";
import DashboardLayout from "../components/dashboard/DashBoardLayout";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import Home from "./dashboard/Home";
import Courses from "./dashboard/Courses";
import Leads from "./dashboard/Leads";
import Profile from "./dashboard/Profile";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import PublicIcon from "@material-ui/icons/Public";
import { useStateValue } from "../StateProvider";

const baseUrl = "/dashboard/";
function Main() {
  const [{ user }, dispatch] = useStateValue();
  const [links, setLinks] = React.useState([
    {
      title: "Home",
      url: `${baseUrl}`,
      icon: <HomeRoundedIcon />,
      routeProps: { exact: true },
      linkProps: { exact: true },
      component: Home,
      headerProps: {
        links: [{ title: "Home", icon: <HomeRoundedIcon />, to: baseUrl }],
      },
    },
    {
      title: "Courses",
      url: `${baseUrl}courses/`,
      icon: <MenuBookRoundedIcon />,
      routeProps: {},
      component: Courses,
      linkProps: {},
      headerProps: {
        links: [
          {
            title: "Courses",
            icon: <MenuBookRoundedIcon />,
            to: `${baseUrl}courses/`,
          },
        ],
      },
    },
    {
      title: <span>Leads</span>,
      url: `${baseUrl}leads/`,
      icon: <PublicIcon />,
      disabled: !user?.["leadsAccess"],
      locked: !user?.["leadsAccess"],
      routeProps: {},
      component: Leads,
      linkProps: {},
      headerProps: {
        links: [
          { title: "Leads", icon: <PublicIcon />, to: `${baseUrl}leads/` },
        ],
      },
    },
    {
      title: "Profile",
      url: `${baseUrl}profile/`,
      icon: <PeopleAltRoundedIcon />,
      routeProps: {},
      component: Profile,
      linkProps: {},
      headerProps: {
        links: [
          {
            title: "Profile",
            icon: <PeopleAltRoundedIcon />,
            to: `${baseUrl}profile/`,
          },
        ],
      },
    },
  ]);
  return (
    <DashboardLayout links={links} title="Dashboard" baseUrl="/dashboard/" />
  );
}

export default Main;
