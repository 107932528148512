import React from "react";
import { Alert, Grid, Drawer, Button, Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Api } from "../../../Api";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import EnrollUsers from "../../../components/admin/users/EnrollUsers";
import EditUserForm from "../../../components/admin/users/EditUserForm";
import { formatDate } from "../../../components/utilities/utils";

const useStyles = makeStyles((theme) => ({
  flex1: {
    flex: 1,
  },
}));
const sortModel = [
  {
    field: "enrolled",
    sort: "desc",
  },
];

function CourseUsers({ course }) {
  const classes = useStyles();
  const columns = [
    // {
    //   field: "id",
    //   type: "number",
    //   headerName: "ID",
    //   width: 70,
    //   description: "Database User ID",
    // },
    {
      field: "complete",
      headerName: "%",
      description: "Activities Completed",
      sortable: true,
      width: 70,
      valueGetter: (params) =>
        `${Math.floor(
          (params.row.activityComplete / course.activityCount) * 100
        )}`,
    },
    {
      field: "fullName",
      headerName: "Name",
      description: "Full Name",
      sortable: true,
      width: 160,
      valueGetter: (params) =>
        `${params.row.user.firstName || ""} ${params.row.user.lastName || ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      valueGetter: (params) => params.row.user.email || "",
    },

    {
      field: "enrolled",
      headerName: "Enrolled",
      width: 200,
      description: "Enrolled Date",
      type: "dateTime",
      valueGetter: (params) =>
        params.row.enrolled ? formatDate(params.row.enrolled) : "",
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      description: "Users Role in this Course",
    },
  ];
  const {
    data: courseUserData,
    loading: courseUserLoading,
    error: courseUserError,
    updateQuery: updateCourseUserData,
  } = useQuery(Api.getCourseUsers, {
    variables: { courseId: course.id },
  });
  const {
    data: courseActivityData,
    loading: courseActivityLoading,
    error: courseActivityError,
  } = useQuery(Api.getCourseActivitiesShort, {
    variables: { courseId: course.id },
  });
  const [editUser, setEditUser] = React.useState();
  const [editUserOpen, setEditUserOpen] = React.useState(false);
  const [enrollLoading, setEnrollLoading] = React.useState(false);
  const [showEnroll, setShowEnroll] = React.useState(false);
  const rowClick = ({ data }) => {
    data && setEditUser(data);
    data && setEditUserOpen(true);
  };
  const toggleEditOpen = (val) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setEditUserOpen(val);
  };
  return (
    <Grid container justifyContent="center">
      <Drawer
        anchor="right"
        open={editUserOpen}
        onClose={toggleEditOpen(false)}
      >
        <EditUserForm
          courseUser={editUser}
          courseActivityData={courseActivityData}
          courseActivityLoading={courseActivityLoading}
          courseActivityError={courseActivityError}
          updateCourseUserData={updateCourseUserData}
          setCourseUser={setEditUser}
          course={course}
        />
      </Drawer>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowEnroll(!showEnroll)}
        >
          Enroll
        </Button>
      </Grid>
      {showEnroll && (
        <EnrollUsers
          course={course}
          updateCourseUserData={updateCourseUserData}
          enrollLoading={enrollLoading}
          setEnrollLoading={setEnrollLoading}
        />
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            my: 3
          }}>
          {!courseUserError ? (
            <Box
              sx={{
                height: 600
              }}>
              {/* className={classes.dataGrid} */}
              <DataGrid
                loading={courseUserLoading || enrollLoading}
                rows={courseUserData ? courseUserData.courseUsers : []}
                columns={columns}
                // pageSize={10}
                // autoHeight={true}
                sortModel={sortModel}
                // autoPageSize={true}
                checkboxSelection
                rowHeight={45}
                onRowClick={rowClick}
                pagination
                pageSize={25}
              />
            </Box>
          ) : (
            <Alert severity="error">
              {courseUserError
                ? courseUserError.message
                : "An error has occurred loading course users. Please try again!"}
            </Alert>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default CourseUsers;
