import React from "react";
import { useStateValue } from "../../StateProvider";
import { Alert, AlertTitle, Grid, Box } from "@material-ui/core";

import CourseTile from "../../components/course/CourseTile";

function Courses() {
  const [{ courseUsers }] = useStateValue();
  const boxProps = { pt: 3 };
  return (
    <Grid className="container" container justifyContent="center">
      {courseUsers && courseUsers.length > 0 ? (
        courseUsers.map((cu, i) => (
          <Box
            component={Grid}
            {...boxProps}
            key={cu.id}
            item
            xs={12}
            md={4}
            lg={3}
          >
            <CourseTile
              progress={
                (cu.activityComplete /
                  (cu.course.activityCount > 0 ? cu.course.activityCount : 1)) *
                100
              }
              course={cu.course}
              url={`/dashboard/courses/${cu.course.slug}/`}
            />
          </Box>
        ))
      ) : (
        <Box
          component={Grid}
          {...boxProps}
          item
          xs={12}
          style={{ paddingTop: 20 }}
        >
          <Alert severity="info">
            <AlertTitle>
              <strong>You don't seem to be enrolled in any courses!</strong>
            </AlertTitle>
            If you believe this is an error, Please contact us.
          </Alert>
        </Box>
      )}
    </Grid>
  );
}

export default Courses;
