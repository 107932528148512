import { Grid, Typography, Box, Chip, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Api } from "../../../Api";
import DateTimePicker from "@material-ui/lab/DatePicker";
// import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import AnalyticVal from "../../../components/admin/AnalyticVal";

const dateTimeProps = {
  autoOk: true,
  // animateYearScrolling: true
};

const stats = [
  { qs: "total_sales_count", title: "Total Sales", renderProps: {} },
  { qs: "total_sales_amount", title: "Total Sales Revenue", renderProps: {} },
  { qs: "webinar_registers", title: "Sign Ups", renderProps: {} },
  { qs: "total_time_watched", title: "Total Watch Time", renderProps: {} },
  { qs: "watched_count", title: "Webinar Watches", renderProps: {} },
  { qs: "single_sales_count", title: "Single Payment Sales", renderProps: {} },
  {
    qs: "subscription_completed_sales_count",
    title: "Completed Subscriptions",
    renderProps: {},
  },
  {
    qs: "subscription_sales_count",
    title: "Subscription Sales",
    renderProps: {},
  },

  {
    qs: "subscription_sales_amount",
    title: "Subscription Revenue",
    renderProps: {},
  },
  {
    qs: "single_sales_amount",
    title: "Single Payment Revenue",
    renderProps: {},
  },
];

function Analytics() {
  const [startDate, setStartDate] = useState(new Date(Date.now() - 86400000));
  const [endDate, setEndDate] = useState(new Date());
  const chipClick = (days) => (event) => {
    setEndDate(new Date());
    setStartDate(
      days === 0
        ? new Date(2020, 4, 18)
        : new Date(Date.now() - 86400000 * days)
    );
  };
  return (
    <Box>
      <Box
        sx={{
          mb: 3
        }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item sm={6} md={4} xs={12}>
            <Box
              sx={{
                width: "100%",
                my: 2,
                display: "flex",
                alignItems: "center"
              }}>
              <Typography variant="body1">
                <Box
                  component="span"
                  sx={{
                    mr: 2,
                    fontWeight: 500
                  }}>
                  From
                </Box>
              </Typography>
              <DateTimePicker
                style={{ width: "100%" }}
                value={startDate}
                maxDate={endDate}
                onChange={setStartDate}
                renderInput={(props) => <TextField {...props} />}
                {...dateTimeProps}
              />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} md={4}>
            <Box
              sx={{
                width: "100%",
                my: 2,
                display: "flex",
                alignItems: "center"
              }}>
              <Typography variant="body1">
                <Box
                  component="span"
                  sx={{
                    mr: 2,
                    fontWeight: 500
                  }}>
                  To
                </Box>
              </Typography>
              <DateTimePicker
                style={{ width: "100%" }}
                value={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={setEndDate}
                showTodayButton={true}
                renderInput={(props) => <TextField {...props} />}
                {...dateTimeProps}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          mb: 4
        }}>
        <Chip
          variant="outlined"
          label="All Time"
          color="primary"
          clickable
          onClick={chipClick(0)}
          // icon={<CheckCircleOutlineRoundedIcon />}
        />
        <Chip
          variant="outlined"
          label="Last 28 Days"
          color="primary"
          onClick={chipClick(28)}
          // icon={<CheckCircleOutlineRoundedIcon />}
        />
        <Chip
          variant="outlined"
          label="Last 7 Days"
          color="primary"
          onClick={chipClick(7)}
          // icon={<CheckCircleOutlineRoundedIcon />}
        />
        <Chip
          variant="outlined"
          label="Last 24 Hours"
          color="primary"
          onClick={chipClick(1)}
          // icon={<CheckCircleOutlineRoundedIcon />}
        />
      </Box>
      <Grid container spacing={3} justifyContent="center">
        {stats.map((stat, i) => (
          <AnalyticVal
            key={i}
            title={stat.title}
            count={i}
            qs={stat.qs}
            startDate={startDate}
            endDate={endDate}
            query={Api.hoiAnalytics}
            queryKey="hoiAnalytics"
          />
        ))}
      </Grid>
    </Box>
  );
}

export default Analytics;
