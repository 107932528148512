import { Box, Grid } from "@material-ui/core";
import React from "react";
import { Api } from "../../Api";
import Form from "../../components/forms/Form"

const fields = {
  searchTerm: {
      value: "",
      label: "Search",
      helperText:"E.g. Dentist, Gym, Restaurant",
      required: true,
      name: "searchTerm",
    },
    location: {
      value: "",
      label: "Location",
      required: true,
      name: "email",
      helperText:""
    },
}

function Leads() {
  return (
    <Box
      sx={{
        p: 3
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:"column",
          justifyContent: "center"
        }}>
        <Form fields={fields} actions={[
        {
          mutation: {
            successMessage: "Search Saved",
            query: Api.leads.search,
            updateValue: (data)=>{},
            dataKey: "updateImage",
            buttonProps: {
              type: "submit",
              fullWidth: true,
              // boxProps: { md: 2 },
              disabledTooltip: {},
              children: "Search",
            },
          },
        },
      ]}></Form>
      </Box>
      <Grid container>Campaigns</Grid>
    </Box>
  );
}

export default Leads;
