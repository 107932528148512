import { useLazyQuery } from "@apollo/client";
import React from "react";
import Button from "../Button";
import { isFunction } from "../utilities/utils";

function LazyQueryLoading({
  buttonProps,
  updateValue,
  variables,
  query,
  dataKey,
}) {
  const [loadQuery, { called, loading, data }] = useLazyQuery(query, {
    variables: isFunction(variables) ? variables() : variables || {},
  });
  React.useEffect(() => {
    called &&
      loading === false &&
      data?.[dataKey] &&
      updateValue(data?.[dataKey]);
  }, [data, loading, called, dataKey, updateValue]);
  return (
    buttonProps && (
      <Button
        loading={loading}
        onClick={(e) =>
          called && !loading ? updateValue(data?.[dataKey]) : loadQuery()
        }
        {...buttonProps}
      />
    )
  );
}

export default LazyQueryLoading;
