import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import CourseDetail from "./CourseDetail";
import CourseSections from "./CourseSections";
import CourseUsers from "./CourseUsers";

import { AppBar, Tabs, Tab, Box, useTheme } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: 1,
            px: 3,
          }}
        >
          {/* <Typography></Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const sectionUrls = ["", "users/", "sections/", "statistics/"];
const sections = {
  users: 1,
  sections: 2,
  statistics: 3,
};
export default function ScrollableTabsButtonForce({
  match,
  course,
  setCourseTitle,
}) {
  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      //   flexGrow: 1,
      //   width: "100%",
      margin: "10px auto",
      marginBottom: 30,
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const section = match.params.section;
  const [value, setValue] = React.useState(
    sections.hasOwnProperty(section) ? sections[section] : 0
  );
  React.useEffect(() => {
    setCourseTitle && setCourseTitle(course.title);
    if (section) {
      !sections.hasOwnProperty(section) &&
        history.push(`/admin/courses/${match.params.slug}/}`);
      // ? setValue(sections[section])
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(
      `/admin/courses/${match.params.slug}/${sectionUrls[newValue]}`
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            // variant="scrollable"
            // scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label=""
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Users" {...a11yProps(1)} />
            <Tab label="Sections" {...a11yProps(2)} />
            <Tab label="Statistics" {...a11yProps(3)} />
            {/* icon={<PersonPinIcon />}  */}
          </Tabs>
        </AppBar>
      </div>
      <div>
        <TabPanel value={value} index={0}>
          <CourseDetail course={course} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CourseUsers course={course} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CourseSections course={course} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          Statistics
        </TabPanel>
      </div>
    </div>
  );
}
