import { Paper, Box } from "@material-ui/core";
import React from "react";
import FormField from "../../forms/FormField";
import GradientButton from "../../GradientButton";
import { useMutation } from "@apollo/client";
import { Api, TypeIcon } from "../../../Api";
import IndeterminateCheckBoxRoundedIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import PropTypes from "prop-types";
import { LinearProgress, Typography } from "@material-ui/core";

const LinearProgressWithLabel = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}>
      <Box
        sx={{
          width: "100%",
          mr: 1
        }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box
        sx={{
          minWidth: 35
        }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function EditUserForm({
  courseUser,
  setCourseUser,
  user,
  updateCourseUserData,
  courseActivityData,
  courseActivityLoading,
  courseActivityError,
  course,
}) {
  const [loading, setLoading] = React.useState(false);
  const [updateUserMutation] = useMutation(Api.updateCourseUser);
  const genFields = () => ({
    courseUserId: {
      value: courseUser.id,
      readOnly: true,
      int: true,
      label: "Course User ID",
    },
    email: {
      value: courseUser.user.email,
      readOnly: true,
      int: true,
      label: "Email",
      comp: courseUser.user.email,
    },
    role: {
      type: "select",
      value: courseUser.role.toLowerCase(),
      comp: courseUser.role.toLowerCase,
      error: "",
      options: [
        {
          value: "student",
          text: "Student",
        },
        {
          value: "teacher",
          text: "Teacher",
        },
      ],
      label: "Type",
      required: true,
    },
    activityUnlock: {
      type: "select",
      value: courseUser.unlockedTrack?.activity.id,
      comp: courseUser.unlockedTrack?.activity.id,
      error: "",
      label: "Current Activity",
      required: true,
      options: courseActivityData
        ? courseActivityData.courseActivities.map((item, i) => ({
            value: item.id,
            text: (
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: "4px"
                }}>
                <TypeIcon type={item.activityType} color="secondary" />
                <Box
                  component="span"
                  sx={{
                    ml: 2,
                    mr: 1
                  }}>
                  {item.section.order}.{item.order} - {item.title}
                </Box>
                {item.id === courseUser.unlockedTrack?.activity.id ? (
                  <Box
                    component={IndeterminateCheckBoxRoundedIcon}
                    sx={{
                      color: "primary.main",
                      fontSize: 18
                    }} />
                ) : (
                  ""
                )}
              </Box>
            ),
          }))
        : [],
      loading: courseActivityLoading,
    },
    completePrevious: {
      type: "checkbox",
      value: true,
      label: "Mark all previous as Completed",
      helperTrue: "All previous activities will be marked as completed",
      helperFalse:
        "Completed status of previous activities will not be modified",
    },
    unCompleteNext: {
      type: "checkbox",
      value: false,
      label: "Mark all After as not Completed",
      helperTrue:
        "All activities after the selected one will be set to uncompleted",
      helperFalse:
        "Completed status of all activities after the selected one will not be modified",
    },
  });
  const [fields, setFields] = React.useState({});
  React.useEffect(() => {
    setFields(genFields());
  }, [courseUser]);
  const updateField = (key, data) => {
    const fs = { ...fields };
    fs[key] = { ...fs[key], ...data };
    setFields(fs);
  };
  const updateUser = () => {
    setLoading(true);
    const mutVars = {};
    Object.keys(fields).map((item) => {
      if (fields[item]?.comp && fields[item].comp === fields[item].value) {
        return null;
      }
      mutVars[item] = fields[item].value;
      return null;
    });
    updateUserMutation({ variables: mutVars })
      .then(({ data }) => {
        setLoading(false);
        const u = data.updateCourseUser.courseUser;
        updateCourseUserData((old) => {
          let up = [...old.courseUsers];
          for (var i = 0; i < up.length; i++) {
            if (up[i].id === u.id) {
              up[i] = {
                ...up[i],
                ...u,
              };
              setCourseUser(up[i]);
              break;
            }
          }
          return { courseUsers: up };
        });
      })
      .catch((e) => {
        setLoading(false);
        Api.errorAlert(e);
      });
  };
  return (
    <Box
      sx={{
        width: "40vw"
      }}>
      <Paper elevation={1}>
        <Box
          sx={{
            px: 2,
            py: 1
          }}>
          <Typography variant="h6">Edit User</Typography>
        </Box>
      </Paper>
      <Box
        sx={{
          p: 2
        }}>
        <Box
          sx={{
            mb: 3
          }}>
          <Typography variant="label">Completed</Typography>

          <LinearProgressWithLabel
            value={(courseUser.activityComplete / course.activityCount) * 100}
          />
        </Box>
        {/* <TextField value />
      {courseUser && courseUser.id} */}

        {Object.keys(fields).map((item, i) => {
          const field = fields[item];
          return (
            <Box
              sx={{
                mb: 4
              }}>
              <FormField
                key={i}
                loading={loading}
                updateField={updateField}
                id={item}
                {...field}
              />
            </Box>
          );
        })}
        <Box
          sx={{
            mt: 4
          }}>
          <GradientButton onClick={updateUser} fullWidth loading={loading}>
            Update User
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
}

export default EditUserForm;
