import { Grid } from "@material-ui/core";
import React from "react";
import { Api } from "../../../Api";
import ModalForm from "../../gql/ModalForm";

const UpdateDisplay = ({ data: displayData, display, refetch, updateData }) => {
  const genFields = {
    displayName: {
      value: display?.name || "",
      label: "Name",
      required: true,
    },
    previewImage: {
      type: "file",
      label: (
        <>
          Preview Image <small>(Defaults to Config Preview)</small>
        </>
      ),
      // acceptedFiles: ["jpeg", "jpg", "png"],
      // accept: " /*",
      value: null,
      required: false,
      displayPreview: {
        name: display?.previewImageFileName,
        url: display?.previewImage && Api.formatMediaUrl(display.previewImage),
      },
    },
  };
  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ModalForm
          genFields={genFields}
          modalTitle="Create Tour"
          label="create-tour"
          // modalActions
          buttonProps={{
            color: "secondary",
            children: "Create Tour",
            fluid: true,
          }}
          modalActionProps={{
            buttonProps: {
              children: "Create",
            },
            query: Api.estatelens.updateDisplays,
            successMessage: "Tour Created Successfully",
            updateValue: (data) => updateData(data?.display?.id, data?.display),
            dismissOnSubmit: true,
            dataKey: "updateDisplay",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateDisplay;
