import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  CircularProgress,
  Button,
  Tooltip,
  Box,
  IconButton,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";

const defaultDisabledTooltip = "Please Fill in required fields";

const BtnWrap = ({ iconButton, className, ...props }) =>
  iconButton ? (
    <IconButton variant="outlined" {...props} />
  ) : (
    <Button className={className} {...props} />
  );

function GradientButton({
  onClick,
  loading,
  children,
  boxProps,
  disabled,
  fullWidth,
  fluid,
  color,
  tooltip: tp,
  disabledTooltip: dtp,
  toLink,
  linkProps,
  ...btnProps
}) {
  const disabledTooltip = dtp
    ? {
        title: loading ? "Loading..." : defaultDisabledTooltip,
        ...dtp,
      }
    : dtp;
  const tooltip = disabled || loading ? disabledTooltip ?? tp : tp;
  const useStyles = makeStyles({
    buttonFinal: {
      // height: 35,
      width: "100%",

      "& > span": { filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))" },

      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    btnWrapper: {
      flex: fullWidth ? 1 : "none",
      maxWidth: fullWidth ? "100%" : !fluid ? 120 : "auto",
      width: fullWidth ? "100%" : "auto",
      maxHeight: fluid ? 35 : "auto",
      position: "relative",
    },
  });
  const classes = useStyles();
  const d = color ? { color: color } : { className: classes.buttonFinal };
  const { sx, ...bProps } = btnProps;
  return (
    <LinkWrap to={toLink} {...linkProps}>
      <ToolTipWrap tooltip={tooltip}>
        <Box className={classes.btnWrapper} {...boxProps}>
          <BtnWrap
            variant="contained"
            onClick={onClick}
            disabled={disabled || loading}
            type="submit"
            {...d}
            {...bProps}
            sx={{ ...sx }}
          >
            <span>{children}</span>
          </BtnWrap>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
      </ToolTipWrap>
    </LinkWrap>
  );
}

const LinkWrap = ({ component: R, to, children, ...props }) =>
  to ? (
    <Box
      sx={{ textDecoration: "none" }}
      to={to}
      component={R ?? Link}
      {...props}
    >
      {children}
    </Box>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

const ToolTipWrap = ({ tooltip, children }) =>
  tooltip ? (
    <Tooltip placement="top" arrow {...tooltip}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default GradientButton;
