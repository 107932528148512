import { useQuery } from "@apollo/client";
import React from "react";
import ModalForm from "../../gql/ModalForm";
import { Api } from "../../../Api";

function UpdatePayout({
  row: payout,
  refetch,
  updateData,
  markPaidOut,
  cancelPayout,
}) {
  const {
    data: payoutMethodData,
    loading: payoutDataLoading,
    // error: payoutDataError,
    // updateQuery: updatePayoutMethodsData,
  } = useQuery(Api.hoi.getPayoutMethods);
  // const genTriggerFields = {
  //   name: {
  //     value: "",
  //     label: "Name",
  //     required: true,
  //   },
  // };
  const genFields = markPaidOut
    ? {
        payoutId: {
          value: payout.id,
          label: "Payout ID",
          readOnly: true,
          hide: true,
        },
        markPaidOut: {
          type: "checkbox",
          value: true,
          readOnly: true,
          label: "Mark as Paid Out",
          hide: true,
          // helperTrue: "Assign to HalalOnlineIncome",
        },
        payoutMethod: {
          type: "select",
          value: "",
          error: "",
          label: "Payout Method",
          required: true,
          options: payoutMethodData?.hoiPayoutMethods
            ? [
                { value: "", text: "---" },
                ...payoutMethodData.hoiPayoutMethods.map((item, i) => ({
                  value: item.id,
                  text: item.name,
                })),
              ]
            : [],
          loading: payoutDataLoading,
        },
        amount: {
          value: `${payout.payoutAmount}`,
          label: "Payout Amount",
          required: true,
        },
        transferReference: {
          value: `HOI - PAYOUT #${payout.id}`,
          label: "Bank Transfer Reference",
          required: true,
        },
      }
    : cancelPayout && {
        payoutId: {
          value: payout.id,
          label: "Payout ID",
          readOnly: true,
        },
        cancelPayout: {
          type: "checkbox",
          value: true,
          readOnly: true,
          label: "Cancel Payout",
          hide: true,
          // helperTrue: "Assign to HalalOnlineIncome",
        },
        cancelReason: {
          value: "",
          label: "Cancel Reason",
          required: true,
          multiline: true,
          rows: 3,
        },
      };
  return (
    <ModalForm
      genFields={genFields}
      label="create-payout"
      modalTitle={
        markPaidOut
          ? `Make Payout #${payout.id}`
          : cancelPayout && `Cancel Payout #${payout.id}`
      }
      boxProps={{ ml: "auto", pb: 2, mr: cancelPayout ? 2 : 0 }}
      // modalActions
      buttonProps={{
        color: cancelPayout && "secondary",
        ml: "auto",
        children: markPaidOut ? "Payout" : cancelPayout && "Cancel",
        fluid: true,
      }}
      modalActionProps={{
        buttonProps: {
          children: "Update",
        },
        query: Api.hoi.updatePayout,
        successMessage: "Payout Updated Successfully",
        updateValue: ({ hoiPayout }) => updateData(payout.id, hoiPayout),
        dismissOnSubmit: true,
        dataKey: "updatePayout",
      }}
    />
  );
}

export default UpdatePayout;
