import React from "react";
import { useLazyQuery } from "@apollo/client";
import { Api, TypeIcon } from "../../Api";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../reducer";
import ContainerLoader from "../../components/ContainerLoader";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import Manage from "../../components/course/Manage";
import {
  Paper,
  Grid,
  Button,
  Typography,
  ButtonGroup,
  IconButton,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import View from "../../components/course/View";
import MainSideToggle from "../../components/navbar/MainSideToggle";

const useStyles = makeStyles((theme) => ({
  nav: {
    zIndex: 500,
    position: "relative",
  },
  navLink: {
    color: "grey",
    transition: "all .3s",
    padding: "18px 5px",
    margin: "0 5px",
    "&.active": {
      color: "black",
      fontWeight: 600,
      borderBottom: "3px solid lightGreen",
      height: "100%",
    },
  },

  activityItems: {
    width: 300,
    zIndex: 200,
    position: "relative",
  },
  activityItem: {
    // borderBottom: "1px solid lightGrey",
  },
  order: {
    fontWeight: 600,
  },
  gridStart: {
    alignItems: "center",
  },
  gridEnd: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  lastSectionItem: {
    borderBottom: "1px solid grey",
  },
}));

function CourseView({ match }) {
  const [{ courseUsers }] = useStateValue();
  const history = useHistory();
  const classes = useStyles();
  const [courseUser, setCourseUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [getTracks, { loading: trackLoading, data: trackData, updateQuery }] =
    useLazyQuery(Api.getCourseUserTrack);
  React.useEffect(() => {
    if (courseUser?.course?.slug !== match.params.slug) {
      const cu = courseUsers.filter((e) => e.course.slug === match.params.slug);
      if (cu.length > 0) {
        setCourseUser(cu[0]);
        getTracks({ variables: { courseId: cu[0].course.id } });
        if (
          !match.params?.type ||
          match.params.type === "view" ||
          match.params.type === "manage"
        ) {
          setLoading(false);
        }
      } else {
        history.push("/dashboard/courses/");
      }
    }
  }, [match.params.slug]);
  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        overflow: "hidden"
      }}>
      <ContainerLoader loading={loading} />
      <Paper elevation={2} className={classes.nav}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            pr: 3
          }}>
          <Box
            sx={{
              color: "secondary",
              display: "flex",
              alignItems: "center"
            }}>
            <Link to="/dashboard/courses/">
              <IconButton
                // onClick={() => setLoading(true)}
                color="secondary"
                // size="small"
              >
                <ArrowBackRoundedIcon />
              </IconButton>
            </Link>
            {/* <Button variant="outlined">Courses</Button> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              pr: 1,
              alignItems: "center"
            }}>
            <MainSideToggle />
          </Box>
          {/* <NavLink
            className={classes.navLink}
            to={`/dashboard/courses/${match.params.slug}/`}
            exact
          >
            Overview
          </NavLink> */}
          <NavLink
            className={classes.navLink}
            to={`/dashboard/courses/${match.params.slug}/view/`}
          >
            <Box>Access</Box>
          </NavLink>
          {courseUser && courseUser.role.toLowerCase() === "teacher" && (
            <NavLink
              className={classes.navLink}
              to={`/dashboard/courses/${match.params.slug}/manage/`}
            >
              Manage
            </NavLink>
          )}
          <Box
            sx={{
              zIndex: -1,
              position: "absolute",
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              top: 0,
              left: 0
            }}>
            <Typography>{courseUser && courseUser.course.title}</Typography>
          </Box>
        </Box>
      </Paper>
      {courseUser && trackData ? (
        <Switch>
          {courseUser.role.toLowerCase() === "teacher" && (
            <Route path={`/dashboard/courses/${match.params.slug}/manage`}>
              <Manage
                classes={classes}
                setLoading={setLoading}
                courseUser={courseUser}
              />
            </Route>
          )}
          <Route
            path={`/dashboard/courses/${match.params.slug}/view/:sectionId/:activityId`}
            render={({ match }) => (
              <View
                classes={classes}
                setLoading={setLoading}
                courseUser={courseUser}
                match={match}
                trackLoading={trackLoading}
                trackData={trackData}
                updateQuery={updateQuery}
              />
            )}
          />
          <Route path={`/dashboard/courses/${match.params.slug}/view`}>
            <Redirect to={`/dashboard/courses/${match.params.slug}/view/1/1`} />
          </Route>
          {/* <Route path={`/dashboard/courses/${match.params.slug}/`} exact>
          <Typography>Overview</Typography>
        </Route> */}
          <Route>
            <Redirect to={`/dashboard/courses/${match.params.slug}/view`} />
          </Route>
        </Switch>
      ) : (
        <ContainerLoader loading={loading} />
      )}
    </Box>
  );
}

export default CourseView;
