import React from "react";
import { Typography, TextField, Grid, Button, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/core";
function ExamDisplay({
  examSubmitData,
  setExamSubmitData,
  examReview,
  setExamReview,
  retryExam,
  updateExam,
  marking,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      {examSubmitData && examSubmitData.submitted && examReview === false ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" component="span">
            <Box
              sx={{
                color: "success.main",
              }}
            >
              Attempt Submitted Successfully!
            </Box>
          </Typography>
          <Grid item md={8}>
            <Box
              sx={{
                my: 3,
              }}
            >
              <Box
                sx={{
                  mb: 3,
                }}
              >
                <Typography variant="h6" align="center">
                  <Box
                    component="span"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    Submitted on:{" "}
                    {examSubmitData.submitted
                      ? new Date(
                          Date.parse(examSubmitData.submitted)
                        ).toLocaleString()
                      : "N/A"}
                  </Box>
                </Typography>
              </Box>
              <Typography variant="h5" align="center">
                <Box
                  component="span"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  We'll let you know once it has been marked!
                </Box>
              </Typography>
            </Box>
            <Typography align="center">
              <Box
                component="span"
                sx={{
                  color: "text.secondary",
                }}
              >
                Until then feel free to revise over the previous topics and
                activities, you can re-watch the videos and re-read the texts as
                many times as you want.
              </Box>
            </Typography>
          </Grid>
          {!marking && examSubmitData.passed === false ? (
            <Button onClick={retryExam}>Retry</Button>
          ) : (
            !marking &&
            examSubmitData.marked && (
              <Button
                onClick={() => {
                  setExamReview(true);
                }}
              >
                Review
              </Button>
            )
          )}
        </Box>
      ) : examSubmitData?.answers && examSubmitData.answers.length > 0 ? (
        examSubmitData.answers.map(
          ({ question, values, error, success, valid }, i) => {
            return (
              <Box
                key={i}
                sx={{
                  mb: 3,
                  mt: 2,
                  width: "100%",
                }}
              >
                {/* <Typography variant="label">
                    {question.num}. {question.question}
                  </Typography> */}
                {question.type === "shortText" ||
                question.type === "longText" ? (
                  <TextField
                    label={`${question.num}. ${question.question}`}
                    readOnly={!!marking}
                    variant={
                      question.type === "shortText" ? "standard" : "outlined"
                    }
                    value={values[0]}
                    onChange={(e) => {
                      const dat = { ...examSubmitData };
                      dat.answers[i].values[0] = e.target.value;
                      if (e.target.value.trim() === "") {
                        dat.answers[i].error = "Please answer this question!";
                        dat.answers[i].valid = false;
                      } else {
                        dat.answers[i].error = "";
                        dat.answers[i].valid = true;
                      }
                      setExamSubmitData(dat);
                    }}
                    onBlur={() => {
                      updateExam();
                    }}
                    error={error !== ""}
                    multiline={question.type === "longText"}
                    rows={6}
                    fullWidth
                    // margin="dense"
                    inputProps={
                      question.maxLength > 0 && {
                        maxLength: question.maxLength,
                      }
                    }
                    helperText={
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        {error === "" ? "" : error}
                        <Box
                          component="span"
                          sx={{
                            marginLeft: "auto",
                          }}
                        >
                          {question.maxLength > 0
                            ? `${values[0] ? values[0].trim().length : 0}/${
                                question.maxLength
                              } Characters`
                            : "No Limit"}
                        </Box>
                      </Box>
                    }
                  />
                ) : (
                  <Box>{question.type}</Box>
                )}
              </Box>
            );
          }
        )
      ) : (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          An error has occurred! Please contact us if the error persists.
        </Alert>
      )}
    </Box>
  );
}

export default ExamDisplay;
