import React from "react";
import { Api } from "../../Api";
import { useMutation } from "@apollo/client";

import {
  Grid,
  Box,
  FormHelperText,
  FormControl,
  Input,
  InputLabel,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import { spacing } from "../utilities/utils";

const useStyles = makeStyles((theme) => ({
  inputRoot: { width: "100%" },
  label: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
  },
  input: {
    fontSize: 16,
  },
  margin: {
    marginTop: 5,
    marginRight: 15,
  },
  add_title: {
    margin: 0,
  },
  buttonFinal: {
    height: 35,
    marginTop: 15,
    width: "100%",
    "& > span": {
      filter: "drop-shadow(2px 4px 2px rgba(0,0,0,0.5))",
    },
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  instructions: {
    marginTop: spacing(theme, 1),
    marginBottom: spacing(theme, 1),
  },
  btnWrapper: {
    flex: 1,
    maxWidth: 200,
    position: "relative",
    marginLeft: 10,
  },
}));

const AddCourseSection = ({ course, refetch, section }) => {
  const classes = useStyles();
  const [sectionName, setSectionName] = React.useState(
    section ? section.title : ""
  );
  const [sectionNameError, setSectionNameError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [mutateAddSection] = useMutation(Api.updateCourseSection);
  const [order] = React.useState();
  // ,setOrder
  const inputRef = React.useRef(null);

  const addSection = (event, courseDelete = false) => {
    event.preventDefault();
    setLoading(true);
    if (sectionName === "") {
      setSectionNameError("Please enter a section name");
      inputRef.current.focus();
      setLoading(false);
    } else {
      setSectionNameError("");
      mutateAddSection({
        variables: {
          courseId: course.id,
          sectionId: section ? section.id : null,
          delete: courseDelete,
          title: sectionName,
          order: order,
        },
      })
        .then(({ data }) => {
          console.log(data);
          const token = data["action"]["courseSection"];
          if (token == null) {
            alert("An error has occured, Please try again!");
          } else {
            setLoading(false);
            setSectionName("");
          }
          refetch();
        })
        .catch((e) => {
          alert("An error has occured, Please try again!");
          console.log(e);
        });
    }
  };
  return (
    <form onSubmit={addSection}>
      <Grid container>
        <Grid item sm={8}>
          <Grid container alignItems="center" direction="column">
            {/* <Box componenet="span" ml={3}></Box> */}
            <FormControl
              className={classes.inputRoot}
              error={sectionNameError !== ""}
            >
              <InputLabel
                htmlFor="create-course-title"
                className={classes.label}
              >
                Section Name
              </InputLabel>
              <Box
                sx={{
                  my: 1
                }} />
              <Input
                inputRef={inputRef}
                className={classes.input}
                id="create-section-name"
                disabled={loading}
                value={sectionName}
                onChange={(event) => {
                  const val = event.target.value;
                  val.trim() === ""
                    ? setSectionNameError("Please enter a section name")
                    : setSectionNameError("");
                  setSectionName(val);
                }}
                aria-describedby="create-section-name"
              />
              <FormHelperText>{sectionNameError}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            color="primary"
            // onClick={addSection}
            disabled={loading}
            className={classes.buttonFinal}
            type="submit"
          >
            {section ? "Update" : "Create"}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    </form>
  );
};

export default AddCourseSection;
