const prod = {
  url: {
    API_URL: "https://api.marketceptionacademy.com",
  },
  storageItemKeys: {
    refreshToken: "gregwertyg45gh9j4hj56wh",
    token: "llh54h94589jh90jjhtregse",
    keepMeLoggedIn: "h54kwr890hj84mroetih",
  },
  dev: false,
};
const dev = {
  url: {
    // API_URL: 'http://localhost:8000'
    API_URL: "http://private.forwardsafe.co.uk:8062",
    // API_URL: "http://192.168.1.100:8000",
  },
  storageItemKeys: {
    refreshToken: "refreshToken",
    token: "token",
    keepMeLoggedIn: "kml",
  },
  dev: true,
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
