import React from "react";
import "./PageLoader.css";
import { CircularProgress } from '@material-ui/core';
function PageLoader() {
  return (
    <div className="page-loader">
      <CircularProgress />
    </div>
  );
}

export default PageLoader;
