import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Api } from "../../Api";
import { Link } from "react-router-dom";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
  useTheme,
} from "@material-ui/core";

// const progPlaceTim = ;

function CourseTile({ course, url, progress }) {
  // const progress = 37;
  // const [actProg, setProgress] = React.useState(0);
  // React.useEffect(() => {
  //   if (actProg === 100) {
  //     console.log(actProg);
  //     setTimeout(() => setProgress(progress), progPlaceTim);
  //   }
  // }, [actProg]);
  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      // maxWidth: 345,
      // width: "100%",
      margin: "0 15px",
      boxShadow: "-5px 5px 15px rgba(0,0,0,0.6)",
      borderRadius: 10,
    },
    text: {
      padding: 10,
    },
    loader: {},
    progressPlaceHolder: {
      // color: "grey",
      // color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      color: theme.palette.grey[700],
    },
    progressParent: {
      filter: "drop-shadow(2px 2px 2px #000)",
      backgroundColor: "rgba(0,0,0,.5)",
    },
    progressText: {
      color: "white",
      fontSize: 20,
      fontWeight: 600,
    },
    progressMain: {
      zIndex: 100,
      // "& circle ": {
      //   transition: `stroke-dashoffset ${progPlaceTim}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      // },
    },
    progress: {
      display: "flex",
      "& > svg": {
        height: "100%",
        width: "100%",
      },
    },
  });
  const classes = useStyles();
  const CircularProgressWithLabel = (props) => (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <CircularProgress
        color="primary"
        variant="determinate"
        {...props}
        className={`${classes.progressMain} ${classes.progress}`}
      />
      <Box
        sx={{
          p: 2,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "secondary",
        }}
      >
        <CircularProgress
          {...props}
          variant="determinate"
          // color={}
          className={`${classes.progress} ${classes.progressPlaceHolder}`}
          value={100}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.progressText}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
  return (
    <Link to={url}>
      <Card className={classes.root}>
        <CardActionArea>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            <CardMedia
              // onLoad={() => setProgress(100)}
              component="img"
              height="140"
              image={Api.formatMediaUrl(course.thumbnail)}
              title={course.title}
            />
            {progress != null && (
              <Box
                className={classes.progressParent}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                <CircularProgressWithLabel
                  size={"100%"}
                  thickness={2}
                  value={progress}
                />
              </Box>
            )}
          </Box>
          <CardContent className={classes.text}>
            <Typography gutterBottom variant="h5" component="h2">
              {course.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {course.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </Link>
  );
}

export default CourseTile;
