import { gql } from "@apollo/client";

const hotspotDat = `id
toImage{
    id
    name
}
toLink
tooltip
yaw
targetYaw
targetPitch
pitch`;

const imageDat = `
    id
    name
    description
    yaw
    pitch
    hotspots{
      ${hotspotDat}
    }
    image
    imageFileName
    multiresMediaPath
    multiresConfig
    order
`;
const displayDat = `
id
name
config{
  hfov
  haov
vaov
vOffset
yaw
minYaw
maxYaw
pitch
minPitch
maxPitch
minHfov
maxHfov
autoRotate
compass
showControls
previewImage
previewTitle
previewAuthor
author
title
}
author
title
previewImage
previewImageFileName
previewTitle
previewAuthor
images{
  ${imageDat}
}
`;
const GET_DISPLAYS = gql`
  {
    displays {
    ${displayDat}
    }
  }
`;

const UPDATE_DISPLAY = gql`
mutation updateDisplay($displayId:ID,$displayName:String
  $previewAuthor: String
  $previewImage: Upload
  $previewTitle: String){
    updateDisplay(displayId:$displayId,displayName:$displayName
      previewAuthor:$previewAuthor
previewImage:$previewImage
previewTitle:$previewTitle){
        display{
            ${displayDat}
        }
    }
}`;

const UPDATE_IMAGE = gql`
mutation updateImage(
$delete: Boolean
$description: String
$displayId: ID
$imageFile: Upload
$imageId: ID
$imageName: String
$pitch:Int
$yaw:Int
$order: Int
$imageFiles:Upload
){
    updateImage(
      delete: $delete
description: $description
displayId: $displayId
imageFile: $imageFile
imageId: $imageId
imageName: $imageName
order: $order
pitch:$pitch
imageFiles:$imageFiles
yaw:$yaw
    ){
      newImages{
        ${imageDat}
      }
        image{
          ${imageDat}
        }
        images{
          id
          order
        }
    }
}`;

const UPDATE_IMAGE_HOTSPOT = gql`
mutation updateImageHotspot(
$delete: Boolean
$hotspotId: ID
$imageId: ID
$order: Int
$pitch: Int
$toImageId: ID
$toLink: String
$tooltip: String
$yaw: Int
$targetPitch: Int
$targetYaw: Int
){
    updateImageHotspot(
      delete:$delete
      hotspotId:$hotspotId
      imageId:$imageId
      order:$order
      pitch:$pitch
      toImageId:$toImageId
      toLink:$toLink
      tooltip:$tooltip
      yaw:$yaw
      targetPitch:$targetPitch
      targetYaw:$targetYaw
    ){
        hotspot{
          ${hotspotDat}
        }
    }
}`;

const GET_RAW_HTML = gql`
  query ($id: ID!) {
    getDisplayHtml(id: $id) {
      html
    }
  }
`;

const GET_PREVIEW_LINK = gql`
  query ($id: ID!) {
    getDisplayPreviewLink(id: $id) {
      link
    }
  }
`;

const queries = {
  getDisplays: GET_DISPLAYS,
  updateDisplays: UPDATE_DISPLAY,
  updateImage: UPDATE_IMAGE,
  updateImageHotspot: UPDATE_IMAGE_HOTSPOT,
  getRawHtml: GET_RAW_HTML,
  getPreviewLink:GET_PREVIEW_LINK
};
export default queries;
