import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import React from "react";
import ModalForm from "../../gql/ModalForm";
import { Api } from "../../../Api";

function UpdateEmail({ data: emailData, refetch, updateData }) {
  const {
    data: emailTriggerData,
    loading: emailTriggerLoading,
    // error: emailTriggerError,
    // updateQuery: updateEmailTriggers,
  } = useQuery(Api.getEmailTriggers, { variables: { hoi: true } });
  // const genTriggerFields = {
  //   name: {
  //     value: "",
  //     label: "Name",
  //     required: true,
  //   },
  // };
  const genFields = {
    name: {
      value: "",
      label: "Name",
      required: true,
    },
    hoi: {
      type: "checkbox",
      value: true,
      readOnly: true,
      label: "HalalOnlineIncome",
      helperTrue: "Assign to HalalOnlineIncome",
    },
    triggerComplete: {
      type: "checkbox",
      value: false,
      label: "Mark Webinar User emails as complete",
      helperTrue:
        "The user will no longer be checked for pending emails after this one has been sent",
      helperFalse:
        "The user will be checked for pending emails after this one has been sent",
    },
    triggerId: {
      type: "select",
      value: "",
      error: "",
      label: "Trigger Event",
      required: false,
      options: emailTriggerData
        ? [
            { value: "", text: "---" },
            ...emailTriggerData.emailTriggers.map((item, i) => ({
              value: item.id,
              text: item.title,
            })),
          ]
        : [],
      loading: emailTriggerLoading,
    },
    time: {
      value: "0",
      label: "Time from Trigger Event (seconds)",
      required: true,
      default: 0,
      int: true,
    },
  };
  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ModalForm
          genFields={genFields}
          modalTitle="Create Email"
          label="create-email"
          // modalActions
          buttonProps={{
            color: "secondary",
            ml: "auto",
            children: "Create Email",
            fluid:true
          }}
          modalActionProps={{
            buttonProps: {
              children: "Create",
            },
            query: Api.updateEmail,
            successMessage: "Email Created Successfully",
            updateValue: (data) => updateData(data?.email?.id, data?.email),
            dismissOnSubmit: true,
            dataKey: "updateEmail",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default UpdateEmail;
