import React from "react";
// import { useStateValue } from "../../StateProvider";
import { Api } from "../../Api";
import { useQuery } from "@apollo/client";
import { Alert,Paper, Box } from "@material-ui/core";
import useWindowDimensions from "../utilities/useWindowDimensions";
import DashBoardSideNav from "../dashboard/DashBoardSideNav";
import { Route, Switch, Redirect } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { formatDate } from "../utilities/utils";

const sortModel = [
  {
    field: "submitted",
    sort: "desc",
  },
];
const columns = [
  {
    field: "fullName",
    headerName: "Name",
    width: 160,
    valueGetter: (params) =>
      `${params.row.user.user.firstName || ""} ${
        params.row.user.user.lastName || ""
      }`,
  },
  {
    field: "activity",
    type: "string",
    headerName: "Activity",
    width: 200,
    valueGetter: (params) =>
      `${params.row.section.order}.${params.row.activity.order} - ${params.row.activity.title}`,
  },
  {
    field: "Email",
    type: "string",
    headerName: "Email",
    width: 300,
    valueGetter: (params) => params.row.user.user.email || "",
  },
  {
    field: "submitted",
    type: "dateTime",
    headerName: "Submitted",
    width: 200,
    valueGetter: (params) =>
      params.row.submitted
        ? formatDate(params.row.submitted)
        : params.row.submitted,
  },
  // {
  //   field: "Email",
  //   type: "string",
  //   headerName: "Email",
  //   width: 150,
  //   valueGetter: (params) =>
  //     `${params.row.user.user.firstName || ""} ${
  //       params.row.user.user.lastName || ""
  //     }`,
  // },
];
function Manage({ courseUser, classes }) {
  // const [dispatch, {courseUsers}] = useStateValue();
  const { data, loading, error } = useQuery(Api.getCourseUserExams, {
    variables: {
      examFilter: true,
      courseId: courseUser.course.id,
    },
  });
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState(false);
  const windowSize = useWindowDimensions();
  const toggleDrawer = (open) => (event) => {
    if (windowSize.width <= 768) {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setMobileDrawerOpen(open);
    } else {
      setMobileDrawerOpen(false);
      setCollapse(!collapse);
    }
  };
  const rowClick = ({ data }) => {
    // data && setEditUser(data);
    // data && setEditUserOpen(true);
  };
  return (
    <Box className="dashboard">
      <DashBoardSideNav
        title={"Manage"}
        collapse={collapse}
        condense
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
        toggleDrawer={toggleDrawer}
        windowSize={windowSize}
        // titleIcon={titleIcon}
        onRowClick={rowClick}
        links={[
          {
            url: `/dashboard/courses/${courseUser.course.slug}/manage/`,
            title: "All Exams",
            routeProps: { exact: true },
            linkProps: { exact: true },
            sectionTitle: {
              id: "Exams",
            },
          },
          {
            url: `/dashboard/courses/${courseUser.course.slug}/manage/to-be-marked`,
            title: "To be Marked",
            // icon: ,
            routeProps: { exact: true },
            // component: Home,
            linkProps: { exact: true },
            completed: data?.courseUsersTracks?.length === 0,
            count: data?.courseUsersTracks && data.courseUsersTracks.length,
          },
        ]}
      />
      <Box
        sx={{
          flex: 1
        }}>
        <Paper
          square
          elevation={2}
          style={{ zIndex: 100, position: "relative" }}
        >
          <Box
            sx={{
              p: "14px",
              height: "64px"
            }}></Box>
        </Paper>
        <Box>
          <Switch>
            <Route
              path={`/dashboard/courses/${courseUser.course.slug}/manage/to-be-marked`}
            ></Route>
            <Route
              path={`/dashboard/courses/${courseUser.course.slug}/manage/to-be-marked`}
            >
              {/* {data?.courseUsersTracks &&
              data.courseUsersTracks.map((track, i) => (
                <Box>{track.user.user.email}</Box>
              ))} */}
              {!error ? (
                data?.courseUsersTracks.length === 0 ? (
                  <Box
                    sx={{
                      p: 2
                    }}>
                    <Alert severity="success">All Exams have been marked</Alert>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: 600
                    }}>
                    <DataGrid
                      loading={loading}
                      rows={data?.courseUsersTracks || []}
                      columns={columns}
                      // pageSize={10}
                      // autoHeight={true}
                      sortModel={sortModel}
                      // autoPageSize={true}
                      rowHeight={45}
                      // onRowClick={rowClick}
                      pagination
                      pageSize={25}
                    />
                  </Box>
                )
              ) : (
                <Box
                  sx={{
                    p: 2
                  }}>
                  <Alert severity="error">
                    {error
                      ? error.message
                      : "An error has occurred loading course users. Please try again!"}
                  </Alert>
                </Box>
              )}
            </Route>
            <Route>
              <Redirect
                to={`/dashboard/courses/${courseUser.course.slug}/manage/to-be-marked`}
              />
            </Route>
          </Switch>
        </Box>
      </Box>
    </Box>
  );
}

export default Manage;
