import { IconButton } from "@material-ui/core";
import React from "react";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../reducer";

function MainSideToggle({ iconProps, ...props }) {
  const [{ user }, dispatch] = useStateValue();

  return user ? (
    <IconButton
      color="primary"
      onClick={() =>
        dispatch({
          type: actionTypes.SET_MAIN_SIDE_OPEN,
          mainSideOpen: true,
        })
      }
      aria-label="open main user drawer"
      {...props}
    >
      <MenuRoundedIcon {...iconProps} />
    </IconButton>
  ) : (
    <React.Fragment />
  );
}

export default MainSideToggle;
