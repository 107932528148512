import * as React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItemButton from "@material-ui/core/ListItemButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ImageRoundedIcon from "@material-ui/icons/ImageRounded";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Button from "../../Button";
import { Api } from "../../../Api";
import { Box, IconButton, ListItem, Tooltip } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import ModalForm from "../../gql/ModalForm";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import MutationLoading from "../../gql/MutationLoading";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import BurstModeRoundedIcon from "@material-ui/icons/BurstModeRounded";
import DonutLargeRoundedIcon from "@material-ui/icons/DonutLargeRounded";
import TrackChangesRoundedIcon from "@material-ui/icons/TrackChangesRounded";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver && 'lightgrey',
  opacity:isDraggingOver && .5
});

const getYawPitchFromRef = (ref, custDict) => {
  const { pitch, yaw } = custDict || {};
  const i = ref.current.getViewer();
  if (!i) return {};
  return { [pitch || "pitch"]: i.getPitch(), [yaw || "yaw"]: i.getYaw() };
};

export default function ImageList({
  images,
  selectedId,
  setSelected,
  updateObj,
  displayRef,
}) {
  // const reorder = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };
  const {enqueueSnackbar} = useSnackbar()
  const [updateMutation] = useMutation(Api.estatelens.updateImage)
  const onDragEnd = ({destination,draggableId,source,...props}) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    if(source.index<destination.index){enqueueSnackbar("Can Only Drag Upwards",{variant:"error"});return;}
    console.log(props)
    // const{index:sourceIndex}=source
    const {index} = destination
    updateMutation({variables:{
      imageId: draggableId,
      order: index,
    }}).then(({data})=>{
      const updateValue=updateObj(false)
      updateValue(data.updateImage)
      enqueueSnackbar("Image Order Updated",{variant:"success"})

    }).catch((e)=>enqueueSnackbar(e.message,{variant:"error"}))
    // const items = reorder(
    //   this.state.items,
    //   result.source.index,
    //   result.destination.index
    // );

    // this.setState({
    //   items,
    // });
  };
  const handleClick =
    ({ image, hotspot }) =>
    (event) => {
      image && setSelected(image);
      // hotspot && setSelectedHotspot(hotspot);
    };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            sx={{
              width: "100%",
              maxHeight: 500,
              overflow: "auto",
              bgcolor: "background.paper",
            }}
            style={getListStyle(snapshot.isDraggingOver)}
            ref={provided.innerRef}
            component="nav"
            aria-labelledby="tour-image-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="tour-image-subheader"
                sx={{ boxShadow: 2 }}
              >
                Images
              </ListSubheader>
            }
          >
            {images.map((image, i) => (
              <Draggable key={image.id} draggableId={image.id} index={i}>
                {(provided, snapshot) => (
                  <Box ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}>
                    <ListItem
                      // Button
                      selected={selectedId === image.id}
                      sx={{ borderBottom: 1, borderColor: "secondary.main" }}
                        // ContainerProps={{ ref: provided.innerRef }}
                        
                      // onClick={handleClick({ image: image })}
                    >
                      <ListItemIcon
                        onClick={handleClick({ image: image })}
                        sx={{ cursor: "pointer" }}
                      >
                        {image?.image ? (
                          <Box
                            component="img"
                            sx={{ maxHeight: 25, pr: 2 }}
                            src={Api.formatMediaUrl(image.image)}
                          />
                        ) : (
                          <ImageRoundedIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ cursor: "pointer" }}
                        onClick={handleClick({ image: image })}
                        primary={image.name}
                      />
                      <UpdateHotspot
                        images={images}
                        image={image}
                        updateObj={updateObj}
                        displayRef={displayRef}
                      />
                      <ModalForm
                        genFields={{
                          imageId: {
                            hide: true,
                            value: image.id,
                          },
                          imageName: {
                            value: image.name,
                            label: "Display Name (E.g 'Kitchen')",
                            required: true,
                          },
                          imageFile: {
                            type: "file",
                            // acceptedFiles: ["jpeg", "jpg", "png"],
                            // accept: "image/*",
                            value: null,
                            required: false,
                            displayPreview: {
                              name: image.imageFileName,
                              url:
                                image.image && Api.formatMediaUrl(image.image),
                            },
                          },
                        }}
                        id={`form-image-${image.id}`}
                        modalProps={{
                          title: "Edit Image",
                          label: "edit-image",
                        }}
                        modalActionProps={{
                          buttonProps: {
                            children: "Update",
                          },
                          query: Api.estatelens.updateImage,
                          successMessage: "Image Updated Successfully",
                          updateValue: updateObj(false),
                          dismissOnSubmit: true,
                          dataKey: "updateImage",
                        }}
                        // modalActions
                        buttonProps={{
                          size: "small",
                          children: <EditRoundedIcon fontSize="small" />,
                          iconButton: true,
                          color: "primary",
                          tooltip: { title: "Edit" },
                        }}
                      />
                      <MutationLoading
                        variables={() => ({
                          imageId: image.id,
                          ...getYawPitchFromRef(displayRef),
                        })}
                        dataKey="updateImage"
                        query={Api.estatelens.updateImage}
                        updateValue={updateObj(false)}
                        successMessage="Position Updated Successfully"
                        buttonProps={{
                          iconButton: true,
                          size: "small",
                          children: <CloudUploadRoundedIcon fontSize="small" />,
                          tooltip: {
                            title: "Set as Start Position",
                          },
                          disabledTooltip: {
                            title: "",
                          },
                        }}
                      />
                      <ModalForm
                        genFields={{
                          imageId: {
                            value: image.id,
                            hide: true,
                          },
                          delete: {
                            value: true,
                            hide: true,
                          },
                        }}
                        modalProps={{
                          title: `Delete Image - ${image.name}`,
                          contentText: (
                            <>
                              <Box
                                component="img"
                                src={Api.formatMediaUrl(image?.image)}
                                sx={{
                                  maxWidth: 400,
                                  mx: "auto",
                                  display: "block",
                                  width: "100%",
                                }}
                              />
                              <Typography
                                component="span"
                                sx={{ py: 3, fontWeight: 700 }}
                                variant="h6"
                              >
                                Are you sure you want to delete this image?
                              </Typography>
                            </>
                          ),
                          dialogProps: { maxWidth: "sm" },
                          label: "delete-image",
                        }}
                        buttonProps={{
                          size: "small",
                          children: <DeleteRoundedIcon fontSize="small" />,
                          iconButton: true,
                          color: "error",
                          tooltip: { title: "Delete" },
                        }}
                        modalActionProps={{
                          buttonProps: {
                            color: "error",
                            children: "Delete",
                          },
                          query: Api.estatelens.updateImage,
                          successMessage: "Image Deleted Successfully",
                          updateValue: updateObj(true),
                          dismissOnSubmit: true,
                          dataKey: "updateImage",
                        }}
                      />
                      <IconButton
                        size="small"
                        onClick={handleClick({ image: image })}
                      >
                        {selectedId === image.id ? (
                          <ExpandLess fontSize="small" />
                        ) : (
                          <ExpandMore fontSize="small" />
                        )}
                      </IconButton>
                      </ListItem>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <Box
                        sx={{
                          pl: 4,
                          display: "flex",
                          justifyContent: "flex-end",
                          pr: 2,
                          alignItems: "center",
                        }}
                      ></Box>
                      {image.hotspots && (
                        <List
                          component="div"
                          sx={{ borderBottom: 1, borderColor: "grey.500" }}
                          disablePadding
                        >
                          {image.hotspots.length > 0 ||
                          selectedId !== image.id ? (
                            image.hotspots
                              .filter(
                                (x) =>
                                  selectedId === image.id ||
                                  (x?.toImage?.id &&
                                    x.toImage.id === selectedId)
                              )
                              .map((hotspot, i) => (
                                <ListItem
                                  key={i}
                                  dense
                                  sx={{
                                    pl: 4,
                                    borderBottom: 1,
                                    borderColor: "primary",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      cursor: "pointer",
                                      mr: 2,
                                      display: "flex",
                                      algnItems: "center",
                                    }}
                                    // onClick={handleClick({ hotspot: hotspot })}
                                  >
                                    {hotspot?.toLink ? (
                                      <InfoRoundedIcon color="primary" />
                                    ) : hotspot?.toImage ? (
                                      <ImageRoundedIcon color="secondary" />
                                    ) : (
                                      <Tooltip arrow title="No Destination">
                                        <DonutLargeRoundedIcon color="error" />
                                      </Tooltip>
                                    )}
                                  </Box>
                                  <ListItemText
                                    sx={{ cursor: "pointer" }}
                                    // onClick={handleClick({ hotspot: hotspot })}
                                    primary={
                                      hotspot.tooltip ??
                                      hotspot.toImage?.name ??
                                      "N/A"
                                    }
                                  />
                                  {selectedId === image.id ? (
                                    <>
                                      <UpdateHotspot
                                        images={images}
                                        image={image}
                                        updateObj={updateObj}
                                        displayRef={displayRef}
                                        hotspot={hotspot}
                                      />
                                      <MutationLoading
                                        variables={() => ({
                                          hotspotId: hotspot.id,
                                          ...getYawPitchFromRef(displayRef),
                                        })}
                                        dataKey="updateImageHotspot"
                                        query={
                                          Api.estatelens.updateImageHotspot
                                        }
                                        updateValue={updateObj(false, image)}
                                        successMessage="Position Updated Successfully"
                                        buttonProps={{
                                          iconButton: true,
                                          size: "small",
                                          children: (
                                            <CloudUploadRoundedIcon fontSize="small" />
                                          ),
                                          tooltip: {
                                            title: "Set as Hotspot Position",
                                          },
                                          disabledTooltip: {
                                            title: "",
                                          },
                                        }}
                                      />
                                      <ModalForm
                                        genFields={{
                                          hotspotId: {
                                            value: hotspot.id,
                                            hide: true,
                                          },
                                          delete: {
                                            value: true,
                                            hide: true,
                                          },
                                        }}
                                        modalProps={{
                                          title: `Delete Hotspot - ${
                                            hotspot.tooltip ??
                                            hotspot?.toImage?.name
                                          }`,
                                          contentText: (
                                            <Typography
                                              component="span"
                                              sx={{ py: 3, fontWeight: 700 }}
                                              variant="h6"
                                            >
                                              Are you sure you want to delete
                                              this Hotspot?
                                            </Typography>
                                          ),
                                          dialogProps: { maxWidth: "sm" },
                                          label: "delete-hotspot",
                                        }}
                                        buttonProps={{
                                          size: "small",
                                          children: (
                                            <DeleteRoundedIcon fontSize="small" />
                                          ),
                                          iconButton: true,
                                          color: "error",
                                          tooltip: { title: "Delete" },
                                        }}
                                        modalActionProps={{
                                          buttonProps: {
                                            color: "error",
                                            children: "Delete",
                                          },
                                          query:
                                            Api.estatelens.updateImageHotspot,
                                          successMessage:
                                            "Hotspot Deleted Successfully",
                                          updateValue: updateObj(true, image),
                                          dismissOnSubmit: true,
                                          dataKey: "updateImageHotspot",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <MutationLoading
                                        variables={() => ({
                                          hotspotId: hotspot.id,
                                          ...getYawPitchFromRef(displayRef, {
                                            pitch: "targetPitch",
                                            yaw: "targetYaw",
                                          }),
                                        })}
                                        dataKey="updateImageHotspot"
                                        query={
                                          Api.estatelens.updateImageHotspot
                                        }
                                        updateValue={updateObj(
                                          false,
                                          image,
                                          false
                                        )}
                                        successMessage="Target Position Updated Successfully"
                                        buttonProps={{
                                          iconButton: true,
                                          size: "small",
                                          color: "success",
                                          children: (
                                            <TrackChangesRoundedIcon fontSize="small" />
                                          ),
                                          tooltip: {
                                            title: "Set as Target Position",
                                          },
                                          disabledTooltip: {
                                            title: "",
                                          },
                                        }}
                                      />
                                      <ModalForm
                                        genFields={{
                                          hotspotId: {
                                            value: hotspot.id,
                                            hide: true,
                                          },
                                          deleteTarget: {
                                            value: true,
                                            hide: true,
                                          },
                                        }}
                                        modalProps={{
                                          title: `Delete Hotspot Target - ${
                                            hotspot.tooltip ??
                                            hotspot?.toImage?.name
                                          }`,
                                          contentText: (
                                            <Typography
                                              component="span"
                                              sx={{ py: 3, fontWeight: 700 }}
                                              variant="h6"
                                            >
                                              Are you sure you want to delete
                                              this Hotspots target? It will
                                              reset to the default start point
                                              of the target room
                                            </Typography>
                                          ),
                                          dialogProps: { maxWidth: "sm" },
                                          label: "delete-hotspot-target",
                                        }}
                                        buttonProps={{
                                          size: "small",
                                          children: (
                                            <TrackChangesRoundedIcon fontSize="small" />
                                          ),
                                          iconButton: true,
                                          color: "error",
                                          tooltip: { title: "Delete Target" },
                                          disabled: !hotspot.targetYaw,
                                          disabledTooltip:
                                            !hotspot.targetYaw && {
                                              title: (
                                                <>
                                                  Delete Target
                                                  <br />
                                                  <small>(No Target Set)</small>
                                                </>
                                              ),
                                            },
                                        }}
                                        modalActionProps={{
                                          buttonProps: {
                                            color: "error",
                                            children: "Delete",
                                          },
                                          query:
                                            Api.estatelens.updateImageHotspot,
                                          successMessage:
                                            "Hotspot Target Deleted Successfully",
                                          updateValue: updateObj(
                                            false,
                                            image,
                                            false
                                          ),
                                          dismissOnSubmit: true,
                                          dataKey: "updateImageHotspot",
                                        }}
                                      />
                                    </>
                                  )}
                                </ListItem>
                              ))
                          ) : (
                            <Typography sx={{ pl: 4, py: 1 }}>
                              No Hotspots
                            </Typography>
                          )}
                        </List>
                      )}
                    </Collapse>
                    
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const UpdateHotspot = ({ images, image, updateObj, hotspot, displayRef }) => {
  const conditionalObj = hotspot
    ? { hotspotId: { value: hotspot.id, hide: true } }
    : { imageId: { hide: true, value: image.id } };
  const addHotspotFields = {
    ...conditionalObj,
    toImageId: {
      type: "select",
      label: "Image Link",
      value: hotspot?.toImage?.id || "",
      helperText: "The user is taken to this room when clicked",
      requiredOr: "toLink",
      options: [
        { value: "", text: "---" },
        ...images
          .filter(
            (i) => i?.id !== image.id
            // -&&
            // -!image.hotspots
            // .map(({ toImage }) => toImage && toImage?.id)
            // .includes(i.id)
          )
          .map((item, i) => ({
            value: item.id,
            text: item.name,
          })),
      ],
    },
    toLink: {
      label: "External Link",
      helperText: "Redirect to a url instead",
      value: hotspot?.toLink || "",
      requiredOr: "toImageId",
    },
    tooltip: {
      value: hotspot?.tooltip || "",
      label: "Title",
      helperText:
        "Leave blank to use Destination Image (Required for External link)",
      requiredDependent: "toLink",
    },
    // yaw: {
    //   int: true,
    //   helperText: "Horizontal Position in Degrees",
    //   label: "Yaw",
    //   value: hotspot?.yaw || 0,
    //   numericKeypad: true,
    // },
    // pitch: {
    //   type: "number",
    //   label: "Pitch",
    //   value: hotspot?.pitch || 0,
    //   helperText: "Vertical Position in Degrees",
    //   inputProps: {
    //     inputMode: "numeric",
    //     pattern: "[0-9]*",
    //   },
    // },
  };
  const title = hotspot ? "Edit Hotspot" : "Add Hotspot";
  return (
    <ModalForm
      genFields={addHotspotFields}
      id={`form-hotspot-${image.id}`}
      modalProps={{
        title: title,
        label: "update-hotspot",
      }}
      modalActionProps={{
        buttonProps: {
          children: "Update",
        },
        query: Api.estatelens.updateImageHotspot,
        handleVariables: (vars) => ({
          ...vars,
          ...getYawPitchFromRef(displayRef),
        }),
        successMessage: hotspot ? "Hotspot Updated" : "Hotspot Added",
        updateValue: updateObj(false, image),
        dismissOnSubmit: true,
        dataKey: "updateImageHotspot",
      }}
      // modalActions
      buttonProps={{
        size: "small",
        children: hotspot ? (
          <EditRoundedIcon fontSize="small" />
        ) : (
          <AddRoundedIcon fontSize="small" />
        ),
        iconButton: true,
        color: hotspot ? "primary" : "success",
        tooltip: { title: title },
      }}
    />
  );
};
