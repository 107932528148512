import React from "react";
import {
  IconButton,
  Button,
  Grid,
  CircularProgress,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
} from "@material-ui/core";
import TextField from "../forms/TextField.js";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import { examQuestionTypes } from "../../Api";
// import { update } from '@react-spring/web';
const types = examQuestionTypes;

const UpdateQuestionContainer = ({
  updateQuestion,
  classes,
  qLen,
  len,
  questionObj,
}) => {
  const [question, setQuestion] = React.useState(
    questionObj ? questionObj.question : ""
  );
  const [questionError, setQuestionError] = React.useState("");
  const [type, setType] = React.useState(
    questionObj
      ? types.find((obj) => {
          return obj.slug === questionObj.type;
        })
      : types[0]
  );
  const [maxLength, setMaxLength] = React.useState(
    questionObj && questionObj.maxLength > 0
      ? questionObj.maxLength.toString()
      : ""
  );
  const [loading, setLoading] = React.useState(false);
  const update = () => {
    setLoading(true);
    if (question === "") {
      setQuestionError("Please enter a question");
      setLoading(false);
    } else {
      updateQuestion({
        id: questionObj ? questionObj.id : len, //1,2,3,4,5
        num: questionObj ? questionObj.num : `${qLen}`, //1.1,2.1,3,4,5
        question: question,
        type: type.slug,
        maxLength: maxLength.trim() === "" ? 0 : parseInt(maxLength),
      });
      !questionObj && setQuestion("");
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        mt: 2
      }}>
      <Box
        sx={{
          mb: 2
        }}>
        <Grid container alignItems="center" wrap="nowrap">
          <TextField
            disabled={loading}
            multiline
            value={question}
            required
            updateValue={setQuestion}
            error={questionError}
            updateError={setQuestionError}
            errorText="Please enter a question"
            label={`Question ${qLen}`}
          />
        </Grid>
      </Box>

      <Grid container alignItems="flex-end" wrap="nowrap">
        <FormControl className={classes.flex1}>
          <InputLabel id={`${len}-exam-edit-select-type-label`}>
            Type
          </InputLabel>
          <Select
            labelId={`${len}-exam-edit-select-type-label`}
            id={`${len}-exam-edit-select-type`}
            disabled={loading}
            value={type.slug}
            onChange={(event) =>
              setType(
                types.find((obj) => {
                  return obj.slug === event.target.value;
                })
              )
            }
          >
            {types.map((item, i) => (
              <MenuItem value={item.slug} disabled={item.disabled}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>type.helperText</FormHelperText> */}
        </FormControl>
        <Box
          sx={{
            ml: 2
          }} />
        {type.maxLength === true && (
          <TextField
            disabled={loading}
            maxWidthRemove
            value={maxLength}
            required
            updateValue={setMaxLength}
            label="Max Length"
            integer
          />
        )}

        <Box
          sx={{
            ml: 2
          }} />
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={update}
        >
          {questionObj ? "Update" : "Add"}
        </Button>
      </Grid>
    </Box>
  );
};
const CreateQuestion = ({ classes, updateQuestion, qLen, len }) => {
  const [show, setShow] = React.useState(false);
  return (
    <Box
      sx={{
        mt: 3
      }}>
      <Button
        color="secondary"
        startIcon={<AddCircleOutlineRoundedIcon />}
        onClick={() => {
          setShow(!show);
        }}
      >
        Add Question
      </Button>
      {show && (
        <UpdateQuestionContainer
          classes={classes}
          updateQuestion={updateQuestion}
          qLen={qLen}
          len={len}
        />
      )}
    </Box>
  );
};
const QuestionItem = ({ question, classes, updateQuestion }) => {
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => setShow(false), [question]);
  return (
    <Box
      className={classes.bottomBorder}
      sx={{
        mt: 2
      }}>
      <Grid container wrap="nowrap" alignItems="center">
        <Typography>
          {question.num}. {question.question}
        </Typography>
        <Box
          sx={{
            ml: 2
          }} />
        <Typography className={classes.grey}>
          <small>
            (Max Length:{" "}
            {question.maxLength > 0
              ? `${question.maxLength} Characters`
              : "No Limit"}
            )
          </small>
        </Typography>
        <Box
          sx={{
            ml: 2
          }} />
        <IconButton
          disabled={loading}
          color="primary"
          onClick={() => setShow(!show)}
        >
          <IndeterminateCheckBoxOutlinedIcon />
        </IconButton>
        <IconButton
          disabled={loading}
          color="secondary"
          onClick={() => {
            setLoading(true);
            updateQuestion(question, true);
            setLoading(false);
          }}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </Grid>
      {show && (
        <Box
          sx={{
            mb: 2,
            px: 3
          }}>
          <UpdateQuestionContainer
            classes={classes}
            updateQuestion={updateQuestion}
            questionObj={question}
          />
        </Box>
      )}
    </Box>
  );
};
const EditExamActivity = ({
  data,
  classes,
  setShow,
  setData,
  drawerContainerProps,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState(data.q ? data.q : []);
  const [length, setLength] = React.useState(data.length ? data.length : 0);
  const [qLen, setQLen] = React.useState();
  const updateQuestion = (obj, deleteObj) => {
    let newArray = [];
    if (deleteObj === true) {
      newArray = [...questions.filter((o) => o.id !== obj.id)].map(function (
        object
      ) {
        if (object.id > obj.id) {
          object.id = object.id - 1;
          object.num = object.id.toString();
        }
        return object;
      });
    } else {
      const elementsIndex = questions.findIndex(
        (element) => element?.id === obj.id
      );
      newArray = [...questions];
      if (elementsIndex < 0) {
        newArray.push(obj);
      } else {
        newArray[elementsIndex] = obj;
      }
      newArray.sort((a, b) => (a.id > b.id ? 1 : -1));
    }
    setQuestions(newArray);
  };
  React.useEffect(() => {
    setQLen(questions.filter((obj) => !obj?.num?.includes(".")).length + 1);
    setLength(questions.length);
  }, [questions]);
  return (
    <React.Fragment>
      <Box {...drawerContainerProps}>
        {questions.map((question, i) => (
          <QuestionItem
            classes={classes}
            updateQuestion={updateQuestion}
            question={question}
            key={question.id}
          />
        ))}
        <CreateQuestion
          updateQuestion={updateQuestion}
          qLen={qLen}
          len={length + 1}
          classes={classes}
        />
      </Box>
      <Paper elevation={3} className={classes.stickyBtn}>
        <Box
          className={classes.btnBorder}
          sx={{
            pt: 2,
            mx: 3
          }} />
        <Box
          className={(classes.btnWrapper, classes.btnFill)}
          sx={{
            mx: 3,
            pb: 2
          }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setLoading(true);
              setData({ q: questions, length: length });
              setShow(false);
              setLoading(false);
            }}
            className={classes.buttonFinal}
            type="submit"
          >
            Update
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default EditExamActivity;
