import React from "react";
import { useMutation } from "@apollo/client";
import ContainerLoader from "../ContainerLoader";
import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Button from "../Button";
import { isFunction, useInterval } from "../utilities/utils";

function MutationLoading({
  query,
  variables,
  autofetch,
  updateValue,
  dataKey,
  render: Render,
  renderProps,
  loadingRender: LoadingRender,
  loaderBefore,
  autoFetchCondition,
  defaultData,
  successMessage,
  errorMessage,
  buttonProps,
  iconButton,
  disabledCondition,
  disabled,
  setAutoFetchCondition,
  closeModal,
  setLoading,
  loading: passLoading,
  resetForm,
  ignoreSuccessMessage,
  onError,
  handleVariables
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateMutation, { loading: mutLoading, error }] = useMutation(query);
  const loading = passLoading || mutLoading;
  const [mutationData, setMutationData] = React.useState(defaultData);
  const [autoFetchRun, setAutoFetchRun] = React.useState(false);
  const runMutation = (e) => {
    e && e.preventDefault();
    setAutoFetchRun(true);
    setLoading && setLoading(true);
    const vrs = isFunction(variables) ? variables() : variables;
    const vars = handleVariables ?handleVariables(vrs):vrs
    updateMutation({
      variables: vars,
    })
      .then(({ data }) => {
        const et = { variables: vars };
        const l = dataKey ? { ...data[dataKey] } : { ...data };
        updateValue && updateValue({ ...et, ...l });
        setMutationData(dataKey ? data[dataKey] : data);
        !autofetch &&
          !ignoreSuccessMessage &&
          enqueueSnackbar(
            successMessage instanceof Function
              ? successMessage(data)
              : successMessage || "Updated Successfully",
            { variant: "success" }
          );
        setAutoFetchRun(false);
        setAutoFetchCondition && setAutoFetchCondition(false);
        closeModal && closeModal();
        setLoading && setLoading(false);
        resetForm && resetForm();
      })
      .catch((e) => {
        console.log(e);
        !autofetch &&
          enqueueSnackbar(
            errorMessage
              ? isFunction(errorMessage)
                ? errorMessage()
                : errorMessage
              : e?.message || "An error has occurred",
            {
              variant: "error",
            }
          );
        onError && onError(e);
        setLoading && setLoading(false);
        setAutoFetchRun(false);
        setAutoFetchCondition && setAutoFetchCondition(false);
      });
  };
  // const intervalRef = useInterval(() => {
  //   const comp = autoFetchCondition ?? true;
  //   // console.log("interval - fetchCondition : ", autoFetchCondition);
  //   if (!autoFetchRun && comp === true) runMutation();
  // }, autofetch);
  return (
    <React.Fragment>
      {buttonProps ? (
        <Button
          onClick={runMutation}
          loading={loading}
          disabled={disabledCondition || disabled || loading}
          {...buttonProps}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {loaderBefore && (
            <Box
              sx={{
                mr: 1,
                width: 30,
                position: "relative",
              }}
            >
              <ContainerLoader loading={loading} relative />
            </Box>
          )}
          {loading ? (
            <LoadingRender loading={loading} />
          ) : (
            <Render {...renderProps} {...mutationData} />
          )}
          {!loaderBefore && (
            <Box
              sx={{
                ml: 1,
                width: 30,
                position: "relative",
              }}
            >
              <ContainerLoader loading={loading} relative />
            </Box>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

export default MutationLoading;
