import React from "react";
import { Pannellum } from "pannellum-react";
import { Api } from "../../../Api";

const PhotoDisplay = ({
  image,
  hotspotDebug,
  displayRef,
  display,
  getHotspotObj,
  target,
  resetTarget
}) => {
  return (
    <Pannellum
      id={image.id}
      height="500px"
      image={Api.formatMediaUrl(image.image)}
      pitch={target?.pitch??image.pitch}
      ref={displayRef}
      yaw={target?.yaw??image.yaw}
      autoLoad
      hfov={display.config.hfov}
      haov={display.config.haov}
      vaov={display.config.vaov}
      vOffset={display.config.vOffset}
      minYaw={display.config.minYaw}
      maxYaw={display.config.maxYaw}
      minPitch={display.config.minPitch}
      maxPitch={display.config.maxPitch}
      minHfov={display.config.minHfov}
      maxHfov={display.config.maxHfov}
      autoRotate={display.config.autoRotate}
      compass={display.config.compass}
      preview={display.previewImage || display.config.previewImage}
      previewTitle={display.previewTitle || display.config.previewTitle}
      previewAuthor={display.previewAuthor || display.config.previewAuthor}
      showControls={display.config.showControls}
      author={display.author || display.config.author}
      title={display.title || display.config.title}
      // orientationOnByDefault={false}
      // draggable
      // keyboardZoom
      // mouseZoom
      // showFullscreenCtrl
      // showZoomCtrl
      onLoad={() => {
        const viewer = displayRef.current.getViewer();
        image.hotspots.map((hotspot, i) =>
          viewer.addHotSpot(getHotspotObj(hotspot))
        );
      }}
      // onScenechange={(id) => {
      //   console.log("Scene has change on " + id);
      // }}
      // onScenechangefadedone={() => {
      //   console.log("panorama loaded");
      // }}
      // onError={(err) => {
      //   console.log("Error", err);
      // }}
      // onErrorcleared={() => {
      //   console.log("Error Cleared");
      // }}
      // onMousedown={(evt) => {
      //   console.log("Mouse Down", evt);
      // }}
      // onMouseup={(evt) => {
      //   console.log("Mouse Up", evt);
      // }}
      // onTouchstart={(evt) => {
      //   console.log("Touch Start", evt);
      // }}
      // onTouchend={(evt) => {
      //   console.log("Touch End", evt);
      // }}
      hotspotDebug={!!hotspotDebug}
    />
  );
};

export default PhotoDisplay;