import { Box } from "@material-ui/core";
import React from "react";

const TextDisplay = ({ mobileText, text }) => (
  <React.Fragment>
    <Box
      component="span"
      sx={{
        display: { xs: "block", sm: "none", md: "none", lg: "none", xl: "none" }
      }}>
      {mobileText}
    </Box>
    <Box
      component="span"
      sx={{
        display: { xs: "none", sm: "block", md: "none", lg: "none", xl: "none" }
      }}>
      {text}
    </Box>
  </React.Fragment>
);
export default TextDisplay;
