import { Box, Typography } from "@material-ui/core";
import React from "react";
import GradientButton from "../components/GradientButton";
import MainSideToggle from "../components/navbar/MainSideToggle";
import { useStateValue } from "../StateProvider";

const props = {
  // component: GradientButton,
  boxProps: { my: 2, mx: "auto" },
  size: "large",
};

function Home() {
  const [{ user }] = useStateValue();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        py: 3,
        px: 2,
      }}
    >
      <Typography variant="h4" align="center">
        MCA
      </Typography>
      <Box
        sx={{
          my: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MainSideToggle iconProps={{ fontSize: "large" }} />
      </Box>
      {!user && (
        <GradientButton toLink="/login" {...props}>
          Login
        </GradientButton>
      )}
      {user && user.isSuperuser === true && (
        <GradientButton toLink="/admin/" {...props}>
          Admin
        </GradientButton>
      )}
      {user && !!user.dashboardAccess && (
        <GradientButton toLink="/dashboard/" {...props}>
          Dashboard
        </GradientButton>
      )}
    </Box>
  );
}

export default Home;
